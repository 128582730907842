import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, Row, } from "reactstrap";
import InputField from "../../../../components/InputField";
import { setSelectedDropdownForSameValue, trimObjectValues, } from "../../../../services/middleware";
import { templateValidationSchema } from "../../../../constants/Schemas";
import { CountryCode, CountryCodeDropdownOptions, TemplateBodyMessageNote, TemplateButtonsDropdown, TemplateButtonsOptions, multiSelectDropdownStyle, selectDropdownStyle, } from "../../../../constants/General";
import MobileViewModal from "../MobileViewModal";
import "./templateModal.css"

export default function TemplateModal(props) {
  // Props
  const { open, template, handleCloseModal, handleSuccess } = props;

  // State
  const [buttonActions, setButtonActions] = useState(!!template?.buttons?.length ? 'action' : '');
  const [buttonActionsOptions, setButtonActionsOptions] = useState(TemplateButtonsOptions);
  const [selectedButtons, setSelectedButtons] = useState(!!template?.buttons?.length
    ? template.buttons.reduce((acc, item, index) => {
      acc[index] = item.type;
      return acc;
    }, {})
    : {
      0: ''
    });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: template?.name || "",
      headerType: template?.headerType || "",
      body: template?.body || "Hello, Greetings for the day, {{content}} Great day ahead.",
      footer: template?.footer || "Reply STOP to unsubscribe.",
      buttons: !!template?.buttons?.length
        ? template?.buttons.map((item) => item.type)
        : [],
      countryCode: setSelectedDropdownForSameValue(
        template?.buttons
          ?.find((item) => item.type === "PHONE_NUMBER")
          ?.phone_number?.slice(0, 2) || CountryCode.UK
      ),
      PHONE_NUMBER: {
        text:
          template?.buttons?.find((item) => item.type === "PHONE_NUMBER")
            ?.text || "",
        phone_number:
          template?.buttons
            ?.find((item) => item.type === "PHONE_NUMBER")
            ?.phone_number?.substring(2) || "",
      },
      URL: {
        text:
          template?.buttons?.find((item) => item.type === "URL")?.text || "",
        url: template?.buttons?.find((item) => item.type === "URL")?.url || "",
      },
      QUICK_REPLY: {
        text:
          template?.buttons?.find((item) => item.type === "QUICK_REPLY")
            ?.text || "",
      },
    },
    validationSchema: templateValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values);

      // Separate "QUICK_REPLY" buttons and other buttons
      const quickReplyButtons = values.buttons.filter(button => button === "QUICK_REPLY");
      const otherButtons = values.buttons.filter(button => button !== "QUICK_REPLY");

      const buttons = [...otherButtons, ...quickReplyButtons].map((option) => ({
        type: option,
        ...values[option],
        ...(option === "PHONE_NUMBER" && {
          ...values[option],
          phone_number: `${values.countryCode?.value}${values[option].phone_number}`,
        }),
      }));
      const payload = {
        name: values.name,
        headerType: values.headerType,
        body: values.body,
        footer: values.footer,
        ...(buttonActions && { buttons: buttons }),
      };
      handleSuccess(payload);
    },
  });

  const updateDropdownOption = (data) => {
    const newOptions = TemplateButtonsOptions.map(option => {
      return {
        ...option,
        disabled: !!Object.values(data).find(item => item === option.value)
      }
    })
    setButtonActionsOptions(newOptions);
  }

  useEffect(() => {
    const buttons = Object.values(selectedButtons).filter((item) => !!item).map((item) => item);
    formik.setFieldValue("buttons", buttons);
  }, [selectedButtons])

  const actionButtonOption = (item, action) => {
    return action === 'PHONE_NUMBER' ? <>
      <Col md={3}>
        <Input
          type="text"
          placeholder="Enter button label"
          value={formik.values.PHONE_NUMBER.text}
          disabled={
            formik.values.buttons.indexOf("PHONE_NUMBER") ===
            -1
          }
          onChange={(e) =>
            formik.setFieldValue("PHONE_NUMBER", {
              ...formik.values.PHONE_NUMBER,
              text: e.target.value,
            })
          }
          invalid={formik.errors.PHONE_NUMBER?.text}
        />
        <FormFeedback>
          {formik.errors?.PHONE_NUMBER?.text}
        </FormFeedback>
        <span className='input-charactor-count-template'>{formik.values?.PHONE_NUMBER?.text.length || 0}/25</span>
      </Col>
      <Col md={6} className="pl-0">
        <div className="d-flex">
          <section className="mb-2 input-group-alternative country-code">
            <Select
              isDisabled={
                formik.values.buttons.indexOf(
                  "PHONE_NUMBER"
                ) === -1
              }
              className="custom-select"
              options={CountryCodeDropdownOptions}
              value={formik.values.countryCode}
              isSearchable={false}
              styles={selectDropdownStyle}
              onChange={(selectedOption) => {
                formik.setFieldValue(
                  "countryCode",
                  selectedOption
                );
              }}
            />
            {formik.touched?.countryCode &&
              formik.errors.countryCode && (
                <span className="error-message input-group-alternative">
                  {formik.errors.countryCode}
                </span>
              )}
          </section>
          <div className="full-width relative">
            <Input
              type="text"
              placeholder="Enter number"
              value={formik.values.PHONE_NUMBER.phone_number}
              disabled={
                formik.values.buttons.indexOf(
                  "PHONE_NUMBER"
                ) === -1
              }
              onChange={(e) =>
                formik.setFieldValue("PHONE_NUMBER", {
                  ...formik.values.PHONE_NUMBER,
                  phone_number: e.target.value,
                })
              }
              invalid={
                formik.errors.PHONE_NUMBER?.phone_number
              }
            />
            <FormFeedback>
              {formik.errors?.PHONE_NUMBER?.phone_number}
            </FormFeedback>
            <span className='input-charactor-count'>{formik.values?.PHONE_NUMBER?.phone_number.length || 0}/11</span>
          </div>
          {removeActionButton(item)}
        </div>
      </Col>
    </> : action === 'URL' ? <>
      <Col md={4}>
        <Input
          type="text"
          placeholder="Enter button label"
          value={formik.values.URL.text}
          disabled={
            formik.values.buttons.indexOf("URL") === -1
          }
          onChange={(e) =>
            formik.setFieldValue("URL", {
              ...formik.values.URL,
              text: e.target.value,
            })
          }
          invalid={formik.errors.URL?.text}
        />
        <FormFeedback>
          {formik.errors?.URL?.text}
        </FormFeedback>
        <span className='input-charactor-count-template'>{formik.values.URL.text.length || 0}/25</span>
      </Col>
      <Col md={5} className="flex">
        <div className="full-width relative">
          <Input
            type="text"
            placeholder="Please add URL with https:// or http://"
            value={formik.values.URL.url}
            disabled={
              formik.values.buttons.indexOf("URL") === -1
            }
            onChange={(e) =>
              formik.setFieldValue("URL", {
                ...formik.values.URL,
                url: e.target.value,
              })
            }
            invalid={formik.errors.URL?.url}
          />
          <FormFeedback>{formik.errors?.URL?.url}</FormFeedback>
          <span className='input-charactor-count'>{formik.values.URL.url.length || 0}/2000</span>
        </div>
        {removeActionButton(item)}
      </Col >
    </> : action === 'QUICK_REPLY' && <Col md={9} className="d-flex">
      <div className="full-width relative">
        <Input
          type="text"
          placeholder="Enter text"
          value={formik.values.QUICK_REPLY.text}
          disabled={
            formik.values.buttons.indexOf("QUICK_REPLY") === -1
          }
          onChange={(e) =>
            formik.setFieldValue("QUICK_REPLY", {
              ...formik.values.QUICK_REPLY,
              text: e.target.value,
            })
          }
          invalid={formik.errors.QUICK_REPLY?.text}
        />
        <FormFeedback>
          {formik.errors?.QUICK_REPLY?.text}
        </FormFeedback>
        <span className='input-charactor-count'>{formik.values.QUICK_REPLY.text.length || 0}/25</span>
      </div>
      {removeActionButton(item)}
    </Col>
  }

  const removeActionButton = (item) => {
    return <Button
      className="action-icon-btn mt-2"
      disabled={Object.keys(selectedButtons).length === 1}
      onClick={() => {
        const newItems = { ...selectedButtons };
        delete newItems[item];

        const entries = Object.entries(newItems);
        const newObj = {};

        entries.forEach(([, value], index) => {
          newObj[index] = value;
        });
        setSelectedButtons(newObj);
        updateDropdownOption(newObj)
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  }

  return (
    <Modal
      className="modal-dialog-centered edit-modal"
      isOpen={open}
      toggle={handleCloseModal}
      size="lg"
      style={{ maxWidth: '900px' }}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Template
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </span>
        </button>
      </div>
      <div className="modal-body p-0 edit-template-body">
        <Card className="border-0 edit-card">
          <CardBody>
            <Form role="form">
              <InputField
                required
                placeholder="Name"
                fieldName="name"
                formik={formik}
              />
              <Row>
                <Col>
                  <Label
                    for="exampleSelect"
                    className="inputfield required-field"
                  >
                    Header
                  </Label>
                  <FormGroup tag="fieldset" inline disabled={!!template}>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="DOCUMENT"
                          checked={formik.values.headerType === "DOCUMENT"}
                          onChange={formik.handleChange}
                        />
                        Document
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="IMAGE"
                          checked={formik.values.headerType === "IMAGE"}
                          onChange={formik.handleChange}
                        />
                        Image
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="TEXT"
                          checked={formik.values.headerType === "TEXT"}
                          onChange={formik.handleChange}
                        />
                        Text
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="headerType"
                          value="VIDEO"
                          checked={formik.values.headerType === "VIDEO"}
                          onChange={formik.handleChange}
                        />
                        Video
                      </Label>
                    </FormGroup>
                    {formik.touched?.headerType && formik.errors.headerType && (
                      <span className="error-message input-group-alternative">
                        {formik.errors.headerType}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <InputField
                required
                className="mb-1"
                placeholder="Body"
                fieldName="body"
                inputType="textarea"
                rows={5}
                formik={formik}
                showCharactorCount
                maxLimit={1028}
              />
              <div className="error-message input-group-alternative">{TemplateBodyMessageNote}</div>
              <InputField
                required
                placeholder="Footer"
                fieldName="footer"
                formik={formik}
                showCharactorCount
                maxLimit={60}
              />
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Buttons
                  </Label>
                  <section className="mb-4 input-group-alternative">
                    <Select className="custom-select" options={TemplateButtonsDropdown} value={TemplateButtonsDropdown.find(option => option.value === buttonActions)} isSearchable={false} styles={multiSelectDropdownStyle}
                      onChange={selectedOption => setButtonActions(selectedOption?.value)}
                    />
                  </section>
                </Col>
              </Row>

              {!!buttonActions && <>
                {Object.keys(selectedButtons).map((item) => {
                  return <Row key={item}>
                    <Col md={3}>
                      <section className="mb-4 input-group-alternative">
                        <Select className="custom-select"
                          options={buttonActionsOptions}
                          isSearchable={false}
                          value={buttonActionsOptions.find(option => option.value === selectedButtons[item]) || ''}
                          styles={multiSelectDropdownStyle}
                          onChange={selectedOption => {
                            updateDropdownOption({ ...selectedButtons, [item]: selectedOption?.value })
                            setSelectedButtons({ ...selectedButtons, [item]: selectedOption?.value })
                          }}
                          isOptionDisabled={(option) => option.disabled}
                        />
                      </section>
                    </Col>
                    {actionButtonOption(item, selectedButtons[item])}
                  </Row>
                })}
                <Button
                  color="primary"
                  type="button"
                  disabled={Object.keys(selectedButtons).length >= 3}
                  onClick={() => {
                    updateDropdownOption(selectedButtons)
                    setSelectedButtons({ ...selectedButtons, [Object.keys(selectedButtons).length]: '' })
                  }}
                >
                  Add Another Button
                </Button>
              </>}
            </Form>
          </CardBody>
        </Card>
        <div className="edit-view">
          <MobileViewModal template={formik.values} formik = {formik}/>
        </div>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color="outline-primary"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          disabled={!!Object.values(formik.errors)?.length}
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
}
