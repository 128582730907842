export function dashboardDataDTO(data) {
  if (data) {
    return {
      userDetail: data?.userDetail && data?.userDetail ? {
        count: data?.userDetail?.count || 0,
      } : null,
      orderDetail: data?.orderDetail && data?.orderDetail ? {
        count: data?.orderDetail?.count || 0,
      } : null,
      revenueDetails: data?.revenueDetails && data?.revenueDetails ? {
        count: data?.revenueDetails?.count || 0,
      } : null,
      facebookAccountDetail: data?.facebookAccountDetail && data?.facebookAccountDetail ? {
        id: data?.facebookAccountDetail?.id || '',
        messaging_limit_tier: data?.facebookAccountDetail?.messaging_limit_tier || '',
        remainingMessageLimit: data?.facebookAccountDetail?.remainingMessageLimit || 0,
        status: data?.facebookAccountDetail?.status || '',
        todaysMessagingLimit: data?.facebookAccountDetail?.todaysMessagingLimit || 0,
        verified_name: data?.facebookAccountDetail?.verified_name || '',
      } : null,
      chart: data?.chart && data?.chart ? {
        type: data?.chart?.type || '',
        revenue: data?.chart?.revenue?.length ? data?.chart?.revenue : [],
        receipts: data?.chart?.receipts?.length ? data?.chart?.receipts : [],
      } : null,
    }
  }
}