export default function cms(
    initialState = {
      cms: [],
      cmsCategoreis: [],
      search: '',
      activeSort: '',
      sortOrder: '',
      totalCount: 0,
      page: 1
    },
    action
  ) {    
    switch (action.type) {
      case 'SET_SUPER_ADMIN_CMS':
        return {
          ...initialState,
          cms: action.payload,
        };

      case 'SET_SUPER_ADMIN_CMS_SEARCH':
        return {
          ...initialState,
          search: action.payload,
        };

      case 'SET_SUPER_ADMIN_CMS_ACTIVE_SORT':
        return {
          ...initialState,
          activeSort: action.payload,
        };

      case 'SET_SUPER_ADMIN_CMS_SORT_ORDER':
        return {
          ...initialState,
          sortOrder: action.payload,
        };

      case 'SET_SUPER_ADMIN_CMS_TOTAL_COUNT':
        return {
          ...initialState,
          totalCount: action.payload,
        };

      case 'SET_SUPER_ADMIN_CMS_PAGE':
        return {
          ...initialState,
          page: action.payload,
        };

      case 'SET_SUPER_ADMIN_CMSCATEGORIES':        
        return {
          ...initialState,
          cmsCategoreis: action.payload,
        };

      case 'SUPER_ADMIN_CMS_RESET':
        return {
          ...initialState,
          cms: [],
          cmsCategoreis: [],
          search: '',
          activeSort: '',
          sortOrder: '',
          totalCount: 0,
          page: 1
        };

      default:
        return initialState;
    }
  }
