export function templatesListDTO(data) {
  let templates = [];

  if (data?.length) {
    templates = data.map((template) => ({
      id: template?.id || 0,
      body: template?.body || '',
      createdAt: template?.createdAt || '',
      footer: template?.footer || '',
      headerType: template?.headerType || '',
      name: template?.name || '',
      status: template?.status || '',
      updatedAt: template?.updatedAt || '',
      buttons: template?.buttons?.length ? template?.buttons : [],
    }))
  }

  return templates;
}