import React from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { useFormik } from 'formik';
import moment from 'moment';
import { Button, Card, CardBody, Col, Form, InputGroup, InputGroupText, Label, Modal, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { couponValidationSchema } from '../../../../constants/Schemas';
import { DiscountType, selectDropdownStyle } from '../../../../constants/General';
import { generateDropdownOptionFromObject, removeEmptyKeysFromObject, setSelectedDropdownValue, trimObjectValues } from '../../../../services/middleware';

export default function CouponModal(props) {
  // Props
  const { open, coupon, handleCloseModal, handleSuccess } = props

  const formik = useFormik({
    initialValues: {
      couponCode: coupon?.couponCode || '',
      startDate: coupon?.startDate ? new Date(coupon?.startDate) : '',
      endDate: coupon?.endDate ? new Date(coupon?.endDate) : '',
      type: coupon?.type ? setSelectedDropdownValue(coupon?.type, DiscountType) : '',
      value: coupon?.value || '',
      description: coupon?.description || '',
    },
    validationSchema: couponValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      const filterData = removeEmptyKeysFromObject(values)
      handleSuccess({ ...filterData, type: filterData.type?.value })
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Coupon
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col md={4}>
                  <InputField required placeholder="Coupon Code" fieldName="couponCode" formik={formik} />
                </Col>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Type
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" value={formik.values.type} isSearchable={false} styles={selectDropdownStyle} onChange={selectedOption => formik.setFieldValue("type", selectedOption)} options={generateDropdownOptionFromObject(DiscountType)} />
                  </section>
                </Col>
                <Col md={4}>
                  <InputField required disabled={!formik.values.type} placeholder="Discount" fieldName="value" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Start Date
                  </Label>
                  <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                    <Datetime dateFormat={'DD-MM-YYYY'} onChange={(value) => {
                      if (moment(value).isSame(moment(formik.values.endDate)) || moment(value).isAfter(moment(formik.values.endDate))) {
                        formik.setFieldValue("endDate", moment(value).add(1, 'hour'))
                      }
                      formik.setFieldValue("startDate", value)
                    }} value={formik.values.startDate} isValidDate={(currentDate) => currentDate.isAfter(moment().subtract(1, 'day').format())} />
                    {formik.touched?.startDate && formik.errors.startDate && <span className="error-message input-group-alternative">{formik.errors.startDate}</span>}
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    End Date
                  </Label>
                  <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                    <Datetime dateFormat={'DD-MM-YYYY'} onChange={(value) => {
                      if (moment(formik.values.startDate).isSame(moment(value)) || moment(formik.values.startDate).isAfter(moment(value))) {
                        formik.setFieldValue("endDate", moment(formik.values.startDate).add(1, 'hour'))
                      } else {
                        formik.setFieldValue("endDate", value)
                      }
                    }} value={formik.values.endDate} isValidDate={(currentDate) => currentDate.isAfter(moment(formik.values.startDate).subtract(1, 'day').format())} />
                    {formik.touched?.endDate && formik.errors.endDate && <span className="error-message input-group-alternative">{formik.errors.endDate}</span>}
                  </InputGroup>
                </Col>
              </Row>
              <InputField placeholder="Description" fieldName="description" formik={formik} />
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}
