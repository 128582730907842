import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Media, Table, Row, Button, Badge, UncontrolledAlert } from "reactstrap";
import { deleteProduct, filteredData, getCategoriesForProducts, getProducts, handleSort } from '../../utils/middleware/products';
import NoDataLabel from '../../../../components/NoDataLabel';
import ConfirmationModal from '../../../../components/ConfirmationModal'
import AddProductModal from '../../components/AddProductModal';
import EditProductModal from '../../components/EditProductModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faImage, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { ProductFetchDelayMessage, RoutesActions } from '../../../../constants/General';
import CustomPagination from '../../../../components/Pagination';
import ViewProductModal from '../../components/ViewProductModal';
import SearchInput from '../../../../components/SearchInput';
import { sortIcon } from '../../../../services/middleware';

export default function Products() {
  // Store
  const { products, productsWithPagination, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminProducts)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [viewProductModal, setViewProductModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  // Mount
  useEffect(() => {
    getCategoriesForProducts()
    getProducts(search, activeSort, sortOrder, page)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Update
  useEffect(() => {
    filteredData(products, search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminProducts.SetPage, pageCount)
    }
  }, [products, search, activeSort, sortOrder, page, pageSize, totalCount])


  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSelectedProduct(null)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedProduct(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedProduct(null)
  }

  const handleClosViewModal = () => {
    setViewProductModal(false)
    setSelectedProduct(null)
  }

  // Unmout
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminProducts.Reset), [])

  return (
    <div>
      <div className="custom-container">

        <UncontrolledAlert color="light">
          {ProductFetchDelayMessage}
        </UncontrolledAlert >

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Products List</h3>
                <div className="right-div-wrap">
                  <SearchInput action={RoutesActions.products} />
                  <Button color='primary' onClick={() => {
                    getCategoriesForProducts()
                    setShowAddModal(true)
                  }}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col" className='serial-number cursor-pointer' onClick={() => handleSort('', '', '')}>No.</th> */}
                    <th scope="col" className={`${activeSort === 'name' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('name', activeSort, sortOrder)}>Name <FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'price' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('price', activeSort, sortOrder)}>Price <FontAwesomeIcon icon={sortIcon(activeSort, 'price', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'recommended' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('recommended', activeSort, sortOrder)}>Recommended <FontAwesomeIcon icon={sortIcon(activeSort, 'recommended', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'category' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('category', activeSort, sortOrder)}>Category <FontAwesomeIcon icon={sortIcon(activeSort, 'category', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'description' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('description', activeSort, sortOrder)}>Description <FontAwesomeIcon icon={sortIcon(activeSort, 'description', sortOrder)} /></th>
                    <th scope="col" className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productsWithPagination.length !== 0 ? productsWithPagination.map((product, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle cursor-pointer mr-3">
                            {product.imageLink ? <img alt="Logo" className="avatar rounded-circle" src={product.imageLink}
                              onClick={() => {
                                dispatch(Actions.User.SetImagePreview, true)
                                dispatch(Actions.User.SetImageLink, product.imageLink)
                              }} /> :
                              <FontAwesomeIcon icon={faImage} />}
                          </span>
                          <Media>
                            <span className="mb-0 text-sm">
                              {product.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{product.price && `${settings?.superAdminSetting?.currencySign}${product.price}`}</td>
                      <td>{product.recommended && <Badge className="badge-success" pill>
                        Recommended
                      </Badge>}</td>
                      <td>{product.category}</td>
                      <td className='ellipsis-column' title={product.description}>{product.description}</td>
                      <td className="text-right">
                        <Button color="warning" className='action-icon-btn' title='View' onClick={() => {
                          setSelectedProduct(product)
                          setViewProductModal(true)
                        }}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                          setSelectedProduct(product)
                          setShowEditModal(true)
                        }}>
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                          setSelectedProduct(product)
                          setShowDeleteModal(true)
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  }) : <tr><td colSpan={7} ><NoDataLabel /></td></tr>}
                </tbody>
              </Table>
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminProducts.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteProduct(selectedProduct?.id, selectedProduct?.retailerId, search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showAddModal && <AddProductModal open={showAddModal} handleCloseModal={handleCloseAddModal} />}
      {showEditModal && <EditProductModal open={showEditModal} product={selectedProduct} handleCloseModal={handleCloseEditModal} />}
      {viewProductModal && <ViewProductModal open={viewProductModal} product={selectedProduct} handleCloseModal={handleClosViewModal} />}
    </div >
  )
}
