export function customersListDTO(data) {
  let customers = [];

  if (data?.length) {
    customers = data.map((customer) => ({
      rewardCoins: customer?.rewardCoins || null,
      createdAt: customer?.createdAt || null,
      isSubscribe: customer?.isSubscribe,
      customer: customer?.customers?.length ? {
        id: customer?.customers[0]?.id || 0,
        name: customer?.customers[0]?.name || null,
        whatsappNumber: customer?.customers[0]?.whatsappNumber || null,
        about: customer?.customers[0]?.about || null,
        whatsappId: customer?.customers[0]?.whatsappId || null,
      } : null,
    }));
  }

  return customers;
}
