export function tableListDTO(data) {
  let tables = [];

  if (data?.length) {
    tables = data.map((table) => ({
      id: table?.id || 0,
      tableNumber: table?.tableNumber || null,
      status: table?.status || 0,
      isAvailable: table.isAvailable,
      restaurant: table?.restaurant !== null ? {
        id: table?.restaurant?.id || 0,
        name: table?.restaurant?.name || null,
        phone: table?.restaurant?.phone || null,
      } : {},
    }))
  }

  return tables;
}