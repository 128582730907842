import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Media, Modal, Row, Table } from "reactstrap";
import NoDataLabel from '../../../../components/NoDataLabel';

export default function ViewSubCategoriesModal(props) {
  // Props
  const { open, selectedCategory, categories, setSelectedCategory, setShowEditModal, setShowDeleteModal, setIsParentCategory, handleCloseModal } = props

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          {`Sub categories of ${selectedCategory?.name}`}
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              {categories.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope='col' className='serial-number cursor-pointer' >No.</th>
                    <th scope='col' className='cursor-pointer' >Name </th>
                    <th scope='col' className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, index) => {
                    return <>
                      <tr key={index}>
                        <td className='serial-number'>{index + 1}</td>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {category.name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="text-right">

                          <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                            setSelectedCategory(category)
                            setIsParentCategory(category?.isParentCategory)
                            setShowEditModal(true)
                          }}>
                            <FontAwesomeIcon icon={faPen} />
                          </Button>
                          <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                            setSelectedCategory(category)
                            setShowDeleteModal(true)
                          }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    </>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
            </Card>
          </div>
        </Row>
      </div>
      <div className="modal-footer pt-2">
        <Button color='primary' type="button" onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    </Modal >
  )
}
