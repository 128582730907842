import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Card, CardHeader, Table, Row, Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterDataForCSV, filteredData, getReports, handleSort } from '../../../utils/middleware/reports';
import { ReportType, calenderViewMode } from '../../../../../constants/General';
import { dispatch, dispatchNoPayload } from '../../../../../utils/store';
import { Actions } from '../../../../../redux/actions';
import DateRangeSelector from '../../../components/DateRangeSelector';
import CustomPagination from '../../../../../components/Pagination';
import NoDataLabel from '../../../../../components/NoDataLabel';
import { CSVLink } from 'react-csv';
import { generateFileName, sortIcon } from '../../../../../services/middleware';

export default function HourlyReports() {
  // Store
  const { reports, reportsWithPagination, search, startDate, endDate, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminReports)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [dateRangeSelector, setDateRangeSelector] = useState(false)

  // Mount
  useEffect(() => {
    getReports(search, ReportType.HOURLY, startDate, endDate, activeSort, sortOrder, page)
  }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  // Update
  useEffect(() => {
    filteredData(reports, search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminReports.SetPage, pageCount)
    }
  }, [reports, search, activeSort, sortOrder, page, pageSize, totalCount])

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminReports.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Hourly Reports</h3>
                <div className="report-btn-wrap">
                  <CSVLink className="btn btn-primary mr-3" data={filterDataForCSV(reports, ReportType.HOURLY, settings?.superAdminSetting?.currencySign)} filename={`${generateFileName()}.csv`}>
                    Download
                  </CSVLink>
                  <Button
                    color="primary"
                    outline
                    onClick={() => setDateRangeSelector(true)}
                  >
                    {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                  </Button>
                </div>
              </CardHeader>
              {reportsWithPagination?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSort('', "", "")}>No.</th> */}
                    <th scope="col" className={`${activeSort === 'startDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('startDate', activeSort, sortOrder)}>Start Time <FontAwesomeIcon icon={sortIcon(activeSort, 'startDate', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'endDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('endDate', activeSort, sortOrder)}>End Time <FontAwesomeIcon icon={sortIcon(activeSort, 'endDate', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'orderCount' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('orderCount', activeSort, sortOrder)}>Orders <FontAwesomeIcon icon={sortIcon(activeSort, 'orderCount', sortOrder)} /></th>
                    <th scope='col'>Tax</th>
                    <th scope="col" className={`${activeSort === 'revenue' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('revenue', activeSort, sortOrder)}>Revenue <FontAwesomeIcon icon={sortIcon(activeSort, 'revenue', sortOrder)} /></th>
                  </tr>
                </thead>
                <tbody>
                  {reportsWithPagination.map((report, index) => {
                    return <tr key={index}>
                      {/* <th className='serial-number'>{index + 1 + (page - 1) * pageSize}</th> */}
                      <th>{report.startDate ? moment(report.startDate).format('DD-MM-YYYY hh:mm A') : ''}</th>
                      <th>{report.endDate ? moment(report.endDate).format('DD-MM-YYYY hh:mm A') : ''}</th>
                      <th>{report.orderCount}</th>
                      <th>{report.tax && `${settings?.superAdminSetting?.currencySign}${report.tax}`}</th>
                      <th>{report.revenue && `${settings?.superAdminSetting?.currencySign}${(report.revenue).toFixed(2)}`}</th>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminReports.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {dateRangeSelector && <DateRangeSelector
        open={dateRangeSelector}
        handleCloseModal={() => setDateRangeSelector(false)}
        startDate={startDate}
        endDate={endDate}
        viewMode={calenderViewMode.day}
        setStartDate={(date) => dispatch(Actions.RestaurantAdminReports.SetStartDate, date)}
        setEndDate={(date) => dispatch(Actions.RestaurantAdminReports.SetEndDate, date)} />}
    </div >
  )
}
