import React, { useState, useEffect, useRef } from "react";
import { registerDevice } from "./../../utils/middleware/notification";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const publicVapidKey = "BLFSMjjyPNdWgdkvCP9EckVyPJYtwUYnnGs5HqTh3msgPVJYfw8YK-z_JgoL5kJQKXwUnNYsHoXnXR41bKZk4jo";
const safariPushId = "web.wepos.ai";
const safariWebsitePushURL = "https://wepos.ai/admin/";

const NotificationComponent = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const isSubscribedRef = useRef(false);

  // Check and update prompt visibility
  const updatePromptVisibility = () => {
    const isNotificationDenied = JSON.parse(localStorage.getItem("isNotificationDenied"));

    if (!isNotificationDenied) {
      if (isSafari()) {
        if (window.safari && window.safari.pushNotification) {
          const permission = window.safari.pushNotification.permission(safariPushId);
          setShowPrompt(permission.permission === "default");
        }
      } else if ("Notification" in window) {
        setShowPrompt(Notification.permission === "default");
      }
    }
  };

  useEffect(() => {
    // Initial check on load
    updatePromptVisibility();

    // Re-check when page gains focus (e.g., after visiting site settings)
    window.addEventListener("focus", updatePromptVisibility);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("focus", updatePromptVisibility);
    };
  }, []);

  const handleSubscription = () => {
    if (isSafari()) {
      if (window.safari && window.safari.pushNotification) {
        const permission = window.safari.pushNotification.permission(safariPushId);
        if (permission.permission === "default") {
          localStorage.setItem("isNotification", false);
        } else if (permission.permission !== "granted") {
          requestSafariPushPermission();
          localStorage.setItem("isNotification", true);
          isSubscribedRef.current = true;
          setShowPrompt(false);
        }
      }
    } else if ("serviceWorker" in navigator && !isSubscribedRef.current) {
      Notification.requestPermission().then((permission) => {
        if (permission === "default") {
          localStorage.setItem("isNotification", false);
        } else if (permission === "granted") {
          registerServiceWorkerAndSubscribe();
          localStorage.setItem("isNotification", true);
          isSubscribedRef.current = true;
          setShowPrompt(false);
        }
      });
    }
  };

  const registerServiceWorkerAndSubscribe = async () => {
    if (isSubscribedRef.current) return;
    try {
      const register = await navigator.serviceWorker.register("/sw.js", {
        scope: "/",
      });
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });

      const restaurant = JSON.parse(localStorage.getItem("userData"));
      const { id, restaurantId } = restaurant;
      const payload = {
        restaurantId: restaurantId,
        customerId: id,
        subscription: subscription,
      };
        registerDevice(payload);
      
    } catch (error) {
      console.error("Error in service worker or push subscription:", error);
    }
  };

  const requestSafariPushPermission = () => {
    window.safari.pushNotification.requestPermission(
      safariWebsitePushURL,
      safariPushId,
      {},
      (permissionData) => {
        if (permissionData.permission === "granted") {
          const deviceToken = permissionData.deviceToken;
          const restaurant = JSON.parse(localStorage.getItem("userData"));
          const { id, restaurantId } = restaurant;

          const payload = {
            restaurantId,
            customerId: id,
            subscription: deviceToken,
          };

          registerDevice(payload);
        } else {
          console.log("Safari Push Permission Denied");
        }
      }
    );
  };

  const isSafari = () => {
    return (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent) &&
      !/CriOS/.test(navigator.userAgent)
    );
  };

  const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const handleNotificationClick = () => {
    handleSubscription();
  };

  const handleNotificationClose = () => {
    localStorage.setItem("isNotificationDenied", true); // Mark as denied
    setShowPrompt(false);
  };

  return (
    <>
      {showPrompt && (
        <div className="setting-configure-error-container">
          <span className="setting-configure-error-text">
            Stay in the know. Turn on desktop notifications. :{" "}
          </span>
          <Button
            color="primary"
            className="ml-2"
            size="sm"
            onClick={handleNotificationClick}
          >
            Turn On
          </Button>
          <span style={{ cursor: "pointer" }} onClick={handleNotificationClose}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </span>
        </div>
      )}
    </>
  );
};

export default NotificationComponent;
