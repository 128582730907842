import { Badge, Card, CardHeader, Media, Table, Row, Button, Input, } from "reactstrap";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRestaurant, editRestaurant, getRestaurants } from "../../utils/middleware/restaurant";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import EditRestaurantModal from "../../components/EditRestaurantModal";
import AddRestaurant from "../../components/AddRestaurant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPen, faRightToBracket, faTrash, } from "@fortawesome/free-solid-svg-icons";
import { RoutesActions, serviceThrough } from "../../../../constants/General";
import NoDataLabel from "../../../../components/NoDataLabel";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import { Actions } from "../../../../redux/actions";
import CustomPagination from "../../../../components/Pagination";
import { handleSortColumn, sortIcon, superAdminLogin } from "../../../../services/middleware";
import SearchInput from "../../../../components/SearchInput";

const Restaurants = () => {
  const navigate = useNavigate();

  // Store
  const { restaurants, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.superAdminRestaurants)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showStatusModal, setStatusModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)

  // Mount
  useEffect(() => {
    getRestaurants(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.SuperAdminRestaurants.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize])

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.SuperAdminRestaurants.Reset), [])

  return (
    <>
      <div>
        <div className="custom-container">
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 space-between-div table-header-div">
                  <h3 className="mb-0">Restaurants</h3>
                  <div className="right-div-wrap">
                    <SearchInput action={RoutesActions.restaurants} />
                    <Button color='primary' onClick={() => setShowAddModal(true)}>
                      Add
                    </Button>
                  </div>
                </CardHeader>
                {restaurants?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'restaurants')}>No.</th> */}
                      <th scope='col' className={`${activeSort === 'name' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('name', activeSort, sortOrder, 'restaurants')}>Name <FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                      <th scope='col'>Service</th>
                      <th scope='col'>Status</th>
                      <th scope='col' className={`${activeSort === 'tableCount' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('tableCount', activeSort, sortOrder, 'restaurants')}>Tables <FontAwesomeIcon icon={sortIcon(activeSort, 'tableCount', sortOrder)} /></th>
                      <th scope='col'>Subscription</th>
                      <th scope='col' className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {restaurants.map((restaurant, index) => {
                      return <tr key={index}>
                        {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                        <th scope="row">
                          <Media className="align-items-center">
                            <span className="avatar avatar-sm rounded-circle cursor-pointer mr-3">
                              {restaurant.logo ? <img alt="Logo" className="avatar rounded-circle" src={restaurant.logo}
                                onClick={() => {
                                  dispatch(Actions.User.SetImagePreview, true)
                                  dispatch(Actions.User.SetImageLink, restaurant?.logo)
                                }} /> :
                                <FontAwesomeIcon icon={faImage} />}
                            </span>
                            <Media>
                              <span className="mb-0 text-sm">
                                {restaurant.name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className='ellipsis-column' title={restaurant.serviceThrough.map(service => serviceThrough[service]).join(", ")}>{restaurant.serviceThrough.map(service => serviceThrough[service]).join(", ")}</td>
                        <td>
                          <Badge className={restaurant.status ? 'badge-success' : 'badge-danger'}>
                            {restaurant.status ? 'Active' : 'Inactive'}
                          </Badge>
                        </td>
                        <td>{restaurant.tableCount}</td>
                        <td>{restaurant?.subscription?.name}</td>
                        <td className="text-right">
                          <div className="flex align-items-center justify-content-end">
                            <label className='custom-toggle mr-1'>
                              <Input
                                type='checkbox'
                                className='form-control'
                                name='recommended'
                                checked={restaurant.status}
                                onChange={() => {
                                  setStatusModal(true)
                                  setSelectedRestaurant(restaurant)
                                }}
                              />
                              <span className='custom-toggle-slider rounded-circle' />
                            </label>
                            <Button color="success" className="action-icon-btn" title="Login" onClick={() => superAdminLogin(restaurant?.id, true, navigate)}>
                              <FontAwesomeIcon icon={faRightToBracket} />
                            </Button>
                            <Button color="success" className="action-icon-btn" title="Edit" onClick={() => {
                              setShowEditModal(true)
                              setSelectedRestaurant(restaurant)
                            }}>
                              <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button color="danger" className="action-icon-btn" title="Delete" onClick={() => {
                              setShowDeleteModal(true)
                              setSelectedRestaurant(restaurant)
                            }}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </Table> : <NoDataLabel />}
                <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.SuperAdminRestaurants.SetPage, selected + 1)} />
              </Card>
            </div>
          </Row>
        </div>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={() => setShowDeleteModal(false)} handleSuccess={() => deleteRestaurant(selectedRestaurant?.id, search, activeSort, sortOrder, page, setShowDeleteModal)} />}
      {showStatusModal && <ConfirmationModal open={showStatusModal} handleCloseModal={() => setStatusModal(false)} handleSuccess={() => editRestaurant(selectedRestaurant?.id, { restaurantStatus: selectedRestaurant?.status ? 0 : 1 }, search, activeSort, sortOrder, page, () => setStatusModal(false))} />}
      {showEditModal && <EditRestaurantModal restaurant={selectedRestaurant} open={showEditModal} handleCloseModal={() => setShowEditModal(false)} />}
      {showAddModal && <AddRestaurant open={showAddModal} handleCloseModal={() => setShowAddModal(false)} />}
    </>
  );
};

export default Restaurants;
