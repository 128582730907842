export default function marketings(
  initialState = {
    campaigns: [],
    tags: [],
    customers: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS':
      return {
        ...initialState,
        campaigns: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_TAGS':
      return {
        ...initialState,
        tags: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_CUSTOMERS':
      return {
        ...initialState,
        customers: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_MARKETINGS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_MARKETINGS_RESET':
      return {
        ...initialState,
        campaigns: [],
        tags: [],
        customers: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
