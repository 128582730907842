export function categoriesDTO(data) {
    let categories = [];
    
    if (data.data?.length) {      
      categories = data.data.map((data) => ({
        value: data?.id || '',
        label: data?.name || '',
      }))
    }

    return categories;
  }