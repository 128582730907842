import React from 'react'
import { FormFeedback, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FILE_TYPE } from '../../constants/General';

export default function InputField(props) {
  // Props
  const { placeholder, action, fieldName, inputType, rows, accept = FILE_TYPE, formik, onKeyPress, value, passwordIcon, multiple, showPassword, required, dropdownChild, removeClearIcon = true, setShowPassword, disabled, passwordField, showCharactorCount, maxLimit } = props

  const commonProps = {
    id: fieldName,
    className: `custom-form-control ${passwordField ? 'password-field' : ''}`,
    type: inputType || "text",
    name: fieldName,
    ...(multiple && { multiple }),
    ...(inputType !== 'file' && {
      value: formik?.values?.[fieldName] || value || ''
    }),
    onChange: (e) => {
      if (inputType === 'file' && multiple) {
        const files = Array.from(e.target.files);
        formik?.setFieldValue(fieldName, [...formik.values[fieldName], ...files])
        
      } else if (inputType === 'file' && e.currentTarget.files && e.currentTarget.files[0]) {
        formik?.setFieldValue(fieldName, e.currentTarget.files[0])
        action(e.currentTarget.files[0].type)
      } else formik?.handleChange(e)
    },
    ...(onKeyPress && {
      onKeyPress: onKeyPress
    }),
    disabled: disabled,
    ...(inputType === 'textarea' && {
      rows: rows || 1
    }),
    onBlur: formik?.handleBlur,
    invalid: !!formik?.touched?.[fieldName] && !!formik?.errors?.[fieldName],
    accept: accept.map(function (x) {
      return '.' + x;
    })
      .join(',')
  }

  return (
    <>
      <Label className={`inputfield ${required && 'required-field'}`}>
        {placeholder}
      </Label>
      <InputGroup className="input-group-alternative mb-3">
        {inputType === 'select' ? <Input
          {...commonProps}
        >
          {dropdownChild.map(data => {
            return <option value={data.value}>{data.name}</option>
          })}
        </Input> : <Input
          {...commonProps}
        />}
        {passwordIcon && <InputGroupText className='cursor-pointer show-hide-password'>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={() => setShowPassword(!showPassword)} />
        </InputGroupText>}
        {inputType === 'file' && typeof formik?.values?.[fieldName] === 'object' && removeClearIcon && !disabled && 
        <InputGroupText className='cursor-pointer' style={{  bottom: 'auto' }}>
          <FontAwesomeIcon icon={faXmark} onClick={(e) => {
            e.stopPropagation()
            formik?.setFieldValue(fieldName, '')
            document.getElementById(fieldName).value = ''
            action('text')
          }} />
        </InputGroupText>}
        {showCharactorCount && <span className='input-charactor-count'>{formik?.values?.[fieldName]?.length || 0}/{maxLimit}</span>}
        <FormFeedback>
          {formik?.errors?.[fieldName]}
        </FormFeedback>
      </InputGroup>
    </>
  )
}
