export function cmsDTO(data) {  
    let cms = [];
    if (data.data?.length) {     
      cms = data.data.map((data) => ({        
        id: data?.id || '',
        content: data?.content || '',
        contentstyle: data?.contentstyle || '',
        contentmeta: data?.contentmeta || '',
        category: data?.category.name || '',
        title: data?.title || '',
        categoryid: data?.categoryId || ''
      }))
    }    
    return cms;
  }