import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import { Actions } from "../../../../redux/actions";
import SubscriptionCard from "../../../../components/SubscriptionCard";
import { Row, Card, CardHeader, CardBody } from "reactstrap";
import SearchInput from "../../../../components/SearchInput";
import { RoutesActions } from "../../../../constants/General";
import { getSubscriptions } from "../../utils/middleware/subscription";

export default function Subscription() {
  // Store
  const { subscriptions, search, page } = useSelector(
    (state) => state.superAdminSubscriptions
  );
  const { restaurant } = useSelector((state) => state.restaurantAdmin);

  // State
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true);
    getSubscriptions(search, page, 1);
  }, [search, page]);

  const isActiveSubscriptionAvailable = (subscriptions, subscription) => {
    return subscriptions.find((sub) => sub?.id === subscription);
  };

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.SuperAdminRestaurants.Reset), []);

  return (
    <>
      <div className="subscription-list-wrapper">
        <Card className="shadow">
          <CardHeader className="space-between-div table-header-div">
            <h3 className="mb-0">Subscription List</h3>
            <div className="right-div-wrap">
              <SearchInput action={RoutesActions.subscription} />
            </div>
          </CardHeader>
          <CardBody>
            <Row className="subscription-row">
              {subscriptions?.length !== 0 && restaurant?.subscription && !isActiveSubscriptionAvailable(subscriptions, restaurant?.subscription?.id) && (
                <SubscriptionCard subscription={restaurant?.subscription} selectedSubscriptionId={restaurant?.subscription?.id} setSelectedSubscription={setSelectedSubscription} />
              )}
              {subscriptions?.length !== 0 && subscriptions.map((subscription, index) => {
                return (
                  <SubscriptionCard key={index} subscription={subscription} selectedSubscriptionId={selectedSubscription?.id || restaurant?.subscription?.id} setSelectedSubscription={setSelectedSubscription} />
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
