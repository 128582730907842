import * as Yup from 'yup';
import { formatBytes } from '../../services/middleware';
import { DiscountType, FILE_TYPE, ImageLinks, MaximumFileSize, MinimumFileSize } from '../General';
import moment from 'moment';

// Register
export const registerValidationSchema = Yup.object().shape({
  name: Yup.string().matches(/^[a-zA-Z0-9_ ]+$/, 'Name can consist of only alphanumeric characters.').required('Name is a required field.').min(3, 'Name should have minimum 3 characters.').max(100, 'Name can have maximum 100 characters.'),
  email: Yup.string().trim().email('This email is not a valid format.').required('Email is a required field.'),
  password: Yup.string().trim().required('Password is a required field.').min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'),
  confirmPassword: Yup.string().trim().required('Confirm password is a required field.').oneOf([Yup.ref('password'), null], "Password don't match"),
  addressLine1: Yup.string().trim().required('Address line 1 is a required field.').min(5, 'Address line 1 must have minimum 5 characters.').max(64, 'Address line 1 must have maximum 64 characters.'),
  addressLine2: Yup.string().trim().min(5, 'Address line 2 must have minimum 5 characters.').max(64, 'Address line 2 must have maximum 64 characters.'),
  zipcode: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Zipcode must be alphanumeric value.').required('Zipcode is a required field.').min(3, 'Zipcode must have minimum 3 characters.').max(10, 'Zipcode must have maximum 10 characters.'),
  state: Yup.object().strict().required('State is a required field.'),
  country: Yup.object().strict().required('Country is a required field.'),
  service: Yup.array().strict().min(1, 'Service is a required field.'),
  tagLine: Yup.string().trim().required('Tagline is a required field.').min(2, 'Tagline must have minimum 2 characters.').max(100, 'Tagline must have maximum 100 characters.'),
  phone: Yup.string().matches(/^[0-9]+$/, 'Phone must be numeric value.').length(10, 'Phone should have 10 digits.').required('Phone is a required field.'),
  tableCount: Yup.string().matches(/^[0-9]+$/, 'Table count must be numeric value.').required('Table count is a required field.').max(2, 'Table count should have maximum 99')
    .test('len', 'Table count must have minimum 1.', val => parseInt(val) > 0),
})

// Login
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().trim().nullable().email('This email is not a valid format.').required('Email is a required field.'),
  password: Yup.string().trim().required('Password is a required field.'),
})

// Reset Password
export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string().trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'),
  confirmPassword: Yup.string().trim()
    .required('Confirm password is a required field.')
    .oneOf([Yup.ref('password'), null], "Password don't match"),
})

// Forgot Password
export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string().trim()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
})

// Add restaurant
export const addRestaurantValidationSchema = Yup.object({
  name: Yup.string().matches(/^[a-zA-Z0-9_ ]+$/, 'Name can consist of only alphanumeric characters.').required('Name is a required field.').min(3, 'Name should have minimum 3 characters.').max(100, 'Name can have maximum 100 characters.'),
  email: Yup.string().trim().email('This email is not a valid format.').required('Email is a required field.'),
  password: Yup.string().trim().required('Password is a required field.').min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'),
  confirmPassword: Yup.string().trim().required('Confirm password is a required field.').oneOf([Yup.ref('password'), null], "Password don't match"),
  addressLine1: Yup.string().trim().required('Address line 1 is a required field.').min(5, 'Address line 1 must have minimum 5 characters.').max(64, 'Address line 1 must have maximum 64 characters.'),
  addressLine2: Yup.string().trim().min(5, 'Address line 2 must have minimum 5 characters.').max(64, 'Address line 2 must have maximum 64 characters.'),
  zipcode: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Zipcode must be alphanumeric value.').required('Zipcode is a required field.').min(3, 'Zipcode must have minimum 3 characters.').max(10, 'Zipcode must have maximum 10 characters.'),
  state: Yup.object().strict().required('State is a required field.'),
  country: Yup.object().strict().required('Country is a required field.'),
  service: Yup.array().strict().min(1, 'Service is a required field.'),
  logo: Yup.mixed().test('FILE_FORMAT', `You can only upload ${FILE_TYPE.join()} files.`, (value) => {
    return (!value || (value?.name && FILE_TYPE.includes(value?.type?.split('/')[1]?.toLocaleLowerCase())))
  }).test('fileSize', `File size must be between ${formatBytes(MinimumFileSize)} to ${formatBytes(MaximumFileSize)}.`, (value) => {
    return (!value || (value?.size && value.size >= MinimumFileSize && value.size <= MaximumFileSize))
  }),
  tagLine: Yup.string().trim().required('Tagline is a required field.').min(2, 'Tagline must have minimum 2 characters.').max(100, 'Tagline must have maximum 100 characters.'),
  phone: Yup.string().matches(/^[0-9]+$/, 'Phone must be numeric value.').length(10, 'Phone should have 10 digits.').required('Phone is a required field.'),
  tableCount: Yup.string().matches(/^[0-9]+$/, 'Table count must be numeric value.').required('Table count is a required field.').max(2, 'Table count should have maximum 99')
    .test('len', 'Table count must have minimum 1.', val => parseInt(val) > 0),
})

// Edit restaurant
export const editRestaurantValidationSchema = Yup.object({
  name: Yup.string().matches(/^[a-zA-Z0-9_ ]+$/, 'Name can consist of only alphanumeric characters.').required('Name is a required field.').min(3, 'Name should have minimum 3 characters.').max(100, 'Name can have maximum 100 characters.'),
  addressLine1: Yup.string().required('Address line 1 is a required field.').min(5, 'Address line 1 must have minimum 5 characters.').max(64, 'Address line 1 must have maximum 64 characters.'),
  addressLine2: Yup.string().nullable().min(5, 'Address line 2 must have minimum 5 characters.').max(64, 'Address line 2 must have maximum 64 characters.'),
  zipCode: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Zipcode must be alphanumeric value.').required('Zipcode is a required field.').min(3, 'Zipcode must have minimum 3 characters.').max(10, 'Zipcode must have maximum 10 characters.'),
  state: Yup.object().strict().required('State is a required field.'),
  serviceThrough: Yup.array().strict().min(1, 'Service is a required field.'),
  logo: Yup.mixed().nullable().test('FILE_FORMAT', `You can only upload ${FILE_TYPE.join()} files.`, (value) => {
    return (!value || (typeof value === 'string' && ImageLinks.includes(value?.split(":")[0])) || FILE_TYPE.includes(value?.type?.split('/')[1]?.toLocaleLowerCase()))
  }).test('fileSize', `File size must be between ${formatBytes(MinimumFileSize)} to ${formatBytes(MaximumFileSize)}.`, (value) => {
    return (!value || (typeof value === 'string' && ImageLinks.includes(value?.split(":")[0])) || (value?.size && value.size >= MinimumFileSize && value.size <= MaximumFileSize))
  }),
  tagLine: Yup.string().trim().required('Tagline is a required field.').min(2, 'Tagline must have minimum 2 characters.').max(100, 'Tagline must have maximum 100 characters.'),
})

// Edit Profile
export const editProfileValidationSchema = Yup.object({
  name: Yup.string().matches(/^[a-zA-Z0-9_ ]+$/, 'Name can consist of only alphanumeric characters.').required('Name is a required field.').min(3, 'Name should have minimum 3 characters.').max(100, 'Name can have maximum 100 characters.'),
  addressLine1: Yup.string().required('Address line 1 is a required field.').min(5, 'Address line 1 must have minimum 5 characters.').max(64, 'Address line 1 must have maximum 64 characters.'),
  addressLine2: Yup.string().nullable().min(5, 'Address line 2 must have minimum 5 characters.').max(64, 'Address line 2 must have maximum 64 characters.'),
  phone: Yup.string().trim().required('Phone is a required field.').matches(/^[0-9]+$/, 'Phone must be numeric value.').length(10, 'Phone should have 10 digits.'),
  email: Yup.string().trim().email('This email is not a valid format.').required('Email is a required field.'),
  serviceThrough: Yup.array().strict().min(1, 'Service is a required field.'),
  tableCount: Yup.string().required('Table count is a required field.').matches(/^[0-9]+$/, 'Table count must be numeric value.').max(2, 'Table count should have maximum 99').test('len', 'Table count must have minimum 1.', val => parseInt(val) > 0),
  logo: Yup.mixed().nullable().test('FILE_FORMAT', `You can only upload ${FILE_TYPE.join()} files.`, (value) => {
    return (!value || (typeof value === 'string' && ImageLinks.includes(value?.split(":")[0])) || FILE_TYPE.includes(value?.type?.split('/')[1]?.toLocaleLowerCase()))
  }).test('fileSize', `File size must be between ${formatBytes(MinimumFileSize)} to ${formatBytes(MaximumFileSize)}.`, (value) => {
    return (!value || (typeof value === 'string' && ImageLinks.includes(value?.split(":")[0])) || (value?.size && value.size >= MinimumFileSize && value.size <= MaximumFileSize))
  }),
})

// Edit Settings
export const editSettingsSchema = Yup.object({
  appId: Yup.string().trim().required('App ID is a required field.'),
  appName: Yup.string().trim().required('App Name is a required field.'),
  accessToken: Yup.string().trim().required('Access Token is a required field.'),
  callbackUrl: Yup.string().trim().required('Callback URL is a required field.'),
  verifyToken: Yup.string().trim().required('Verify Token is a required field.'),
  phoneNumberId: Yup.string().trim().required('Phone ID is a required field.'),
  wabaId: Yup.string().trim().required('Waba ID is a required field.'),
  googleFeedbackUrl: Yup.string().trim().url('Please enter valid URL.'),
  visitCount: Yup.number()
    .nullable()
    .typeError('Visit Count must be a numeric value.')
    .positive('Visit Count must be a positive number.')
    .integer('Visit Count must be an integer.')
    .min(1, 'Visit Count must have a minimum value of 1.'),
  welcomePDF: Yup.array().of(
    Yup.object().shape({
      fileName: Yup.string(),
      fileUrl: Yup.mixed().nullable().test('FILE_FORMAT', 'You can only upload PDF files.', (value) => {
        if (!value || value.length === 0) {
          return true;  // Allow empty array or null
        }
        return (
          (typeof value === 'string' && ImageLinks.includes(value?.split(':')[0])) ||
          (value instanceof File && value.type === 'application/pdf')
        );
      })
    })
  ).test('MAX_TOTAL_SIZE', 'Total combined pdf files size should not be more then 5 MB.', (files) => {
    if (!files || files.length === 0) {
      return true; // Allow empty array or null
    }

    const totalSize = files.reduce((acc, file) => {
      if (file.fileUrl instanceof File) {
        return acc + file.fileUrl.size;
      }
      return acc;
    }, 0);
    return totalSize <= MaximumFileSize;
  }),
  fbCatalogId: Yup.string().trim().required('FB Catelog ID is a required field.'),
  serviceHours: Yup.array().strict().min(1, 'Service Hours is a required field.'),
  rewardCoinsPrice: Yup.number().min(0, 'Reward Coins Price must be positive value.').typeError('Reward Coins Price must be numeric value.'),
  rewardCoinsPerOrder: Yup.number().min(0, 'Reward Coins per Order must be positive value.').typeError('Reward Coins per Order must be numeric value.'),
  minimumRewardedCoins: Yup.number().min(0, 'Minimum Reward Coins must be positive value.').typeError('Minimum Reward Coins must be numeric value.'),
  minimumOrderPrice: Yup.number().min(0, 'Minimum Order price must be positive value.').typeError('Minimum Order price must be numeric value.'),
  maxRedeemableCoins: Yup.number().min(0, 'Maximum Redeemable Reward Coins must be positive value.').typeError('Maximum Redeemable Reward Coins must be numeric value.')
})

// Edit Admin Settings
export const editAdminSettingsSchema = Yup.object({
  currency: Yup.string().required('Currency is a required field.'),
  currencySign: Yup.string().required('Currency Sign is a required field.'),
  vat: Yup.number().min(0, 'VAT must be positive value.').max(100, 'VAT can have maximum 100 percentage.').typeError('VAT must be numeric value.')
})

// Change password
export const changePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string().trim().required('Old Password is a required field.'),
  newPassword: Yup.string().trim().required('New password is a required field.').min(6, 'New password is too short - should be 6 chars minimum.')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'New password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number)'),
  confirmNewPassword: Yup.string().trim().required('Confirm new password is a required field.').oneOf([Yup.ref('newPassword'), null], "Confirm new password don't match"),
})

// Category
export const categoryValidationSchema = Yup.object({
  name: Yup.string().required('Name is a required field.').min(3, 'Name must have minimum 3 characters.').max(100, 'Name must have maximum 100 characters.'),
  isParentCategory: Yup.boolean(),
  parentCategory: Yup.object().when("isParentCategory", {
    is: (isParentCategory) => !isParentCategory,
    then: Yup.object().nullable().required('please select parent category.')
  }),
})

// Category
export const tagValidationSchema = Yup.object({
  tagName: Yup.string().required('Tag Name is a required field.').min(3, 'Tag Name must have minimum 3 characters.').max(100, 'Tag Name must have maximum 100 characters.'),
  customers: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.number()
      })
    ).min(1, 'Please select atleast 1 customer.')
})

// Customer
export const customerValidationSchema = Yup.object({
  name: Yup.string().required('Name is a required field.'),
  about: Yup.string().required('About is a required field.'),
})

// Add Product
export const addProductValidationSchema = Yup.object({
  title: Yup.string().matches(/^[a-zA-Z0-9_ ]+$/, 'Title can consist of only alphanumeric characters.').required('Title is a required field.').min(2, 'Title must have minimum 2 characters.').max(100, 'Title must have maximum 100 characters.'),
  imageLink: Yup.mixed().test('FILE_FORMAT', `You can only upload ${FILE_TYPE.join()} files.`, (value) => {
    return (!value || (value?.name && FILE_TYPE.includes(value?.type?.split('/')[1]?.toLocaleLowerCase())))
  }).test('fileSize', `File size must be between ${formatBytes(MinimumFileSize)} to ${formatBytes(MaximumFileSize)}.`, (value) => {
    return (!value || (value?.size && value.size >= MinimumFileSize && value.size <= MaximumFileSize))
  }),
  description: Yup.string().min(5, 'Description must have minimum 5 characters.').max(300, 'Description must have maximum 300 characters.'),
  price: Yup.number().min(0, 'Price must be positive value.').required('Price is a required field.').typeError('Price must be numeric value.'),
  categoryName: Yup.object().strict().required('Category is a required field.'),
  recommended: Yup.boolean(),
})

// Edit Product
export const editProductValidationSchema = Yup.object({
  title: Yup.string().matches(/^[a-zA-Z0-9_ ]+$/, 'Title can consist of only alphanumeric characters.').min(2, 'Title must have minimum 2 characters.').max(100, 'Title must have maximum 100 characters.'),
  imageLink: Yup.mixed().nullable().test('FILE_FORMAT', `You can only upload ${FILE_TYPE.join()} files.`, (value) => {
    return (!value || (typeof value === 'string' && ImageLinks.includes(value?.split(":")[0])) || FILE_TYPE.includes(value?.type?.split('/')[1]?.toLocaleLowerCase()))
  }).test('fileSize', `File size must be between ${formatBytes(MinimumFileSize)} to ${formatBytes(MaximumFileSize)}.`, (value) => {
    return (!value || (typeof value === 'string' && ImageLinks.includes(value?.split(":")[0])) || (value?.size && value.size >= MinimumFileSize && value.size <= MaximumFileSize))
  }),
  description: Yup.string().min(5, 'Description must have minimum 5 characters.').max(300, 'Description must have maximum 300 characters.'),
  price: Yup.number().min(0, 'Price must be positive value.').typeError('Price must be numeric value.'),
  categoryName: Yup.object(),
  recommended: Yup.boolean(),
})

// Add Offer
export const addOfferValidationSchema = Yup.object({
  retailerId: Yup.object().nullable(),
  categoryName: Yup.object().required('Category is a required field.'),
  description: Yup.string(),
  saleStart: Yup.string().required('Sale start date is a required field.'),
  saleEnd: Yup.string().required('Sale end date is a required field.'),
  discountType: Yup.object(),
  price: Yup.number(),
  discountValue: Yup.number().when("discountType", {
    is: (discountType) => discountType?.label === DiscountType.percentage,
    then: Yup.number().min(0, 'Discount must be positive value.').max(100, 'Discount can have maximum 100 percentage.').typeError('Discount must be numeric value.')
  }).when("discountType", {
    is: (discountType) => discountType?.label === DiscountType.flat,
    then: Yup.number().min(0, 'Discount must be positive value.').max(Yup.ref("price"), "Discount price cannot be higher than selected category's lowest product price.Please change the discount price or try with other categories.").typeError('Discount must be numeric value.')
  }).required('Discount is a required field.')
})

// Edit Offer
export const editOfferValidationSchema = Yup.object({
  description: Yup.string(),
  saleStart: Yup.string().required('Sale start date is a required field.'),
  saleEnd: Yup.string().required('Sale end date is a required field.'),
  discountType: Yup.object(),
  price: Yup.number(),
  discountValue: Yup.number().when("discountType", {
    is: (discountType) => discountType?.label === DiscountType.percentage,
    then: Yup.number().min(0, 'Discount must be positive value.').max(100, 'Discount can have maximum 100 percentage.').typeError('Discount must be numeric value.')
  }).when("discountType", {
    is: (discountType) => discountType?.label === DiscountType.flat,
    then: Yup.number().min(0, 'Discount must be positive value.').max(Yup.ref("price"), 'Discount can not be more than price.').typeError('Discount must be numeric value.')
  }).required('Discount is a required field.')
})

// Add Subscription
export const addSubscriptionValidationSchema = Yup.object({
  name: Yup.string().required('Name is a required field.').min(3, 'Name must have minimum 3 characters.').max(25, 'Name must have maximum 25 characters.'),
  description: Yup.string().min(3, 'Description must have minimum 3 characters.').max(250, 'Description must have maximum 250 characters.'),
  type: Yup.object().required('Type is a required field.'),
  amount: Yup.number().typeError('Amount must be numeric value.').min(1, 'Amount must have minimum 1 value.').max(Math.pow(10, 12), `Amount must have maximum ${Math.pow(10, 12)} value.`).required('Amount is a required field.'),
})

// Campaigns
export const campaignValidationSchema = Yup.object({
  name: Yup.string().required('Name is a required field.').max(512, 'Name can be max 512 characters long.'),
  media: Yup.mixed().test(
    'fileSize',
    'File size must be less than 5MB',
    (value) => {            
      if(value.size){
        return (value && value.size < 5 * 1024 * 1024); // Check file size (5MB)
      }else{
        return true
      }
    }
  ),
  message: Yup.string().when("media", {
    is: (media) => !media,
    then: Yup.string().required('Either Media or Message is a required field.').max(920, 'Message can be max 920 characters long.'),
    otherwise: Yup.string().max(920, 'Message can be max 920 characters long.')
  }),
  tags: Yup.array(),
  allCustomers: Yup.boolean(),
  isScheduled: Yup.boolean(),
  occurance: Yup.string().when('isScheduled', {
    is: true,
    then: Yup.string().required('Please select an occurrence'),
    otherwise: Yup.string().notRequired(),
  }),
  occuranceDate: Yup.string().when(['occurance', 'isScheduled'], {
    is: (occurance, isScheduled) => occurance === 'donotrepeat' && isScheduled,
    then: Yup.string().required('Occurance date is required'),
    otherwise: Yup.string().notRequired(),
  }),
  occuranceDay: Yup.array().when(['occurance', 'isScheduled'], {
    is: (occurance, isScheduled) => occurance === 'repeat' && isScheduled,
    then: Yup.array().min(1, 'At least 1 day is required').required('Days are required'),
    otherwise: Yup.array().notRequired(),
  }),
  occuranceStartDate: Yup.date().when(['occurance', 'isScheduled'], {
    is: (occurance, isScheduled) => occurance === 'repeat' && isScheduled,
    then: Yup.date().required('Occurance start date is required'),
    otherwise: Yup.date().notRequired(),
  }),
  occuranceEndDate: Yup.date().when(['occurance', 'occuranceStartDate', 'isScheduled'], {
      is: (occurance, occuranceStartDate, isScheduled) =>
        occurance === 'repeat' && occuranceStartDate && isScheduled,
      then: Yup.date()
  .required('Occurance end date is required')
  .when('occuranceStartDate', (occuranceStartDate, schema) => {
    if (occuranceStartDate) {
      const nextDay = new Date(occuranceStartDate.getTime() + 24 * 60 * 60 * 1000); 
      return schema.min(nextDay, "End date must be after the start date and include the next day");
    }
    return schema; // No validation if startDate is not yet selected
  })
  .min(Yup.ref('occuranceStartDate'), 'End date must be after start date'),
  // .(Yup.ref('occuranceStartDate'), 'End date must be after start date'),
  otherwise: Yup.date().notRequired(),
  }),
  // occuranceTime: Yup.string().required('Occurance time is required'),
  occuranceTime: Yup.string().when(['occurance', 'isScheduled'], {
    is: (occurance, isScheduled) =>  isScheduled && (occurance == 'donotrepeat' || occurance == 'repeat'),
    then: Yup.string().required('Occurance time is required'),
    otherwise: Yup.string().notRequired(),
  }),
  draftTemplateId: Yup.object().when(['isScheduled', 'occurance'], {
    is:  (isScheduled, occurance) =>  { return isScheduled && (occurance == 'donotrepeat' || occurance == 'repeat')},
    then: Yup.object().required('Please select template'),
    otherwise: Yup.object().nullable(),
  }),

  customers: Yup.array().when(["tags", "allCustomers"], {
    is: (tags, allCustomers) => !(tags.length || allCustomers), 
    then: Yup.array().min(1, 'Either Tags or Customers is a required field.'),
  }),
})

// Feedback
export const feedbackValidationSchema = Yup.object({
  value: Yup.number(),
  feedbackMessage: Yup.string().when("value", {
    is: (value) => value === 1 || value === 2 || value === 3,
    then: Yup.string().required('Feedback Message is a required for lower ratings.')
  }),
})

// Coupons
export const couponValidationSchema = Yup.object({
  couponCode: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Coupon Code must be alphanumeric value.').strict().uppercase('Only Uppercase letter allowed.').min(3, 'Coupon Code must have minimum 3 characters.').max(10, 'Coupon Code must have maximum 10 characters.').required('Coupon Code is a required field.'),
  startDate: Yup.string().required('Start date is a required field.'),
  endDate: Yup.string().required('End date is a required field.'),
  type: Yup.object(),
  value: Yup.number().when("type", {
    is: (discountType) => discountType?.label === DiscountType.percentage,
    then: Yup.number().min(0, 'Discount must be positive value.').max(100, 'Discount can have maximum 100 percentage.').typeError('Discount must be numeric value.')
  }).required('Discount is a required field.'),
  description: Yup.string().min(5, 'Description must have minimum 5 characters.').max(300, 'Description must have maximum 300 characters.'),
})

// Order
export const orderValidationSchema = Yup.object({
  tableNumber: Yup.object().strict().required('Table number is a required field.'),
  customers: Yup.array().of(Yup.object().shape({
    label: Yup.string().matches(/^[0-9]+$/, 'must be numeric value').strict().min(6, 'should have minimum 6 digits').max(12, 'should have maximum 12 digits'),
    value: Yup.string().matches(/^[0-9]+$/, 'must be numeric value').strict().min(6, 'should have minimum 6 digits').max(12, 'should have maximum 12 digits'),
  })),
  items: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        quantity: Yup.number().min(1, 'please enter valid number.').required('required field.').typeError('must be numeric value.'),
        price: Yup.number(),
        productRetailerId: Yup.string()
      })
    ).min(1, 'Please select atleast 1 product.')
})

// Send Payment Link
export const sendPaymentLinkValidationSchema = Yup.object({
  coupon: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Coupon Code must be alphanumeric value.').min(3, 'Coupon Code have minimum 3 characters.').max(10, 'Coupon Code must have maximum 10 characters.'),
  customers: Yup.array().of(Yup.object().shape({
    label: Yup.string().matches(/^[0-9]+$/, 'must be numeric value').strict().min(6, 'should have minimum 6 digits').max(12, 'should have maximum 12 digits'),
    value: Yup.string().matches(/^[0-9]+$/, 'must be numeric value').strict().min(6, 'should have minimum 6 digits').max(12, 'should have maximum 12 digits'),
  })).min(1, 'Please select atleast 1 customer.')
})


// Reservation
export const reservationValidationSchema = Yup.object({
  tableId: Yup.object().shape({
    label: Yup.number(),
    value: Yup.number(),
  }).strict().required('Table Number is required field.')
})

// Template
export const templateValidationSchema = Yup.object({
  name: Yup.string().required('Name is a required field.'),
  headerType: Yup.string().required('Header is a required field.'),
  countryCode: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  body: Yup.string().required('Body is a required field.')
    .max(1028, 'Body must have maximum 1028 characters.')
    .test('contains-placeholder', 'Body must contain {{content}} to show campaign content.', (value) => {
      if (!value) return false;
      return value.includes('{{content}}');
    })
    .test('single-placeholder', 'Body must contain {{content}} only once.', (value) => {
      if (!value) return false;
      const occurrences = value.split('{{content}}').length - 1;
      return occurrences === 1;
    }),
  footer: Yup.string().required('Footer is a required field.').max(60, 'Footer must have maximum 60 characters.'),
  PHONE_NUMBER: Yup.object().when("buttons", {
    is: (buttons) => buttons.includes('PHONE_NUMBER'),
    then: Yup.object().shape({
      text: Yup.string().max(25, 'Label must have maximum 25 characters.').required('Label is required.'),
      phone_number: Yup.string()
        .matches(/^[0-9]+$/, 'Phone Number must be numeric value.')
        .max(11, 'Phone Number should have 11 digits.')
        .required('Phone Number is required.'),
    }),
    otherwise: Yup.object().shape({
      text: Yup.string().max(25, 'Label must have maximum 25 characters.'),
      phone_number: Yup.string()
        .matches(/^[0-9]+$/, 'Phone Number must be numeric value.')
        .max(11, 'Phone Number should have 11 digits.'),
    })
  }),
  URL: Yup.object().when("buttons", {
    is: (buttons) => buttons.includes('URL'),
    then: Yup.object().shape({
      text: Yup.string().max(25, 'Label must have maximum 25 characters.').required('Label is required.'),
      url: Yup.string().url('Invalid URL format (include http:// or https://).').max(2000, 'Value must have maximum 2000 characters.').required('Value is required.'),
    }),
    otherwise: Yup.object().shape({
      text: Yup.string().max(25, 'Label must have maximum 25 characters.'),
      url: Yup.string().url('Invalid URL format (include http:// or https://).').max(2000, 'Value must have maximum 2000 characters.'),
    })
  }),
  QUICK_REPLY: Yup.object().when("buttons", {
    is: (buttons) => buttons.includes('QUICK_REPLY'),
    then: Yup.object().shape({
      text: Yup.string().max(25, 'Text must have maximum 25 characters.').required('Text is required.'),
    }),
    otherwise: Yup.object().shape({
      text: Yup.string().max(25, 'Text must have maximum 25 characters.'),
    })
  }),
})