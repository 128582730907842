import React from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Form, Modal, Row, Col, Label } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Country, ImageLinks, ImageType, multiSelectDropdownStyle, selectDropdownStyle, serviceThrough, UK_States } from '../../../../constants/General';
import InputField from '../../../../components/InputField';
import { editRestaurant } from '../../utils/middleware/restaurant';
import { editRestaurantValidationSchema } from '../../../../constants/Schemas';
import { dispatch } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { generateDropdownOptionForStates, generateDropdownOptionFromObject, removeEmptyKeysFromObject, setSelectedCountry, setSelectedDropdownForSameValue, setSelectedService, trimObjectValues, uploadImage } from '../../../../services/middleware';
import { handleError } from '../../../../utils/toast';
import UploadLogo from '../../../../components/UpdateLogo';

export default function EditRestaurantModal(props) {
  // Props
  const { open, restaurant, handleCloseModal } = props

  // Store
  const { search, activeSort, sortOrder, page } = useSelector(state => state.superAdminRestaurants)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: restaurant?.name,
      addressLine1: restaurant?.addressLine1,
      addressLine2: restaurant?.addressLine2,
      zipCode: restaurant?.zipCode,
      state: setSelectedDropdownForSameValue(restaurant?.state),
      logo: restaurant?.logo,
      tagLine: restaurant?.tagLine,
      serviceThrough: setSelectedService(restaurant?.serviceThrough),
    },
    validationSchema: editRestaurantValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      trimObjectValues(values, 'logo')
      const formValues = { ...formik.values, serviceThrough: values.serviceThrough.map(datum => datum.value), state: values.state?.value, zipCode: values?.zipCode };
      const filterData = removeEmptyKeysFromObject(formValues)
      if (values.logo && !(typeof values.logo === 'string' && ImageLinks.includes(values.logo?.split(":")[0]))) {
        dispatch(Actions.SuperAdmin.SetLoading, true)
        const logoResponse = await uploadImage(values.logo, ImageType.LOGO).catch(err => {
          dispatch(Actions.SuperAdmin.SetLoading, false)
          handleError(err)
        })
        filterData.logo = logoResponse.fileLink;
      }
      editRestaurant(restaurant?.id, filterData, search, activeSort, sortOrder, page, handleCloseModal, resetForm)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Edit Restaurant details
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody >
            <Form role="form">
              <UploadLogo formik={formik} fieldName="logo" logo={restaurant?.logo} />
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Name" fieldName="name" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField disabled placeholder="Tagline" value={restaurant.tagLine} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label className='inputfield required-field'>
                    Service
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" isMulti value={formik.values.serviceThrough} options={generateDropdownOptionFromObject(serviceThrough)} isSearchable={false} styles={multiSelectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("serviceThrough", selectedOption);
                      }}
                    />
                    {formik.touched?.serviceThrough && formik.errors.serviceThrough && <span className="error-message input-group-alternative">{formik.errors.serviceThrough}</span>}
                  </section>
                </Col>
                <Col md={6}>
                  <InputField disabled placeholder="Table count" value={restaurant.tableCount} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <InputField disabled placeholder="Email" value={restaurant.email} />
                </Col>
                <Col md={6}>
                  <InputField disabled placeholder="Phone" value={restaurant.phone} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Address line 1" fieldName="addressLine1" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField placeholder="Address line 2" fieldName="addressLine2" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label className='inputfield required-field'>
                    State
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={generateDropdownOptionForStates(UK_States)} value={formik.values.state} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("state", selectedOption);
                      }}
                    />
                    {formik.touched?.state && formik.errors.state && <span className="error-message input-group-alternative">{formik.errors.state}</span>}
                  </section>
                </Col>
                <Col md={6}>
                  <InputField required placeholder="Zipcode" fieldName="zipCode" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className='inputfield'>
                    Country
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className='custom-select dropdown-disabled' options={Country} isDisabled value={setSelectedCountry(restaurant.country)} isSearchable={false} styles={selectDropdownStyle} />
                  </section>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}
