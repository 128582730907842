export default function subscriptions(
  initialState = {
    subscriptions: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_SUPER_ADMIN_SUBSCRIPTIONS':
      return {
        ...initialState,
        subscriptions: action.payload,
      };

    case 'SET_SUPER_ADMIN_SUBSCRIPTIONS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_SUPER_ADMIN_SUBSCRIPTIONS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_SUPER_ADMIN_SUBSCRIPTIONS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_SUPER_ADMIN_SUBSCRIPTIONS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_SUPER_ADMIN_SUBSCRIPTIONS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'SUPER_ADMIN_SUBSCRIPTIONS_RESET':
      return {
        ...initialState,
        subscriptions: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
