import React, { useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardBody, Col, Form, Modal, Row, InputGroup, } from "reactstrap";
import InputField from "../../../../components/InputField";

export default function DateRangeSelector(props) {
  // Props
  const { open, startDate, endDate, setStartDate, title, hideTodayShortcut = true, hideYesterdayShortcut = true, hideLastWeekShortcut = true, hideLastMonthShortcut = true, hideLastYearShortcut = true, viewMode, setEndDate, handleCloseModal } = props;

  //State
  const [selectedStartDate, setSelectedStartDate] = useState(new Date(startDate));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(endDate));

  const handleToday = () => {
    setStartDate(moment().startOf("day").toISOString())
    setEndDate(moment().endOf("day").toISOString())
    handleCloseModal();
  };

  const handleYesterDay = () => {
    setStartDate(moment().subtract(1, "day").startOf("day").toISOString());
    setEndDate(moment().subtract(1, "day").endOf("day").toISOString());
    handleCloseModal();
  };

  const handleLastWeek = () => {
    setStartDate(moment().subtract(1, "week").startOf("week").toISOString());
    setEndDate(moment().subtract(1, "week").endOf("week").toISOString());
    handleCloseModal();
  };

  const handleLastMonth = () => {
    setStartDate(moment().subtract(1, "month").startOf("month").toISOString());
    setEndDate(moment().subtract(1, "month").endOf("month").toISOString());
    handleCloseModal();
  };

  const handleLastYear = () => {
    setStartDate(moment().subtract(1, "year").startOf("year").toISOString());
    setEndDate(moment().subtract(1, "year").endOf("year").toISOString());
    handleCloseModal();
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size="lg"
      style={{ maxWidth: "630px" }}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          {title ? title : 'Range Selector'}
        </h3>
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleCloseModal}>
          <span aria-hidden={true}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </span>
        </button>
      </div>
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col md={6}>
                  <InputField disabled value={moment(selectedStartDate).format("DD/MM/YYYY")} placeholder="Start Date" />
                </Col>
                <Col md={6}>
                  <InputField disabled value={moment(selectedEndDate).format("DD/MM/YYYY")} placeholder="End Date" />
                </Col>
              </Row>
              <div className="center-div date-range-wrapper">
                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                  <Datetime key={selectedStartDate} input={false} dateFormat={viewMode === 'month' ? "MMM" : viewMode === 'year' ? "YYYY" : true} timeFormat={false} value={selectedStartDate} onChange={(value) => {
                    if (moment(value).isAfter(moment(selectedEndDate))) {
                      setSelectedEndDate(moment(value).endOf(viewMode));
                    }
                    setSelectedStartDate(moment(value).startOf((viewMode === 'month' || viewMode === 'year') ? viewMode : 'day'));
                  }} />
                </InputGroup>
                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                  <Datetime key={selectedEndDate} input={false} dateFormat={viewMode === 'month' ? "MMM" : viewMode === 'year' ? "YYYY" : true} timeFormat={false} value={selectedEndDate} onChange={(value) => setSelectedEndDate(moment(value).endOf((viewMode === 'month' || viewMode === 'year') ? viewMode : 'day'))} isValidDate={(currentDate) => currentDate.isAfter(moment(selectedStartDate).startOf((viewMode === 'month' || viewMode === 'year') ? viewMode : 'day').format())} />
                </InputGroup>
              </div>
              <div className="center-div flex-wrap py-3">
                {hideTodayShortcut && <Button color="outline-primary my-1" size="sm" onClick={() => handleToday()}>
                  Today
                </Button>}
                {hideYesterdayShortcut && <Button color="outline-primary my-1" size="sm" onClick={() => handleYesterDay()}>
                  Yesterday
                </Button>}
                {hideLastWeekShortcut && <Button color="outline-primary my-1" size="sm" onClick={() => handleLastWeek()}>
                  Last Week
                </Button>}
                {hideLastMonthShortcut && <Button color="outline-primary my-1" size="sm" onClick={() => handleLastMonth()}>
                  Last Month
                </Button>}
                {hideLastYearShortcut && <Button color="outline-primary my-1" size="sm" onClick={() => handleLastYear()}>
                  Last Year
                </Button>}
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button color="outline-primary" data-dismiss="modal" type="button" onClick={handleCloseModal}>
          Close
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={() => {
            setStartDate(moment(selectedStartDate).toISOString());
            setEndDate(moment(selectedEndDate).toISOString());
            handleCloseModal();
          }}
        >
          Submit
        </Button>
      </div>
    </Modal >
  );
}
