import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Card, CardHeader, Media, Table, Row, Button, Badge, } from "reactstrap";
import { useSelector } from 'react-redux'
import { getReservations, updateReservation, updateReservationStatus } from '../../utils/middleware/reservations'
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import { ReservationStatus, RoutesActions } from '../../../../constants/General';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import AcceptReservation from '../../components/AcceptReservation';
import DateRangeSelector from '../../components/DateRangeSelector';
import SearchInput from '../../../../components/SearchInput';
import { faPen } from '@fortawesome/free-solid-svg-icons';

export default function Reservations() {
  // Store
  const { reservations, search, startDate, endDate, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminReservations)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [selectedReservation, setSelectedReservation] = useState(null)
  const [openDeclineModal, setOpenDeclineModal] = useState(false)
  const [openAcceptModal, setOpenAcceptModal] = useState(false)
  const [openEditModal, setEditModal] = useState(false)
  const [dateRangeSelector, setDateRangeSelector] = useState(false)

  // Mount
  useEffect(() => {
    getReservations({ search, startDate, endDate, sortBy: activeSort, orderBy: sortOrder, page })
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminReservations.SetPage, pageCount)
    }
  }, [search, startDate, endDate, activeSort, sortOrder, totalCount, page, pageSize])

  const handleCloseModal = () => {
    setOpenDeclineModal(false)
    setOpenAcceptModal(false)
    setEditModal(false)
    setSelectedReservation(null)
  }

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminReservations.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Reservations</h3>
                <div className="right-div-wrap">
                  <Button color='primary' onClick={() => getReservations({ search, startDate, endDate, sortBy: activeSort, orderBy: sortOrder, page })}>
                    Refresh
                  </Button>
                  <SearchInput action={RoutesActions.reservations} />
                  <Button color="primary" outline onClick={() => setDateRangeSelector(true)}>
                    {moment(startDate).format('DD/MM/YYYY')} - {moment(endDate).format('DD/MM/YYYY')}
                  </Button>
                </div>
              </CardHeader>
              {reservations.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'reservations')}>No.</th> */}
                    <th scope='col' className={`${activeSort === 'whatsappNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('whatsappNumber', activeSort, sortOrder, 'reservations')}>Customer <FontAwesomeIcon icon={sortIcon(activeSort, 'whatsappNumber', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'tableNumber' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('tableNumber', activeSort, sortOrder, 'reservations')}>Table Number <FontAwesomeIcon icon={sortIcon(activeSort, 'tableNumber', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'startTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('startTime', activeSort, sortOrder, 'reservations')}>Start Time <FontAwesomeIcon icon={sortIcon(activeSort, 'startTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'endTime' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('endTime', activeSort, sortOrder, 'reservations')}>End Time <FontAwesomeIcon icon={sortIcon(activeSort, 'endTime', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'numberOfGuests' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('numberOfGuests', activeSort, sortOrder, 'reservations')}>Guests <FontAwesomeIcon icon={sortIcon(activeSort, 'numberOfGuests', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'status' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('status', activeSort, sortOrder, 'reservations')}>Status <FontAwesomeIcon icon={sortIcon(activeSort, 'status', sortOrder)} /></th>
                    <th scope='col' />
                  </tr>
                </thead>
                <tbody>
                  {reservations.map((reservation, index) => {
                    return <tr key={index}>
                      {/* <th className='serial-number'>{index + 1 + (page - 1) * pageSize}</th> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {reservation?.customerNumber} {reservation?.customerName && `(${reservation?.customerName})`}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <th>{reservation.tableNumber}{' '}{(reservation.status === ReservationStatus.PENDING || reservation.status === ReservationStatus.RESERVED) && <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                        setSelectedReservation(reservation)
                        setEditModal(true)
                      }}>
                        <FontAwesomeIcon icon={faPen} />
                      </Button>}</th>
                      <th>{reservation.startTime ? moment(reservation.startTime).format('DD-MM-YYYY hh:mm A') : ''}</th>
                      <th>{reservation.endTime ? moment(reservation.endTime).format('DD-MM-YYYY hh:mm A') : ''}</th>
                      <th>{reservation.numberOfGuests}</th>
                      <th>
                        <Badge className={(reservation.status === ReservationStatus.RESERVED || reservation.status === ReservationStatus.COMPLETE) ? 'badge-success' : reservation.status === ReservationStatus.PENDING ? 'badge-info' : 'badge-danger'}>
                          {reservation.status === ReservationStatus.RESERVED ? 'Reserved' : reservation.status === ReservationStatus.PENDING ? 'Pending' : reservation.status === ReservationStatus.CANCELLED ? 'CANCELLED' :
                            reservation.status === ReservationStatus.DECLINED ? 'DECLINED' : reservation.status === ReservationStatus.COMPLETE ? 'COMPLETE' : ''}
                        </Badge>
                      </th>
                      <td className="text-right">
                        {reservation.status === ReservationStatus.PENDING && <>
                          <Button color="primary" size="sm" title='Reserve' onClick={() => {
                            setSelectedReservation(reservation)
                            setOpenAcceptModal(true)
                          }}>
                            Reserve
                          </Button>
                          <Button color="outline-primary" size="sm" title='Decline' onClick={() => {
                            setSelectedReservation(reservation)
                            setOpenDeclineModal(true)
                          }}>
                            Decline
                          </Button></>}
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminReservations.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {openAcceptModal && <AcceptReservation open={openAcceptModal} reservation={selectedReservation} handleCloseModal={() => setOpenAcceptModal(false)} handleSuccess={(tableId) => updateReservationStatus({ id: selectedReservation?.id, payload: { tableId, status: ReservationStatus.RESERVED }, search, sortBy: activeSort, orderBy: sortOrder, page, handleCloseModal })} />}
      {openEditModal && <AcceptReservation open={openEditModal} tableId={{ label: selectedReservation.tableNumber, value: selectedReservation.tableId, isdisabled: true }} reservation={selectedReservation} handleCloseModal={() => setEditModal(false)} handleSuccess={(tableId) => updateReservation({ id: selectedReservation?.id, payload: { tableId }, search, sortBy: activeSort, orderBy: sortOrder, page, handleCloseModal })} />}
      <ConfirmationModal open={openDeclineModal} handleCloseModal={() => setOpenDeclineModal(false)} handleSuccess={() => updateReservationStatus({ id: selectedReservation?.id, payload: { status: ReservationStatus.DECLINED }, search, startDate, endDate, sortBy: activeSort, orderBy: sortOrder, page, handleCloseModal })} />

      {dateRangeSelector && <DateRangeSelector
        open={dateRangeSelector}
        handleCloseModal={() => setDateRangeSelector(false)}
        startDate={startDate}
        endDate={endDate}
        setStartDate={(date) => dispatch(Actions.RestaurantAdminReservations.SetStartDate, date)}
        setEndDate={(date) => dispatch(Actions.RestaurantAdminReservations.SetEndDate, date)}
      />}
    </div >
  )
}
