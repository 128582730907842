export function dashboardDataDTO(data) {
  if (data) {
    return {
      subscriptionDetails: data?.subscriptionDetails && data?.subscriptionDetails ? {
        count: data?.subscriptionDetails?.count || 0,
      } : null,
      restaurantDetails: data?.restaurantDetails && data?.restaurantDetails ? {
        count: data?.restaurantDetails?.count || 0,
      } : null,
      paymentDetails: data?.paymentDetails && data?.paymentDetails ? {
        count: data?.paymentDetails?.count || 0,
      } : null
    }
  }
}