import React, { useEffect } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { featurePermissions } from '../../constants/General';

export default function FeatureList(props) {
  // Props
  const { permissions, setPermissions } = props

  const handleAccess = (feature, checked) => {
    setPermissions(obj => ({ ...obj, ...(['orders', 'categories', 'products'].indexOf(feature) !== -1 ? { orders: checked, categories: checked, products: checked } : { [feature]: checked }) }))
  }

  useEffect(() => {
    setPermissions(obj => ({ ...obj, tables: true, feedbacks: true }))
  }, [setPermissions])

  const middleIndex = Math.ceil(Object.keys(featurePermissions).length / 2);
  const firstColumn = Object.keys(featurePermissions).slice(0, middleIndex);
  const secondColumn = Object.keys(featurePermissions).slice(middleIndex);

  return (
    <>
      <Label className='mb-2'>
        Features
      </Label>
      <Row>
        <Col md={6}>
          {firstColumn.map((feature) => (
            <Row key={feature}>
              <Col className='space-between-div'>
                <Label className='inputfield'>{featurePermissions[feature]}</Label>
                <FormGroup check inline>
                  <Input type='checkbox' disabled={feature === 'tables' || feature === 'pos'} checked={permissions[feature] || false} onChange={(e) => handleAccess(feature, e.target.checked)} />
                </FormGroup>
              </Col>
            </Row>
          ))}
        </Col>
        <Col md={6}>
          {secondColumn.map((feature) => (
            <Row key={feature}>
              <Col className='space-between-div'>
                <Label className='inputfield'>{featurePermissions[feature]}</Label>
                <FormGroup check inline>
                  <Input type='checkbox' disabled={feature === 'feedbacks'} checked={permissions[feature] || false} onChange={(e) => handleAccess(feature, e.target.checked)} />
                </FormGroup>
              </Col>
            </Row>
          ))}
        </Col>
      </Row >
    </>
  )
}
