import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTemplateAnalytics } from '../../utils/middleware/templateAnalytics';
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import CustomPagination from '../../../../components/Pagination';
import { Card, CardHeader, Table, Row, Badge, UncontrolledAlert } from "reactstrap";
import { RoutesActions, TemplateAnalyticsMessage } from '../../../../constants/General';
import SearchInput from '../../../../components/SearchInput';
import NoDataLabel from '../../../../components/NoDataLabel';
import moment from 'moment';

export default function TemplateAnalytics() {
  // Store
  const { templates, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminTemplatesAnalytics)
  const { pageSize } = useSelector(state => state.user)

  // Mount
  useEffect(() => {
    getTemplateAnalytics(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminTemplatesAnalytics.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, page, pageSize, totalCount])

  // Unmout
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminTemplatesAnalytics.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <UncontrolledAlert color="light">
          {TemplateAnalyticsMessage}
        </UncontrolledAlert >
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Templates Analytics</h3>
                <div className='right-div-wrap'>
                  <SearchInput action={RoutesActions.templatesAnalytics} />
                </div>
              </CardHeader>
              {templates.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope='col' >Date </th>
                    <th scope='col' >Campaign </th>
                    <th scope='col' >Delivered </th>
                    <th scope='col' >Sent </th>
                    <th scope='col' >Read</th>
                    <th scope='col' >Dropout</th>
                    <th scope='col' >Recipients</th>
                    <th scope='col' >Skip Customers</th>
                    <th scope='col' >Status</th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((template, index) => {
                    return <tr key={index}>
                      <td >{template.createdAt ? moment(template.createdAt).format('DD-MM-YYYY') : ''}</td>
                      <td >{template?.campaign?.name || ''}</td>
                      <th >{template?.deliveredCount}</th>
                      <th >{template?.sendCount}</th>
                      <th >{template?.readCount}</th>
                      <th >{template?.dropoutCount}</th>
                      <th >{template?.initialSendCount}</th>
                      <th >{template?.skippedCount}</th>
                      <td>
                        <Badge
                          className={
                            template.isSuccessfullyRun
                              ? "badge-success"
                              : "badge-danger"
                          }
                        >
                          {template.isSuccessfullyRun ? "Success" : "Re-Run"}
                        </Badge>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminTemplatesAnalytics.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>
    </div >
  )
}
