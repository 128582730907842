import React, { useState } from 'react';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Actions } from '../../redux/actions';
import { dispatch } from '../../utils/store';
import { Document, Page, pdfjs } from 'react-pdf';
import './imagePreview.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function ImagePreview(props) {
  const { url, fileType } = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  return (
    <div className='image-preview-main-container'>
      <div className='image-preview-close-icon'>
        <FontAwesomeIcon icon={faXmark} size='2x' onClick={() => dispatch(Actions.User.SetImagePreview, false)} />
      </div>
      {fileType === 'pdf' ? <div className='pdf-container'>
        <FontAwesomeIcon icon={faChevronLeft} className="cursor-pointer" size="2x" color='#FFF' onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)} />
        <div className='pdf-viewer'>
          <Document
            file={{
              url: url,
            }}

            onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          {numPages && <p>
            Page {pageNumber} of {numPages}
          </p>}
        </div>
        <FontAwesomeIcon icon={faChevronRight} className="cursor-pointer" size="2x" color='#FFF' onClick={() => pageNumber < numPages && setPageNumber(pageNumber + 1)} />
      </div> : fileType === 'image' ? <div className='image-preview-container'>
        <div className='image-preview'>
          {url ? <img src={url} alt="Oops..." className='image' />
            : <h1>Image not available.</h1>}
        </div>
      </div> : <div className='image-preview-container'>
        <div className='image-preview'>
          {url ? 
            <video
              src={url}
              className='image'
              controls                    
            />
            : <h1>Video not available.</h1>}
        </div>
      </div>}
    </div >
  )
}
