import { RESERVATIONS_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { reservationsListDTO } from "../dtos/reservations"

export const getReservations = ({ search, startDate, endDate, sortBy, orderBy, page = 1 }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!startDate ? { startDate } : {}),
    ...(!!endDate ? { endDate } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, RESERVATIONS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminReservations.SetReservations, reservationsListDTO(res.data.data.rows))
      dispatch(Actions.RestaurantAdminReservations.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getOpenTables = (startTime, endTime) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!startTime ? { startTime } : {}),
    ...(!!endTime ? { endTime } : {})
  }

  API(API_REQUEST.get, `${RESERVATIONS_API}/open-tables`, { params })
    .then(res => {
      const tables = res?.data?.data?.rows?.map((table) => {
        return {
          label: table?.tableNumber,
          value: table?.id
        }
      })
      dispatch(Actions.RestaurantAdminReservations.SetTables, tables)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateReservationStatus = ({ id, payload, search, startDate, endDate, sortBy, orderBy, page, handleCloseModal }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${RESERVATIONS_API}/${id}`, payload)
    .then(res => {
      handleSuccess(res)
      handleCloseModal()
      getReservations({ search, startDate, endDate, sortBy, orderBy, page })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateReservation = ({ id, payload, search, startDate, endDate, sortBy, orderBy, page, handleCloseModal }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${RESERVATIONS_API}/${id}/table`, payload)
    .then(res => {
      handleSuccess(res)
      handleCloseModal()
      getReservations({ search, startDate, endDate, sortBy, orderBy, page })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}