import { CUSTOMERS_API, CUSTOMERS_LIST_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { customersListDTO } from "../dtos/customers"

export const getCustomers = (search, sortBy, orderBy, page = 1, startDate, endDate) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!endDate ? { endDate } : {}),
    ...(!!startDate ? { startDate } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, CUSTOMERS_LIST_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminCustomers.SetCustomers, customersListDTO(res?.data?.data?.rows))
      dispatch(Actions.RestaurantAdminCustomers.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getCustomer = async (id) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  return await API(API_REQUEST.get, `${CUSTOMERS_API}/${id}`)
    .then(res => {
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return res?.data?.data || []
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return []
    })
}

export const editCustomer = (id, data, handleCloseEditModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${CUSTOMERS_API}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      handleCloseEditModal()
      getCustomers()
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteCustomer = (id, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${CUSTOMERS_API}/${id}`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal(false)
      getCustomers()
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const importCustomer = (data, handleCloseModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${CUSTOMERS_API}/upload`, data)
    .then(res => {
      handleSuccess(res)
      handleCloseModal(false)
      getCustomers()
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}