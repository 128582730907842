export default function notifications(
    initialState = {
      notification: [],
    },
    action
  ) {    
    switch (action.type) {
      case 'SET_NOTIFICATION':
        return {
          ...initialState,
          notification: action.payload,
        };
  
      default:
        return initialState;
    }
  }