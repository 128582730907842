import React from 'react';
import { Table, Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoDataLabel from '../../../../components/NoDataLabel';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function CustomersTagsModal(props) {
  // Props
  const { open, customers, tags, handleCloseModal } = props

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Customers/Tags
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body flex p-0 customer-tag-modal-overflow" >
        {(!!customers?.length || !!tags?.length) ? <div className='full-width column-gap'>
          {!!customers?.length && <Table className="table table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope='col' className='serial-number cursor-pointer'>No.</th>
                <th scope='col' >Customers</th>
                <th scope='col' >Number</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => {
                return <tr key={index}>
                  <td className='serial-number'>{index + 1}</td>
                  <td>{customer.name}</td>
                  <td>{customer.whatsappNumber}</td>
                </tr>
              })}
            </tbody>
          </Table>}
          {!!tags?.length && <Table className="table table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope='col' className='serial-number cursor-pointer'>No.</th>
                <th scope='col' >Tags</th>
              </tr>
            </thead>
            <tbody>
              {tags.map((tag, index) => {
                return <tr key={index}>
                  <td className='serial-number'>{index + 1}</td>
                  <td>{tag.tagName}</td>
                </tr>
              })}
            </tbody>
          </Table>}
        </div> : <NoDataLabel />}
      </div>
      <div className="modal-footer pt-2">
        <Button color='primary' type="button" onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    </Modal>
  )
}
