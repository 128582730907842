import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation} from "react-router-dom";
import RestaurantSidebar from "../../../../components/RestaurantSidebar";
import Footer from "../../../../components/Footers";
import Header from "../../../../components/Navbars";
import { RestaurantRoutes } from "../../../../constants/Routes";
import Loader from "../../../../components/Loader";
import { getRestaurant } from "../../utils/middleware/restaurant";
import { getLocalStorageUserData, getLocalStorageIsNotification } from "../../../../utils/session";
import { getSettings } from "../../utils/middleware/settings";
import NotificationComponent  from "./../../components/Notification"

const Main = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const loading = useSelector((state) => state.restaurantAdmin.loading);

  const getBrandText = () => {
    let route = RestaurantRoutes.find(
      (value) => location.pathname.indexOf(value.layout + value.path) !== -1
    );
    return route?.name || "Brand";
  };

  // Mount
  useEffect(() => {
    getRestaurant(getLocalStorageUserData()?.restaurantId);
    getSettings();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <RestaurantSidebar
        routes={RestaurantRoutes}
        logo={{
          innerLink: "/admin/dashboard",
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <header className="site-header">
          <Header brandText={getBrandText(location.pathname)} />
          
        </header>
        <div
          className={`main-content-wrap`}
        >
          <NotificationComponent />
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Main;
