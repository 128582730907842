import React from "react";
import { Handle, Position } from "reactflow";

import { v4 as uuidv4 } from 'uuid';
const getId = () => uuidv4();

//custome node
function StartNode({id, data, selected, onDelete }) {
    return (
        <div
          className={`start-box   ${
            selected ? "custom-border" : ""
          } `}
        >
          <button
            onClick={() => onDelete(id)}
            className="delete-node"
            title="Delete Node"
          >
            ×
          </button> 
          <div className="d-flex flex-column">
            <div className="start-node web-theme-color">
                Start
            </div>
          </div>
    
          <Handle
            id={getId()}
            type="source"
            position={Position.Right}
            className="custom-handle"
          />
        </div>
    );
}

export default StartNode;