import { CATEGORIES_API, PRODUCTS_API } from "../../../../constants/Configs"
import { API_REQUEST, ProductFechingTimeout } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize, sortData } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { categoriesListDTO } from "../dtos/categories"
import { productsListDTO } from "../dtos/products"

export const getProducts = (search, activeSort, sortOrder, page) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)

  API(API_REQUEST.get, PRODUCTS_API)
    .then(res => {
      dispatch(Actions.RestaurantAdminProducts.SetProducts, productsListDTO(res?.data?.data?.rows))
      filteredData(productsListDTO(res?.data?.data?.rows), search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdminProducts.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addProduct = (data, search, activeSort, sortOrder, page, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, PRODUCTS_API, data)
    .then((res) => {
      setTimeout(() => {
        handleSuccess(res)
        handleCloseAddModal()
        getProducts(search, activeSort, sortOrder, page)
      }, ProductFechingTimeout)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteProduct = (id, retailerId, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${PRODUCTS_API}/${id}?retailerId=${retailerId}`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal(false)
      getProducts(search, activeSort, sortOrder, page)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getCategoriesForProducts = async () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    page: 1, size: 1000
  }

  await API(API_REQUEST.get, CATEGORIES_API, { params })
    .then(res => {
      const categories = categoriesListDTO(res.data.data.rows)?.map((cat) => {
        return {
          label: cat?.name,
          value: cat?.name,
          isParentCategory: cat?.isParentCategory,
          subCategories: !!cat?.subCategories?.length ? cat?.subCategories.map(sub => ({
            label: sub?.name,
            value: sub?.name,
          })) : []
        }
      })
      dispatch(Actions.RestaurantAdminProducts.SetCategories, categories)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const filteredData = (products, search, activeSort, sortOrder, page) => {
  let data = []
  const query = search?.toLowerCase()
  if (products?.length) {
    data = sortData(products, activeSort || 'id', sortOrder, activeSort === 'recommended').filter((item) =>
      item.name.toLowerCase().includes(query) ||
      item.price.toLowerCase().includes(query) ||
      (item.recommended && 'recommended'.includes(query)) ||
      item.category.toLowerCase().includes(query) ||
      item.description.toLowerCase().includes(query)
    );
  }

  dispatch(Actions.RestaurantAdminProducts.SetTotalCount, data?.length)
  getProductsByPagination(data, page)
}

export const getProductsByPagination = (products, page) => {
  let data = []
  if (products?.length) {
    products.forEach((product, index) => {
      if (index >= ((page - 1) * getPageSize()) && (index < (getPageSize() * page))) {
        data.push(product)
      }
    })
  }
  dispatch(Actions.RestaurantAdminProducts.SetProductsWithPagination, data)
}

export const handleSort = (param, activeSort, sortOrder) => {
  let paramSortOrder = ''
  if (param) {
    if (activeSort !== param) {
      paramSortOrder = 'ASC';
    } else {
      paramSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    }
  }
  dispatch(Actions.RestaurantAdminProducts.SetActiveSort, param);
  dispatch(Actions.RestaurantAdminProducts.SetSortOder, paramSortOrder);
}