import React, { useState, useEffect, useRef  } from "react";
import { Input,Button, InputGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { dispatch } from '../../../../../utils/store';
import { ImageType } from '../../../../../constants/General';
import { Actions } from '../../../../../redux/actions';
import { uploadNodeImage } from "../../../utils/middleware/chatAtomation";
import { toast } from "react-toastify";

export default function Sidebar({
  nodeText,
  setNodeText,
  nodeType,
  setNodeType,
  nodeValue,
  setNodeLabel,
  nodeLabel,
  setNodeValue,
  selectedNode,
  setSelectedElements,
  setSelectedFile,
  setFileUrl,
  fileUrl,
  selectedFile,
  selectedPDFFile,
  selectedVideoFile,
  setSelectedVideoFile,  
  setSelectedPDFFile,
  nodes
}) {
  const [conditionValues, setConditionValues] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isNodeDisabled, setIsNodeDisabled] = useState(false);
  const [nodeID, setNodeID] = useState("");
  const [uploadPDF, setUploadPDF] = useState([{ id: 0, pdf: null }]);  
  const [uploadImages, setUploadImages] = useState([{ id: 0, file: null }]);
  const [uploadVideo, setUploadVideo] = useState([{ id: 0, video: null }]);
  const pdfInputRefs = useRef(uploadPDF.map(() => React.createRef()));
  const imageInputRefs = useRef(uploadImages.map(() => React.createRef()));
  const videoInputRefs = useRef(uploadVideo.map(() => React.createRef()));



  // useEffect(() => {
  //   if (selectedNode && selectedNode.type === "conditionnode") {
  //     setConditionValues(nodeValue);
  //   }
  // }, [selectedNode, nodeValue]);
  useEffect(() => {
    const startNodes = nodes.filter(node => node.type === "startnode");    
    if(startNodes.length > 0){
      setIsNodeDisabled(true)
    }else{
      setIsNodeDisabled(false)
    }    
  }, [nodes]);

  const handleDragStart = (event) => {
    if (isNodeDisabled) {
        event.preventDefault(); // Prevent dragging
    } else {
        onDragStart(event, "startnode"); // Allow dragging
    }
  };

  useEffect(() => { 
    setUploadPDF([{ id: 0, pdf: null }])
    setUploadImages([{ id: 0, file: null }]);
    setUploadVideo([{ id: 0, video: null }]);   
    pdfInputRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.value = '';
      }
    });
    imageInputRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.value = '';
      }
    });
    videoInputRefs.current.forEach((ref) => {
      if (ref.current) {
        ref.current.value = '';
      }
    });
  }, [selectedNode])
  

  useEffect(() => {    
    if (selectedNode && (selectedNode.type === "conditionnode" || selectedNode.type === "textnode")) {      
      if( selectedNode.type === "textnode" && nodeID !== selectedNode.id){
        setIsButtonDisabled(false);
      }
      setConditionValues(nodeValue);
      setNodeID(selectedNode.id)
    } else {
      setConditionValues([]);
    }
  }, [selectedNode, nodeValue]);
  
  const handleLabelInputChange = (event) => {
    setNodeText(event.target.value);
  };
  
  // const handleFileUrl = (event) => {
  //   setFileUrl(event.target.value);
  //   if(event.target.value.length > 0){
  //     setSelectedFile(null)
  //   }
  // };

  const handleTypeInputChange = (event) => {
    setNodeType(event.target.value);
  };

  const handleValueInputChange = (value) => {
    setNodeValue(value);
  };

  const handleInputChange = (value) => {
    setNodeLabel(value);
  };

  const handleFileChange = (index) => (event) => {
    const newFiles = [...uploadImages]; // Create a copy of the current state
    newFiles[index] = event.target.files[0]; // Update only the selected index
    setUploadImages(newFiles); // Update the state with the new array
  };


  // const handlePDFFileChange = (index) => (event) => {
  //   const newFiles = [...uploadPDF]; // Create a copy of the current state
  //   newFiles[index] = event.target.files[0]; // Update only the selected index
  //   setUploadPDF(newFiles); // Update the state with the new array
  // };

  const handleAddValueInputChange = (value, id) => {
    const updatedValues = conditionValues.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    console.log(updatedValues)

    if (selectedNode.type === "textnode" && updatedValues.length === 3) {
      setIsButtonDisabled(true);
    } else{
      setIsButtonDisabled(false);
    }    
    setConditionValues(updatedValues);
    setNodeValue(updatedValues);
  };

  const addConditionValue = () => {
    const newInput = { id: uuidv4(), value: "" };
    let temp = conditionValues;
    let updatedValues = [];
    if(temp.length > 0) {
      updatedValues = [...temp, newInput];
    }else{
      updatedValues.push(newInput);
    }    
    // if (selectedNode.type === "textnode" && temp.length >= 2) {
    //   setIsButtonDisabled(true);
    // } else {
    //   setIsButtonDisabled(false);
    // }
    setConditionValues(updatedValues);
    setNodeValue(updatedValues);
  };

  const removeConditionValue = (id) => {
    const updatedValues = conditionValues.filter((item) => item.id !== id);
    if (selectedNode.type === "textnode" && updatedValues.length === 3) {
      setIsButtonDisabled(true);
    }else{
      setIsButtonDisabled(false);
    } 
    setConditionValues(updatedValues);
    setNodeValue(updatedValues);
  };

  const onDragStart = (event, nodeType) => {    
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleUploadPDF = () => {    
    callUploadFiles(uploadPDF);    
  }

  const handleUploadImage = () => {
    callUploadFiles(uploadImages);    
  }

  const callUploadFiles = async(fileData,fileType) => {
    // dispatch(Actions.RestaurantAdmin.SetLoading, true)        
      // Process each data item in the uploadData array             
        // const fileType = fileData[0]?.type?.split('/')[1];        
        if(fileData.length > 0){
          await uploadNodeImage(fileData, fileType === 'pdf' ? ImageType.CAMPAIGN_PDF : fileType === 'video' ? ImageType.CAMPAIGN_VIDEO : ImageType.CAMPAIGN_IMAGE,true).then((res) =>{                             
            if(fileType === 'pdf'){
              setSelectedPDFFile(res.data.data)              
            }else if(fileType === 'video'){
              setSelectedVideoFile(res.data.data)
            }else{
              setSelectedFile(res.data.data);              
            }
          }) ;        
        }
  }

  const handlePDFFileChangeNew = (index) => (event) => {
    const newFiles = [...uploadPDF];
    newFiles[index].pdf = event.target.files[0]; // Store the selected file
    setUploadPDF(newFiles);
  };

  // Add a new file input
  const handleAddPDFFileInput = () => {
    if (uploadPDF.length < 3) {
      setUploadPDF([...uploadPDF, { id: uploadPDF.length, pdf: null }]);
    }
  };

  // Remove a specific file input
  const handleRemovePDFFileInput = (index) => () => {
    setUploadPDF(uploadPDF.filter((_, i) => i !== index));
  };

  // Handle PDF upload (optional implementation)
  const handleUploadPDFNew = () => {
    // Logic for uploading the PDFs
    const pdfArray = uploadPDF.map(item => item.pdf);
    console.log('Uploading PDFs:', uploadPDF);
    if(uploadPDF[0].pdf){    
      callUploadFiles(pdfArray,"pdf");   
    }else{
      toast.error("Please Choose the PDF", { position: 'bottom-right', theme: 'colored' });
    }
    // Example: you could send this `uploadPDF` array to a backend
  };


  const handleFileChangeNew = (index) => (event) => {
    const newImages = [...uploadImages];
    newImages[index].file = event.target.files[0]; // Store the selected image
    setUploadImages(newImages);
  };

  // Add a new image input, but only if there are fewer than 3 inputs
  const handleAddFileInput = () => {
    if (uploadImages.length < 3) {
      const newId = uploadImages.length; // Generate a new id
      setUploadImages([...uploadImages, { id: newId, file: null }]);
    }
  };

  // Remove a specific image input
  const handleRemoveFileInput = (index) => () => {
    setUploadImages(uploadImages.filter((_, i) => i !== index));
  };

  // Handle image upload (optional implementation)
  const handleUploadImageNew = () => {
    // Logic for uploading the images
    const imageArray = uploadImages.map(item => item.file);
    console.log('Uploading Images:', uploadImages);
    if(uploadImages[0].file){
      callUploadFiles(imageArray,"image");   
    }else{
      toast.error("Please Choose the Image", { position: 'bottom-right', theme: 'colored' });
    }
    // You can send the `uploadImages` array to a backend
  };

  const handleVideoChangeNew = (index) => (event) => {
    const newVideo = [...uploadVideo];
    newVideo[index].file = event.target.files[0]; // Store the selected image
    setUploadVideo(newVideo);
  };

  // Add a new image input, but only if there are fewer than 3 inputs
  const handleAddVideoInput = () => {
    if (uploadVideo.length < 3) {
      const newId = uploadVideo.length; // Generate a new id
      setUploadVideo([...uploadVideo, { id: newId, file: null }]);
    }
  };

  // Remove a specific image input
  const handleRemoveVideoInput = (index) => () => {
    setUploadVideo(uploadVideo.filter((_, i) => i !== index));
  };

  // Handle image upload (optional implementation)
  const handleUploadVideoNew = () => {
    // Logic for uploading the images
    const videoArray = uploadVideo.map(item => item.file);
    console.log('Uploading Images:', uploadVideo);
    if(uploadVideo[0].video){    
      callUploadFiles(videoArray,"video");   
    }else{
      toast.error("Please Choose the Video", { position: 'bottom-right', theme: 'colored' });
    }
    // You can send the `uploadImages` array to a backend
  };


  return (
    <aside className="sidebar-container">
      {selectedNode ? (
        <div>
          <h3 className="update-label">Update Node</h3>
          {/* {selectedNode.type === "textnode" ? (
            <div>
              <label className="node-label">Node Label:</label>
              <Input
                type="text"
                className="custom-input"
                value={nodeText}
                placeholder="Enter Label..."
                onChange={handleLabelInputChange}
              />
              {/* <label className="node-label">Select Option:</label>  
              <select className="custom-input"
                value={nodeType}
                onChange={handleTypeInputChange}  > 
                <option value="text">Text</option>
                 <option value="pdf">PDF</option>
                <option value="image">Image</option> 
              </select> }
              <label className="node-label">Add message:</label>
              <Input
                type="text"
                className="custom-input"
                placeholder="Enter Message..."
                value={nodeValue}
                onChange={e => handleValueInputChange(e.target.value)}
              />
            </div>
          ) :  */}
          {selectedNode.type === "conditionnode" || selectedNode.type === "textnode" ? (
            <>
            {selectedNode.type === "textnode" && (
              <>
              <label className="node-label">Add message:</label>
              {/* <Input
                type="text"
                className="custom-input"
                value={nodeLabel}
                onChange={e => handleInputChange(e.target.value)}
                placeholder="Enter Label..."
              /> */}
              <Input
                type="text"
                className="custom-input"
                placeholder="Enter Message..."
                value={nodeText}
                onChange={handleLabelInputChange}
              />
              {/* <label className="node-label">Select Option:</label>  
              <select className="custom-input"
                value={nodeType}
                onChange={handleTypeInputChange}  > 
                <option value="text">Text</option>
                 <option value="pdf">PDF</option>
                <option value="image">Image</option> 
                <option value="button">Button</option> 
              </select> */}
              {/* {(nodeType === "text" || nodeType === "") &&<> <label className="node-label">Add message:</label>
              <Input
                type="text"
                className="custom-input"
                placeholder="Enter Message..."
                value={nodeText}
                onChange={handleLabelInputChange}
              /></>
              } */}
              {/* {(nodeType ==="pdf" || nodeType === "image") && <> */}
                <label className="node-label">PDF</label>

                { uploadPDF.map((fileObj, index) => (
                  <div key={fileObj.id} className="file-input-wrapper position-relative">
                    <Input
                      innerRef={pdfInputRefs.current[index]}
                      id={`examplePDFFile${index}`}
                      accept=".pdf"
                      name="file"
                      type="file"
                      className="choose-height"
                      onChange={handlePDFFileChangeNew(index)}
                    />                                       
                    <Button
                      className="delete-button"
                      onClick={handleRemovePDFFileInput(index)}
                      color="danger"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </div>
                ))}
                <div>
                  <button
                    className="add-button upload-button mr-2"
                    onClick={handleUploadPDFNew}                                    
                  >
                    Upload PDF
                  </button>
                  <button className="add-button" onClick={handleAddPDFFileInput}>
                    +
                  </button>
                </div>
                <label className="node-label">Image</label>
                {uploadImages.map((fileObj, index) => (
                  <div key={fileObj.id} className="file-input-wrapper position-relative">
                    <Input
                      innerRef={imageInputRefs.current[index]}
                      id={`exampleFile${fileObj.id}`}
                      accept="image/*"
                      name="file"
                      type="file"
                      className="choose-height"
                      onChange={handleFileChangeNew(index)}
                    />
                    {/* Delete button next to each input */}                    
                    <Button
                      className="delete-button"
                      onClick={handleRemoveFileInput(index)}
                      color="danger"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    
                  </div>
                ))}
                <div>
                  <button
                    className="add-button upload-button mr-2"
                    onClick={handleUploadImageNew}                                   
                  >
                    Upload Image
                  </button>
                  <button className="add-button" onClick={handleAddFileInput}>
                    +
                  </button>
                </div>
                <label className="node-label">Video</label>
                {uploadVideo.map((fileObj, index) => (
                  <div key={fileObj.id} className="file-input-wrapper position-relative">
                    <Input
                      innerRef={videoInputRefs.current[index]}
                      id={`exampleFile${fileObj.id}`}
                      accept="video/*"
                      name="file"
                      type="file"
                      className="choose-height"
                      onChange={handleVideoChangeNew(index)}
                    />
                    {/* Delete button next to each input */}                    
                    <Button
                      className="delete-button"
                      onClick={handleRemoveVideoInput(index)}
                      color="danger"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    
                  </div>
                ))}
                <div>
                  <button
                    className="add-button upload-button mr-2"
                    onClick={handleUploadVideoNew}                                    
                  >
                    Upload Video
                  </button>
                  <button className="add-button" onClick={handleAddVideoInput}>
                    +
                  </button>
                </div>
                {/* <p className="text-center m-0">OR</p>
                <Input
                  type="text"
                  className="custom-input"
                  placeholder="Enter URL..."
                  value={fileUrl}
                  onChange={handleFileUrl}
                /> */}
              {/* </>} */}
              </>
            )}
              {selectedNode.type === "conditionnode" && (
                <>
                <label className="node-label">Label:</label>
                    <Input
                    type="text"
                    className="custom-input"
                    value={nodeLabel}
                    onChange={e => handleInputChange(e.target.value)}
                  />
                  <label className="node-label">Text:</label>
                  <Input
                    type="text"
                    className="custom-input"
                    value={nodeText}
                    onChange={handleLabelInputChange}
                  />
                  <label className="node-label">Select Option:</label>
                  <select
                    className="custom-input"
                    value={nodeType}
                    onChange={handleTypeInputChange}
                  >
                    <option value="">Select Option</option>
                    <option value="sbtn">Submit Button</option>
                    <option value="rbtn">Radio Button</option>
                  </select>
                </>
              )}

              <label className="node-label">Add Button:</label>
              {conditionValues.length > 0 && conditionValues.map((item) => (
                <InputGroup key={item.id} className="add-group">
                  <Input
                    type="text"
                    className="custom-input"
                    value={item.value}
                    onChange={(e) => handleAddValueInputChange(e.target.value, item.id)}
                  />
                  <Button
                    className="delete-btn"
                    onClick={() => removeConditionValue(item.id)}
                    color="danger"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </InputGroup>
              ))}
              {selectedNode.type === "conditionnode" &&<> <label className="node-label">Add Values:</label>
              {conditionValues.length > 0 && conditionValues.map((item) => (
                <InputGroup key={item.id} className="add-group">
                  <Input
                    type="text"
                    className="custom-input"
                    value={item.value}
                    onChange={(e) => handleAddValueInputChange(e.target.value, item.id)}
                  />
                  <Button
                    className="delete-btn"
                    onClick={() => removeConditionValue(item.id)}
                    color="danger"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </InputGroup>
              ))} </>}
              {selectedNode.type === "conditionnode" && (
                <button
                  className="add-button"
                  onClick={addConditionValue}
                >
                <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
              {selectedNode.type === "textnode" && (
                <button
                className="add-button"
                onClick={addConditionValue}
                style={{
                  backgroundColor: isButtonDisabled ? '#ccc' : '#007bff',
                  color: isButtonDisabled ? '#666' : '#fff',
                  cursor: isButtonDisabled ? 'not-allowed' : 'pointer'
                }}
                disabled={isButtonDisabled}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
              )}
              
            </>
          ) : null}
          <button
            className="go-back-btn"
            onClick={() => setSelectedElements([])}
          >
            Go Back
          </button>
        </div>
      ) : (
        <>
          <h3 className="update-label">Nodes Panel</h3>
          <div
            className="sidebar-btn"
            onDragStart={handleDragStart}
            draggable={!isNodeDisabled}
            style={{
              cursor: isNodeDisabled ? 'not-allowed' : 'grab',
              opacity: isNodeDisabled ? 0.5 : 1,
          }}
          >
            Start
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "textnode")}
            draggable
          >
            Message Node
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "conditionnode")}
            draggable
          >
            Condition Node
          </div>
          <div
            className="sidebar-btn"
            onDragStart={(event) => onDragStart(event, "endnode")}
            draggable
          >
            End
          </div>
        </>
      )}
    </aside>
  );
}