export function ordersListDTO(data) {
  let orders = [];

  if (data?.length) {
    orders = data.map((order, index) => ({
      id: index + 1 || 0,
      orderId: order?.orderId || 0,
      couponId: order?.couponId || null,
      tableNumber: order?.tableNumber || null,
      status: parseInt(order?.status),
      paymentStatus: order?.paymentStatus,
      rating: order?.rating || 0,
      currency: order?.currency || null,
      subTotal: order?.subTotal || null,
      createdAt: order?.createdAt || null,
      total: order?.total || null,
      transactionId: order?.transactionId || null,
      vat: order?.vat || null,
      feedbackMessage: order?.feedbackMessage || null,
      totalDiscountValue: order?.totalDiscountValue || 0,
      payingCustomer: order?.payingCustomer || [],
      discount: order?.discount?.length !== 0 ? order.discount.map(discount => ({
        name: discount?.name || null,
        type: discount?.type || null,
        discountValue: discount?.discountValue || null,
      })) : [],
      items: order?.orderItems?.length ? order?.orderItems?.map(item => ({
        productRetailerId: item?.productRetailerId || null,
        name: item?.name || null,
        quantity: item?.quantity || null,
        price: item?.price || 0
      })) : [],
      customers: order?.customers?.length ? order?.customers?.map(customer => ({
        name: customer?.name || null,
        phone: customer?.phone || null
      })) : [],
      transactions: order?.transactions?.length ? order?.transactions?.map(transaction => ({
        amount: transaction?.amount || null,
        status: transaction?.status || null,
        phone: transaction?.phone || null,
      })) : [],
    }))
  }

  return orders;
}