import { Badge, Card, CardHeader, Media, Table, Row, Button, Input, } from "reactstrap";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCMS } from "../../utils/middleware/cms";
import { deleteCMS } from "../../utils/middleware/cms";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import Editcms from "../../components/Editcms";
import Addcms from "../../components/Addcms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPen, faRightToBracket, faTrash, } from "@fortawesome/free-solid-svg-icons";
import { RoutesActions, serviceThrough } from "../../../../constants/General";
import NoDataLabel from "../../../../components/NoDataLabel";
import { dispatch, dispatchNoPayload } from "../../../../utils/store";
import { Actions } from "../../../../redux/actions";
import CustomPagination from "../../../../components/Pagination";
import { handleSortColumn, sortIcon, superAdminLogin } from "../../../../services/middleware";
import SearchInput from "../../../../components/SearchInput";


export default function Cms() {
    const navigate = useNavigate();

    // Store
    const { cms, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.superAdminCMS)
    const { pageSize } = useSelector(state => state.user)

    // State
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showStatusModal, setStatusModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedCms, setSelectedCms] = useState(null)

    // // Mount
    useEffect(() => {
      getCMS(search, activeSort, sortOrder, page)
      let pageCount = Math.ceil(totalCount / pageSize)
      if (totalCount && page > pageCount) {
        dispatch(Actions.SuperAdminCMS.SetPage, pageCount)
      }
    }, [])
    
    // Unmount
    useEffect(() => () => dispatchNoPayload(Actions.SuperAdminCMS.Reset), [])

    return (
      <>
        <div>
          <div className="custom-container">
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0 space-between-div table-header-div">
                    <h3 className="mb-0">CMS</h3>
                    <div className="right-div-wrap">
                      <Button color='primary' onClick={() => setShowAddModal(true)}>
                        Add
                      </Button>
                    </div>
                  </CardHeader>
                  {cms?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope='col' className='serial-number cursor-pointer' >No.</th>
                        {/* <th scope='col'>content</th>
                        <th scope='col'>contentstyle</th>
                        <th scope='col'>contentmeta</th> */}
                        <th scope='col'>Title</th>
                        <th scope='col'>Category</th>
                        <th scope='col' className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cms.map((cms, index) => {
                        return <tr key={index}>
                          <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td>
                          {/* <td>{`${cms.content.slice(0, 30)}...`}</td>
                          <td>{`${cms.contentstyle.slice(0, 30)}...`}</td>
                          <td>{`${cms.contentmeta.slice(0, 30)}...`}</td> */}
                          <td>{cms.title}</td>
                          <td>{cms.category}</td>
                          <td><Button color="success" className="action-icon-btn" title="Edit" onClick={() => {
                              setShowEditModal(true)
                              setSelectedCms(cms)
                            }}>
                              <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button color="danger" className="action-icon-btn" title="Delete" onClick={() => {
                              setShowDeleteModal(true)
                              setSelectedCms(cms)
                            }}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button></td>

                        </tr>
                      })}
                    </tbody>
                  </Table> : <NoDataLabel />}
                  <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.SuperAdminCMS.SetPage, selected + 1)} />
                </Card>
              </div>
            </Row>
          </div>
        </div>

        {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={() => setShowDeleteModal(false)} handleSuccess={() => deleteCMS(selectedCms?.id, search, activeSort, sortOrder, page, setShowDeleteModal)} />}
        {showEditModal && <Editcms cms={selectedCms} open={showEditModal} handleCloseModal={() => setShowEditModal(false)} />}
        {showAddModal && <Addcms open={showAddModal} handleCloseModal={() => setShowAddModal(false)} />}
      </>
    );
}
