import { SUBSCRIPTIONS_API, SUBSCRIPTION_PAYMENT_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getLocalStorageUserData, removeSessionData } from "../../../../utils/session"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { subscriptionsListDTO } from "../../../Admin/utils/dtos/subscriptions"
import { getRestaurant } from "./restaurant"

export const getSubscriptions = (search, page = 1, status) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  const params = {
    ...(!!status ? { status } : {}),
    ...(!!search ? { search } : {}),
    ...(!!page ? { page, 'size': 1000 } : {}),
  }

  API(API_REQUEST.get, SUBSCRIPTIONS_API, { params })
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.SuperAdminSubscriptions.SetSubscriptions, subscriptionsListDTO(res?.data?.data?.rows))
      dispatch(Actions.SuperAdminSubscriptions.SetTotalCount, res?.data?.data?.count || 0)
      dispatch(Actions.SuperAdmin.SetLoading, false)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const addSubscription = (subscriptionId) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${SUBSCRIPTION_PAYMENT_API}/subscribe`, { subscriptionId })
    .then(res => {
      handleSuccess(res)
      if (res?.data?.data) {
        window.open(res.data.data, '_blank');
      } else {
        removeSessionData()
      }
      getRestaurant(getLocalStorageUserData()?.restaurantId)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}