import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { faEye, faImage, faPen, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardHeader, Media, Table, Row, Button, UncontrolledAlert, } from "reactstrap";
import ConfirmationModal from '../../../../components/ConfirmationModal';
import NoDataLabel from '../../../../components/NoDataLabel';
import AddOfferModal from '../../components/AddOfferModal';
import EditOfferModal from '../../components/EditOfferModal';
import { addProductOffer, filteredData, getOfferProducts, handleSort, selectedProductPayload } from '../../utils/middleware/offers';
import { getCategoriesForProducts } from '../../utils/middleware/products';
import { ProductFetchDelayMessage, RoutesActions } from '../../../../constants/General';
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import CustomPagination from '../../../../components/Pagination';
import ViewProductModal from '../../components/ViewProductModal';
import SearchInput from '../../../../components/SearchInput';
import { sortIcon } from '../../../../services/middleware';

export default function Offers() {
  // Store
  const { offers, offersWithPagination, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminOffers)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [viewProductModal, setViewProductModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  // Noubt
  useEffect(() => {
    getOfferProducts(search, activeSort, sortOrder, page)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Update
  useEffect(() => {
    filteredData(offers, search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminOffers.SetPage, pageCount)
    }
  }, [offers, search, activeSort, sortOrder, page, pageSize, totalCount])

  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSelectedProduct(null)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedProduct(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedProduct(null)
  }

  const handleClosViewModal = () => {
    setViewProductModal(false)
    setSelectedProduct(null)
  }

  // Unmout
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminOffers.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <UncontrolledAlert color="light">
          {ProductFetchDelayMessage}
        </UncontrolledAlert >
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">

                <h3 className="mb-0">Offers</h3>
                <div className="right-div-wrap">
                  <SearchInput action={RoutesActions.offers} />
                  <Button color='primary' onClick={() => {
                    getCategoriesForProducts()
                    setShowAddModal(true)
                  }}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col" className='serial-number cursor-pointer'>No.</th> */}
                    <th scope="col" className={`${activeSort === 'name' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('name', activeSort, sortOrder)} >Name <FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'price' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('price', activeSort, sortOrder)}>Price <FontAwesomeIcon icon={sortIcon(activeSort, 'price', sortOrder)} /></th>
                    <th scope="col" >Discount</th>
                    <th scope="col" className={`${activeSort === 'salePrice' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('salePrice', activeSort, sortOrder)}>Final Sale Price <FontAwesomeIcon icon={sortIcon(activeSort, 'salePrice', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'saleStart' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('saleStart', activeSort, sortOrder)}>Start Date <FontAwesomeIcon icon={sortIcon(activeSort, 'saleStart', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'saleEnd' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('saleEnd', activeSort, sortOrder)}>End Date <FontAwesomeIcon icon={sortIcon(activeSort, 'saleEnd', sortOrder)} /></th>
                    <th scope="col" className={`${activeSort === 'description' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSort('description', activeSort, sortOrder)}>Description <FontAwesomeIcon icon={sortIcon(activeSort, 'description', sortOrder)} /></th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {offersWithPagination.length !== 0 ? offersWithPagination.map((product, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle cursor-pointer mr-3">
                            {product.imageLink ? <img alt="Logo" className="avatar rounded-circle" src={product.imageLink}
                              onClick={() => {
                                dispatch(Actions.User.SetImagePreview, true)
                                dispatch(Actions.User.SetImageLink, product.imageLink)
                              }} /> :
                              <FontAwesomeIcon icon={faImage} />}
                          </span>
                          <Media>
                            <span className="mb-0 text-sm">
                              {product.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{product.price && `${settings?.superAdminSetting?.currencySign}${product.price}`}</td>
                      <td>{product?.discountValue ? product?.discountType === 'percentage' ? `${product?.discountValue}%` : `${settings?.superAdminSetting?.currencySign}${product?.discountValue}` : ''}</td>
                      <td>{product.salePrice && `${settings?.superAdminSetting?.currencySign}${product.salePrice}`}</td>
                      <td>{product.saleStart ? moment(product.saleStart).format('DD-MM-YYYY hh:mm A') : ''}</td>
                      <td>{product.saleEnd ? moment(product.saleEnd).format('DD-MM-YYYY hh:mm A') : ''}</td>
                      <td className='ellipsis-column' title={product.description}>{product.description}</td>
                      <td className="text-right">
                        <Button color="warning" className='action-icon-btn' title='View' onClick={() => {
                          setSelectedProduct(product)
                          setViewProductModal(true)
                        }}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                          setSelectedProduct(product)
                          setShowEditModal(true)
                        }}>
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                          setSelectedProduct(product)
                          setShowDeleteModal(true)
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  }) : <tr><td colSpan={9} ><NoDataLabel /></td></tr>}
                </tbody>
              </Table>
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminOffers.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => addProductOffer(selectedProductPayload(selectedProduct), search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showAddModal && <AddOfferModal open={showAddModal} handleCloseModal={handleCloseAddModal} />}
      {showEditModal && <EditOfferModal open={showEditModal} product={selectedProduct} handleCloseModal={handleCloseEditModal} />}
      {viewProductModal && <ViewProductModal open={viewProductModal} product={selectedProduct} handleCloseModal={handleClosViewModal} />}
    </div >
  )
}
