import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Panel,
  useReactFlow,
  MiniMap,
  Controls,
  Background,
} from "reactflow";
import "reactflow/dist/base.css";
import Sidebar from "./components/Sidebar.jsx";
import TextNode from "./components/TextNode.jsx";
import ConditionNode from "./components/ConditionNode.jsx";
import StartNode from "./components/StartNode.jsx";
import EndNode from "./components/EndNode.jsx";
import { v4 as uuidv4 } from 'uuid';
import { getBezierPath } from 'reactflow';
import "./index.css";
import { addNodeChatAutomation, addEdgeChatAutomation, getChatAutomation, updateNodeChatAutomation, updateEdgeChatAutomation, deleteNodeChatAutomation, deleteEdgeChatAutomation } from '../../utils/middleware/chatAtomation.js';
import { handleError, handleSuccess } from "../../../../utils/toast"
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';
import { uploadImage } from "../../../../services/middleware.js";
import { ImageType } from '../../../../constants/General';
import { toast } from "react-toastify";
// Key for local storage
const flowKey = "flow-key";


// Function for generating unique IDs for nodes
const getId = () => uuidv4();

// Initial nodes setup
const initialNodes = [];
const initialNodess = [
  {
    id: getId(),
    type: "textnode",
    data: { text: "Text Node", type: "", value: "", pdf: [], file: [], video:[] },
    position: { x: 100, y: 5 },
  },
  {
    id: getId(),
    type: "conditionnode",
    data: { label: '', text: "", type: "", value: [] },
    position: { x: 300, y: 5 },
  },
];

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  onDelete,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={40}  // Adjusted width
        height={40} // Adjusted height
        x={labelX - 20} // Center the button horizontally
        y={labelY - 20} // Center the button vertically
        className="edgebutton-foreignobject"
      >
        <div style={{ textAlign: 'center' }}>  {/* Center the button text */}
          <button
            onClick={() => onDelete(id)}
            style={{
              background: 'white',
              border: '1px solid black',
              borderRadius: '50%', // Make the button circular
              padding: '3px',
              fontSize: '10px',
              width: '20px',
              height: '20px',
              marginTop: '10px'
            }}
          >
            X
          </button>
        </div>
      </foreignObject>
    </>
  );
};
const FlowWithProvider = () => {
  // States and hooks setup
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedElements, setSelectedElements] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [uploadImageData, setUploadImageData] = useState([]);
  const [uploadVideoData, setUploadVideoData] = useState([]);
  const [nodeText, setNodeText] = useState("");
  const [nodeValue, setNodeValue] = useState({});
  const [nodeType, setNodeType] = useState("");
  const [nodeLabel, setNodeLabel] = useState("");
  const [updateStatus, setUpdateStatus] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedPDFFile, setSelectedPDFFile] = useState([]);
  const [selectedVideoFile, setSelectedVideoFile] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const restaurant = JSON.parse(localStorage.getItem('userData'));
  const userRestaurantId = restaurant.restaurantId


  // Define custom node types
  // const nodeTypes = useMemo(
  //   () => ({
  //     textnode: TextNode,
  //     conditionnode: ConditionNode,
  //     startnode: StartNode,
  //     endnode: EndNode
  //   }),
  //   []
  // );

  useEffect(() => {
    onRestore();
  },[])

  // useEffect(() => {
  //   const selectedNodeId = selectedElements[0]?.id;
  //   // nodes.map((node) => {
  //   //   if (node.id === selectedNodeId) {
  //   //     if ( node.type === "textnode") {
  //   //       if(node.data.pdf && node.data.pdf.length > 0){
  //   //         setUploadData(node.data.pdf)  
  //   //       }  
  //   //       if(node.data.file && node.data.file.length > 0){
  //   //         setUploadImageData(node.data.file)
  //   //       }        
  //   //       if(node.data.video && node.data.video.length > 0){
  //   //         setUploadVideoData(node.data.video)
  //   //       }        
  //   //     }
  //   //   }      
  //   // });
  // }, [nodes]);

  const handleDeleteEdge = useCallback(
    (edgeId) => {
      console.log('Attempting to delete edge:', edgeId);

      setEdges((eds) => {
        const newEdges = eds.filter((edge) => edge.id !== edgeId);
        const filteredOutEdges = eds.filter((edge) => edge.id === edgeId);
        filteredOutEdges.forEach(element => {
          if(element.createdAt){               
            deleteEdgeChatAutomation(element.id)
          }
        });
        console.log('Edges after deletion:', newEdges);
        return newEdges;
      });
    },
    [setEdges]
  );



  const edgeTypes = useMemo(
    () => ({
      custom: (props) => <CustomEdge {...props} onDelete={handleDeleteEdge} />,
    }),
    [handleDeleteEdge]
  );



  const handleDeleteNode = useCallback(
    (nodeId) => {
      // Clear sidebar state if the selected node is deleted
      if (selectedElements[0]?.id === nodeId) {
        console.log('Attempting to delete node:', nodeId);
        setNodeText("");
        setNodeValue({});
        setNodeType("");
        setNodeLabel("");
        setSelectedPDFFile([]);
        setSelectedVideoFile([]);
        setSelectedFile([]);
        setSelectedElements([]);
      }

      // Function to check if a parent node is connected to any child nodes
      const isParentConnectedToChild = (parentId, edges) => {
        // Simply check if there's any edge with the source as parentId
        return edges.some(edge => edge.source === parentId);
      };

      setEdges((eds) => {
        if(!isParentConnectedToChild(nodeId,eds)){
          console.log('nodeId,eds',nodeId,'====>',eds)
          const newEdges = eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId);
          const filteredOutEdges = eds.filter((edge) => edge.source === nodeId || edge.target === nodeId);
          setNodes((nds) => {
            const newNodes = nds.filter((node) => node.id !== nodeId);
            const filterOutNodes = nds.filter((node) => node.id === nodeId);                        
            if(filterOutNodes && filterOutNodes[0].createdAt){
              console.log('nodeId,eds====>',filterOutNodes)                        
              deleteNodeChatAutomation(filterOutNodes[0].id);
            }
            return newNodes;
          });
          filteredOutEdges.forEach(element => {
            if(element.createdAt){
              deleteEdgeChatAutomation(element.id)
            }
          });
          return newEdges;
        }else{
          alert("Please remove the child node first", true);

          return eds;
        }
      });
    },
    [selectedElements, setNodes, setEdges]
  );



  const nodeTypes = useMemo(
    () => ({
      textnode: (props) => <TextNode {...props} id={props.id} onDelete={handleDeleteNode}  />,
      conditionnode: (props) => <ConditionNode {...props} id={props.id} onDelete={handleDeleteNode} />,
      startnode: (props) => <StartNode {...props} id={props.id} onDelete={handleDeleteNode} />,
      endnode: (props) => <EndNode {...props} id={props.id} onDelete={handleDeleteNode} />
    }),
    [handleDeleteNode]
  );


  useEffect(() => {
    if (selectedElements.length > 0) {
      const selectedNodeId = selectedElements[0]?.id;

      const nodeExists = nodes.some((node) => node.id === selectedNodeId);
      if (!nodeExists) {
        console.log(nodeExists, selectedElements[0])
        setSelectedElements([]);
        return; // If the node doesn't exist, do nothing
      }

      // Only update the selected node
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          let updatedData = { ...node.data };

          // if (node.type === "textnode") {
          //   updatedData = {
          //     ...updatedData,
          //     text: nodeText,
          //     type: nodeType,
          //     value: nodeValue,
          //   };
          // } else
          if (node.type === "conditionnode" || node.type === "textnode") {
            updatedData = {
              ...updatedData,
              text: nodeText,
              type: nodeType,
              label: nodeLabel,
              value: nodeValue,
              pdf: selectedPDFFile.length > 0 ? selectedPDFFile : uploadData,
              file: selectedFile.length > 0 ? selectedFile : uploadImageData,
              video: selectedVideoFile.length > 0 ? selectedVideoFile : uploadVideoData,
            };
          }

          return { ...node, data: updatedData };
        }
        return node;
      });
      console.log(updatedNodes)
      setNodes(updatedNodes);
    }
  }, [nodeText, nodeType, nodeValue, nodeLabel, selectedElements, setNodes, selectedFile, fileUrl, selectedPDFFile, selectedVideoFile]);


  const onNodeClick = useCallback(
    (event, node) => {      
      console.log(node,"onclick=======================")    
      setSelectedElements([node]);  
      // Reset the values in the sidebar for the new selected node
      if (node.type === "textnode" || node.type === "conditionnode"  || node.type === "startnode" || node.type === "endnode") {
        setNodeText(node.data.text || '');
        setNodeType(node.data.type || '');
        setNodeLabel(node.data.label || '');        
        setNodeValue(node.data.value || (node.type === "conditionnode" || node.type === "textnode"? [] : ''));
        setSelectedFile(node.data.file || (node.type === "textnode" ? [] : ''));
        setSelectedPDFFile(node.data.pdf || (node.type === "textnode" ? [] : ''));
        setSelectedVideoFile(node.data.video || (node.type === "textnode" ? [] : ''));
      }

      setNodes((nodes) =>
        nodes.map((n) => ({
          ...n,
          selected: n.id === node.id,
        }))
      );
    },
    [nodes, setNodes]
  );

  // Setup viewport
  const { setViewport } = useReactFlow();

  // Check for empty target handles
  const checkEmptyTargetHandles = () => {
    let emptyTargetHandles = 0;
    edges.forEach((edge) => {
      if (!edge.targetHandle) {
        emptyTargetHandles++;
      }
    });
    return emptyTargetHandles;
  };

  // Check if any node is unconnected
  const isNodeUnconnected = useCallback(() => {
    let unconnectedNodes = nodes.filter(
      (node) =>
        !edges.find(
          (edge) => edge.source === node.id || edge.target === node.id
        )
    );
    return unconnectedNodes.length > 0;
  }, [nodes, edges]);

  // Save flow to local storage
  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      const emptyTargetHandles = checkEmptyTargetHandles();
      if (nodes.length > 1 && (emptyTargetHandles > 1 || isNodeUnconnected())) {
        alert(
          "Error: More than one node has an empty target handle or there are unconnected nodes.", true
        );
      } else {
        const flow = reactFlowInstance.toObject();        
        // if(updateStatus){          
        //   localStorage.setItem(flowKey, JSON.stringify(flow));
        //   addNodeChatAutomation(flow.nodes)  
        //   addEdgeChatAutomation(flow.edges)  
        //   alert("Save successful!",true); // Provide feedback when save is successful
        // }else{          
        //   updateNodeChatAutomation(flow.nodes)
        //   updateEdgeChatAutomation(flow.edges)
        // }                
        // flow.nodes.map((node) => {                    
        //   if(node.nodeId){
        //     updateNodeChatAutomation(node)
        //   }else{                       
        //     addNodeChatAutomation(node)  
        //   }
        // })
        const promisesNode = flow.nodes.map((node) => {                    
          if (node.nodeId) {
            return updateNodeChatAutomation(node);
          } else {                       
            return addNodeChatAutomation(node);  
          }
        });
        Promise.all(promisesNode)
        .then((res) => {
          // Display success message only once
          handleSuccess(res);          
          dispatch(Actions.RestaurantAdmin.SetLoading, false);
        })
        .catch((error) => {
          handleError(error);
          dispatch(Actions.RestaurantAdmin.SetLoading, false);
        });
        const promisesEdge = flow.edges.map((edge) => {
          if(edge.edgeId){
            updateEdgeChatAutomation(edge)
          }else{            
            addEdgeChatAutomation(edge)  
          }
        })

        Promise.all(promisesEdge)
        .then((res) => {
          // Display success message only once
          handleSuccess(res);          
          dispatch(Actions.RestaurantAdmin.SetLoading, false);
        })
        .catch((error) => {
          handleError(error);
          dispatch(Actions.RestaurantAdmin.SetLoading, false);
        });

      }
    }
  }, [reactFlowInstance, nodes, isNodeUnconnected]);


  // Restore flow from local storage
  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey));
      const restaurant = JSON.parse(localStorage.getItem('userData'));
      const restaurantId = restaurant.restaurantId  
      getChatAutomation(restaurantId).then(res => {
        if(res[0].data.data.length > 0 ) {
          setUpdateStatus(false);
        }        
        const dataNode = res[0].data.data.map(e => {          
          if(e.type == "textnode"){
            e.data.file = e.data.file;
            e.data.pdf = e.data.pdf;
          }
          let temp = e.id;
          e.id = e.nodeId
          e.nodeId = temp;
          return e;
        });
        const dataEdge = res[1].data.data.map(e => {
          let temp = e.id;
          e.id = e.edgeId
          e.edgeId = temp;
          return e;
        });

        // if (flow) {
        //   const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        //   setNodes(flow.nodes || []);
        //   setEdges(flow.edges || []);
        //   setViewport({ x, y, zoom });
        // }
        if (res) {
          const x = 0;
          const y = 0;
          const zoom = 1;
          setNodes(dataNode || []);
          setEdges(dataEdge || []);
          setViewport({ x, y, zoom });
        }
      }).catch(err => {
        console.log(err);
      })


    };
    restoreFlow();
  }, [setNodes, setViewport, setEdges]);

  // Handle edge connection
  const onConnect = useCallback(
    (params) => {
      const { source, sourceHandle, target, targetHandle } = params;

      // Check if the source handle already has an edge connected
      const isSourceHandleOccupied = edges.some(
        (edge) => edge.source === source && edge.sourceHandle === sourceHandle
      );

      // Get the node corresponding to the source ID
      const sourceNode = nodes.find((node) => node.id === source);

      // Limit outgoing edges from the "startNode" type
      const maxOutgoingEdgesFromStart = 1;
      const maxOutgoingEdgesFromCondition = 1;
      const existingOutgoingEdgesFromStart = edges.filter(
        (edge) => edge.source === source
      );

      // If the source handle is already occupied, prevent the connection
      if (isSourceHandleOccupied) {
        alert('Source handle already occupied.', true);
        return;
      }      
      // Prevent multiple connections from a node of type "startnode"
      if (sourceNode && sourceNode.type === 'startnode' && existingOutgoingEdgesFromStart.length >= maxOutgoingEdgesFromStart) {
        alert('Only one connection is allowed from the Start node.', true);
        return;
      }
      // else if(sourceNode && sourceNode.type === 'textnode' && existingOutgoingEdgesFromStart.length >= maxOutgoingEdgesFromStart){
      //   alert('Only one connection is allowed from the Massage node.');
      //   return;
      // }

      // Update nodes to include source and target information
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === source) {
            return {
              ...node,
              data: {
                ...node.data,
                source: target, // Add target node ID as source's connected target
              },
            };
          }
          if (node.id === target) {
            return {
              ...node,
              data: {
                ...node.data,
                target: source, // Add source node ID as target's connected source
              },
            };
          }
          return node;
        })
      );

      // Add the new edge to the edges state
      setEdges((eds) => addEdge({ ...params, type: 'custom', restaurantId: userRestaurantId }, eds))
    },
    [edges, nodes, setEdges, setNodes]
  );



  // Enable drop effect on drag over
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handle drop event to add a new node
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      if (typeof type === "undefined" || !type) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: {
          label: `${
            type === "textnode"
              ? ""
              : type === "conditionnode"
              ? ""
              : ""
          }`,
          type: "",
          value: type === "conditionnode" ? [] : "",
          pdf: type === "textnode" ? [] : "",
          file: type === "textnode" ? [] : "",
          video: type === "textnode" ? [] : "",

        },
        restaurantId: userRestaurantId
      };
      // const addnode = nodes;
      // addnode.push(newNode)
      // setNodes(newNode);      
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes]
  );

  const rfStyle = {
    backgroundColor: "#ffffff",
  };
  console.log("edges", edges)
  return (
    <div className="flow-container">
      <div className="grow-container" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          style={rfStyle}
          onNodeClick={onNodeClick}
          onPaneClick={() => {
            setSelectedElements([]); // Reset selected elements when clicking on pane
            setNodes((nodes) =>
              nodes.map((n) => ({
                ...n,
                selected: false, // Reset selected state of nodes when clicking on pane
              }))
            );
          }}
          fitView
        >
          <Background variant="dots" gap={12} size={1} />
          <Controls />
          {/* <MiniMap zoomable pannable /> */}
          <Panel>
          {/* <button
              className="panel-button"
              onClick={onSave}
            >
              Back to Wepos
            </button> */}
            <button
              className="panel-button"
              onClick={onSave}
            >
              save flow
            </button>
            {/* <button
              className="panel-button"
              onClick={onRestore}
            >
              restore flow
            </button> */}
          </Panel>
        </ReactFlow>
      </div>

      <Sidebar
        nodes={nodes}
        selectedNode={selectedElements[0]}
        setSelectedElements={setSelectedElements}
        nodeText={nodeText}
        nodeValue={nodeValue}
        nodeType={nodeType}
        nodeLabel= {nodeLabel}
        setNodeLabel= {setNodeLabel}
        setNodeText={setNodeText}
        setNodeType={setNodeType}
        setNodeValue={setNodeValue}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        selectedPDFFile={selectedPDFFile}
        selectedVideoFile={selectedVideoFile}
        setSelectedVideoFile={setSelectedVideoFile}
        setSelectedPDFFile={setSelectedPDFFile}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
      />
    </div>
  );
};

// Wrap App with ReactFlowProvider
function ChatsFlow() {
  return (
    <ReactFlowProvider>
      <FlowWithProvider />
    </ReactFlowProvider>
  );
}

export default ChatsFlow;
