import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import './comingSoon.css'; // Import the CSS file

const ComingSoon = () => {
  return (
    <div className="chatbot-flow">
      <FontAwesomeIcon icon={faClock} className="icon" spin />
      <div className="text">Coming Soon.</div>
    </div>
  );
};

export default ComingSoon;
