import { useFormik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Form, Modal, Row, Col, Label } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { addRestaurant } from '../../utils/middleware/restaurant';
import { addRestaurantInputFields, Country, CountryCode, ImageType, multiSelectDropdownStyle, selectDropdownStyle, serviceThrough, UK_States } from '../../../../constants/General';
import InputField from '../../../../components/InputField';
import { addRestaurantValidationSchema, } from '../../../../constants/Schemas';
import { dispatch } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { handleError } from '../../../../utils/toast';
import { generateDropdownOptionForStates, generateDropdownOptionFromObject, removeEmptyKeysFromObject, trimObjectValues, uploadImage } from '../../../../services/middleware';
import UploadLogo from '../../../../components/UpdateLogo';

export default function AddRestaurant(props) {
  // Props
  const { open, handleCloseModal } = props

  // Store
  const { search, activeSort, sortOrder, page } = useSelector(state => state.superAdminRestaurants)

  // State
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      addressLine1: '',
      addressLine2: '',
      zipcode: '',
      state: '',
      logo: '',
      tagLine: '',
      phone: '',
      service: '',
      tableCount: '',
      country: ''
    },
    validationSchema: addRestaurantValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      trimObjectValues(values, 'logo')
      const payload = {
        name: values.name,
        tableCount: parseInt(values.tableCount),
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        zipCode: values.zipcode,
        state: values.state?.value,
        country: values.country?.value,
        tagLine: values.tagLine,
        phone: `${CountryCode[values.country?.value]}${values.phone}`,
        email: values.email,
        password: values.password,
        serviceThrough: values.service.map(datum => datum.value),
      }
      if (values.logo) {
        dispatch(Actions.SuperAdmin.SetLoading, true)
        const logoResponse = await uploadImage(values.logo, ImageType.LOGO).catch(err => {
          dispatch(Actions.SuperAdmin.SetLoading, false)
          handleError(err)
        })
        if (logoResponse) {
          payload.logo = logoResponse?.fileLink
        }
      }
      const filterData = removeEmptyKeysFromObject(payload)
      addRestaurant(filterData, search, activeSort, sortOrder, page, resetForm, handleCloseModal)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Add Restaurant
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <UploadLogo formik={formik} fieldName="logo" />
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Name" fieldName="name" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField required placeholder="Tagline" fieldName="tagLine" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label className='inputfield required-field'>
                    Service
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" isMulti value={formik.values.service} options={generateDropdownOptionFromObject(serviceThrough)} isSearchable={false} styles={multiSelectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("service", selectedOption);
                      }}
                    />
                    {formik.touched?.service && formik.errors.service && <span className="error-message input-group-alternative">{formik.errors.service}</span>}
                  </section>
                </Col>
                <Col md={6}>
                  <InputField required placeholder="Table count" inputType='number' fieldName="tableCount" formik={formik} />
                </Col>
              </Row>
              {addRestaurantInputFields.map((row, index) => {
                return <Row key={index}>
                  <Col md={6}>
                    <InputField required passwordField placeholder={row.placeholderLeft} dropdownChild={generateDropdownOptionFromObject(serviceThrough)} fieldName={row.fieldNameLeft} inputType={row?.passwordIconLeft ? showNewPassword ? "text" : "password" : row?.inputTypeLeft} passwordIcon={row?.passwordIconLeft} formik={formik} showPassword={showNewPassword} setShowPassword={setShowNewPassword} />
                  </Col>
                  <Col md={6}>
                    <InputField required={row?.requiredRight} passwordField placeholder={row.placeholderRight} fieldName={row.fieldNameRight} inputType={row?.passwordIconRight ? showConfirmPassword ? "text" : "password" : row?.inputTypeRight} passwordIcon={row?.passwordIconRight} formik={formik} showPassword={showConfirmPassword} setShowPassword={setShowConfirmPassword} />
                  </Col>
                </Row>
              })}
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    State
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={generateDropdownOptionForStates(UK_States)} value={formik.values.state} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("state", selectedOption);
                      }}
                    />
                    {formik.touched?.state && formik.errors.state && <span className="error-message input-group-alternative">{formik.errors.state}</span>}
                  </section>
                </Col>
                <Col md={4}>
                  <InputField required placeholder="Zipcode" fieldName="zipcode" formik={formik} />
                </Col>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Country
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={Country} value={formik.values.country} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("country", selectedOption);
                      }}
                    />
                    {formik.touched?.country && formik.errors.country && <span className="error-message input-group-alternative">{formik.errors.country}</span>}
                  </section>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}
