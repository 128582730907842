import { MESSAGES_API, SETTINGS_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { restaurantSettingsDTO } from "../dtos/settings"

export const getSettings = () => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.get, `${SETTINGS_API}`)
    .then(res => {
      dispatch(Actions.SuperAdmin.SetSettings, restaurantSettingsDTO(res?.data?.data))
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const getMessages = () => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.get, `${MESSAGES_API}`)
    .then(res => {
      dispatch(Actions.SuperAdmin.SetSettingMessages, res?.data?.data)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const editMessages = (data, resetForm, setEditable) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.put, `${MESSAGES_API}`, data)
    .then(res => {
      handleSuccess(res)
      getMessages()
      setEditable(false)
      resetForm({ values: '' })
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}



export const editSettings = (data, resetForm, setEditable) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.put, `${SETTINGS_API}`, data)
    .then(res => {
      handleSuccess(res)
      getSettings()
      setEditable(false)
      resetForm({ values: '' })
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const setInitialValues = (values) => {
  return {
    currency: values?.currency,
    currencySign: values?.currencySign,
    vat: values?.vat,
  }
}

export const setInitialMessages = (values) => {
  const messagesObject = values?.length ? values.reduce((acc, item) => {
    acc[item.key] = item.message_en;
    return acc;
  }, {}) : {}
  return messagesObject
}