import React from 'react'
import { CardFooter } from 'reactstrap'
import Select from 'react-select';
import { PageSizes } from '../../constants/General'
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';
import { setSelectedDropdownForSameValue } from '../../services/middleware';
import { selectDropdownStyle } from '../../constants/General';

// import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CustomPagination(props) {
  const { totalCount, page, handlePageClick } = props

  // Store
  const { pageSize } = useSelector(state => state.user)

  return (
    <CardFooter className="space-between-div">
      {totalCount !== 0 && <Select styles={selectDropdownStyle} menuPlacement="auto" className='page-size-select' value={setSelectedDropdownForSameValue(pageSize)} options={PageSizes} isSearchable={false}
        onChange={selectedOption => {
          dispatch(Actions.User.SetPageSize, selectedOption?.value);
          dispatch(Actions.RestaurantAdminTables.SetPage, 1);
          dispatch(Actions.RestaurantAdminCategories.SetPage, 1);
          dispatch(Actions.RestaurantAdminCustomers.SetPage, 1);
          dispatch(Actions.RestaurantAdminTags.SetPage, 1);
          dispatch(Actions.RestaurantAdminProducts.SetPage, 1);
          dispatch(Actions.RestaurantAdminOffers.SetPage, 1);
          dispatch(Actions.RestaurantAdminCoupons.SetPage, 1);
          dispatch(Actions.RestaurantAdminMarketings.SetPage, 1);
          dispatch(Actions.RestaurantAdminOrders.SetPage, 1);
          dispatch(Actions.RestaurantAdminFeedbacks.SetPage, 1);
          dispatch(Actions.RestaurantAdminReports.SetPage, 1);
          dispatch(Actions.RestaurantAdminReservations.SetPage, 1);
          dispatch(Actions.RestaurantAdminTransactions.SetPage, 1);
          dispatch(Actions.SuperAdminRestaurants.SetPage, 1);
          dispatch(Actions.SuperAdminSubscriptions.SetPage, 1);
          dispatch(Actions.SuperAdminPayments.SetPage, 1);
        }} />}
      <nav aria-label="...">
        <ReactPaginate
          className='pagination justify-content-end mb-0'
          breakLabel="..."
          nextLabel=" "
          previousLabel=" "
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          forcePage={page - 1}
          pageCount={Math.ceil(totalCount / pageSize)}
          renderOnZeroPageCount={null}
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextClassName="page-item next-arrow"
          nextLinkClassName="page-link"
          previousClassName="page-item prev-arrow"
          previousLinkClassName="page-link"
        />
      </nav>
    </CardFooter>
  )
}
