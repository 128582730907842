import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { Input, Label, List } from 'reactstrap';
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { v4 as uuidv4 } from 'uuid';

const getId = () => uuidv4();

function ConditionNode({ id, data, selected, onDelete }) {
  const nodeRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 20, height: 20 });
  const updateNodeInternals = useUpdateNodeInternals();

  const positionHandle = (index, total, nodeHeight) => {
    const spacing = 15;
    const handleHeight = spacing * (total - 1) / 2;
    const adjustedTop = Math.max(0, (nodeHeight - handleHeight * 2) / 2) + spacing * index;

    return adjustedTop;
  };

  useLayoutEffect(() => {
    if (nodeRef.current) {
      const valueCount = data.value.length;
      const handleSpacing = 10;
      const itemHeight = 30;
      const padding = 20;

      const newHeight = Math.max(
        100,
        valueCount * itemHeight + padding + handleSpacing * (valueCount - (valueCount -1))
      );

      setDimensions({
        width: nodeRef.current.offsetWidth,
        height: newHeight
      });

      // Force React Flow to update node internals after setting dimensions
      updateNodeInternals(id);
    }
  }, [data.value.length, id, updateNodeInternals]);

  return (
    <div
      ref={nodeRef}
      className={`condition-box ${selected ? "custom-border" : ""}`}
    >
        <button
          onClick={() => onDelete(id)}
          className="delete-node"
          title="Delete Node"
        >
          ×
        </button>
      <div className="d-flex flex-column">
      <div className="condition-header web-theme-color">
        Condition
      </div>
        <div className="px-3 py-2">
          <input
            type="text"
            placeholder= "Add Label"
            value={data.label}
            className="add-label"
            disabled
            style={{ padding: '0.25rem', height: '1.1rem', width: '100%' }}
          />
          <textarea
            placeholder="Add Text"
            value={data.text}
            className="add-label"
            disabled
            rows={5}
            style={{ padding: '0.25rem', height: '100%', width: '100%' }}
          />
        </div>
        <List className="condition-list">
          {data && data.value.length > 0 && data.value.map((e) => {
            if (e.value) {
              return (
                <li key={e.id}>
                  {/* <Input
                    value={e.value}
                    name="chat-fun"
                    type={data.type === 'rbtn' ? "radio" : 'submit'}
                  /> */}
                  <Label check className=""> {e.value} </Label>
                </li>
              );
            }
            return null;
          })}
        </List>
      </div>

      <Handle
        id={getId()}
        type="target"
        position={Position.Left}
        className="custom-handle"
      />
      {data && data.value.length > 0 && data.value.map((e, i) => {
        if (e.value) {
          return (
            <Handle
              id={e.id}
              type="source"
              position={Position.Right}
              className="custom-handle"
              style={{ top: positionHandle(i, data.value.length, dimensions.height) }}
              key={e.id}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export default ConditionNode;
