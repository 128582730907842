export default function offers(
  initialState = {
    offers: [],
    offersWithPagination: [],
    products: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1,
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_OFFERS':
      return {
        ...initialState,
        offers: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_WITH_PAGINATION':
      return {
        ...initialState,
        offersWithPagination: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_PRODUCTS':
      return {
        ...initialState,
        products: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_OFFERS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_OFFERS_RESET':
      return {
        ...initialState,
        offers: [],
        offersWithPagination: [],
        products: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1,
      };

    default:
      return initialState;
  }
}
