import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Badge, Button, Card, CardBody, CardHeader, CardFooter, CardTitle, Col, FormGroup, Label, Row, } from "reactstrap";
import { featurePermissions, subscriptionType } from "../../constants/General";
import { addSubscription } from "../../pages/Restaurant/utils/middleware/subscription";
import moment from "moment";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SubscriptionCard(props) {
  // Props
  const { subscription, selectedSubscriptionId, setSelectedSubscription } =
    props;

  // Store
  const { restaurant } = useSelector((state) => state.restaurantAdmin);
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  const [showAll, setShowAll] = useState(false);

  const handleToggleShow = () => {
    setShowAll(!showAll);
  };

  const viewData = showAll
    ? Object.keys(featurePermissions)
    : Object.keys(featurePermissions).slice(0, 5);

  return (
    <Col className="subscription-col">
      <Card
        className={`card-stats ${subscription?.id && restaurant?.subscription?.id === subscription?.id
            ? "already-selected-card"
            : subscription?.id === selectedSubscriptionId
              ? "selected-card"
              : ""
          }`}
      >
        <CardHeader className="">
          <Row className="align-items-center">
            <div className="col">
              <CardTitle
                tag="div"
                className="text-uppercase font-weight-bold m-0 align-items-center flex h5"
              >
                <span className="mr-2">{subscription?.name}</span>
                <Badge className="badge-orange mr-2">
                  {subscriptionType[subscription?.type]}
                </Badge>
                <div className="h2 m-0">
                  <div className="price">
                    {subscription?.amount &&
                      `${settings?.superAdminSetting?.currencySign}${subscription?.amount}`}
                  </div>
                </div>
              </CardTitle>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <>
            <Row className="title-row">
              <Col md={12} className="space-between-div mb-2">
                <Label>Features</Label>
                <Label>Access</Label>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {viewData.map((feature) => (
                  <Row key={feature}>
                    <Col className="space-between-div mb-1">
                      <Label className="inputfield mb-0">
                        {featurePermissions[feature]}
                      </Label>
                      <FormGroup check className="p-0">
                        <label className="access-label mb-0">
                          <FontAwesomeIcon
                            icon={
                              subscription?.feature?.[feature]
                                ? faCheck
                                : faClose
                            }
                            color={
                              subscription?.feature?.[feature]
                                ? "#28a745"
                                : "#dc3545"
                            }
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="cursor-pointer text-right view-more-less">
                <span onClick={handleToggleShow}>
                  {showAll ? "...less" : "...more"}
                </span>
              </Col>
            </Row>
          </>
          {subscription?.description && (
            <p className="mt-3 mb-0 text-muted text-sm">
              {subscription.description}
            </p>
          )}
        </CardBody>
        <CardFooter>
          {restaurant?.subscription?.id === subscription?.id ? (
            <div className="footer-content-wrap mx--1">
              <div>
                <Badge style={{ fontSize: 12 }}>
                  {restaurant?.subscription?.startDate &&
                    moment(restaurant?.subscription?.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  <span className="mx-2">-</span>
                  {restaurant?.subscription?.endDate &&
                    moment(restaurant?.subscription?.endDate).format(
                      "DD-MM-YYYY"
                    )}
                </Badge>
              </div>
              <Button size="sm" className="mb-1 mx-1 btn-success">
                Purchased
              </Button>
            </div>
          ) : selectedSubscriptionId === subscription?.id ? (
            <>
              <Button
                color="primary"
                onClick={() => addSubscription(subscription?.id)}
              >
                Submit
              </Button>
              <Button
                color="outline-primary"
                onClick={() => setSelectedSubscription(null)}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              color="outline-primary"
              onClick={() => setSelectedSubscription(subscription)}
            >
              Choose
            </Button>
          )}
        </CardFooter>
      </Card>
    </Col>
  );
}
