import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Form, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap'
import { dispatch } from '../../utils/store'
import { Actions } from '../../redux/actions'

export default function SearchInput(props) {
  // Props
  const { action } = props

  // State
  const [searchValue, setSearchValue] = useState('')

  const handleChange = (e) => {
    const text = e.target.value || ''
    setSearchValue(text)
    if (e.key === 'Enter') {
      e.preventDefault()
      dispatch(Actions?.[action].SetSearch, text.trim())
      dispatch(Actions?.[action].SetPage, 1)
    }
  }

  const handleSearch = () => {
    dispatch(Actions?.[action].SetSearch, searchValue.trim())
    dispatch(Actions?.[action].SetPage, 1)
  }

  const clearSearch = () => {
    setSearchValue('')
    dispatch(Actions?.[action].SetSearch, '')
    dispatch(Actions?.[action].SetPage, 1)
  }

  return (
    <div className="search-div">
      <Form>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Search..."
              type="text"
              value={searchValue}
              onKeyPress={handleChange}
              onChange={handleChange}
            />
            <InputGroupText className="cursor-pointer search-btn">
              {searchValue && <FontAwesomeIcon icon={faXmark} className='mr-2' size='lg' onClick={clearSearch} />}
              <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
            </InputGroupText>
          </InputGroup>
        </FormGroup>
      </Form>
    </div>
  )
}
