import { COUPONS_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { couponsListDTO } from "../dtos/coupons"

export const getCoupons = (search, sortBy, orderBy, page = 1) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, COUPONS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminCoupons.SetCoupons, couponsListDTO(res?.data?.data?.rows))
      dispatch(Actions.RestaurantAdminCoupons.SetTotalCount, res?.data?.data?.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addCoupon = (data, search, activeSort, sortOrder, page, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, COUPONS_API, data)
    .then((res) => {
      handleSuccess(res)
      handleCloseAddModal()
      getCoupons(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editCoupons = (id, data, search, activeSort, sortOrder, page, handleCloseEditModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${COUPONS_API}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      handleCloseEditModal()
      getCoupons(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteCoupon = (id, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${COUPONS_API}/${id}`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal()
      getCoupons(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}