import React from 'react';
import { Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function ConfirmationModal(props) {
  // Props
  const { open, handleCloseModal, handleSuccess } = props

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      size="sm"
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Confirmation
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body">
        <p className='mb-0 text-center'>Are you sure ?</p>
      </div>
      <div className="modal-footer">
        <Button          
        color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          No
        </Button>
        <Button color='primary' type="button" onClick={handleSuccess}>
          Yes
        </Button>
      </div>
    </Modal>
  )
}
