import React,{useEffect} from 'react';
import { Button, Card, CardBody, Form, Modal, } from "reactstrap";
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function ViewQRCode(props) {
  // Props
  const { open, table, isTableNumerRequired, restaurantName, restaurantPhone, handleCloseModal } = props
    

  const downloadQRCode = () => {
    var canvas = document.getElementById("qrcode");
    var anchor = document.createElement("a");
    anchor.href = canvas.toDataURL("image/jpg");
    anchor.download = "QR_Code.jpg";
    anchor.click();
  }

  const phone = table?.restaurant?.phone || restaurantPhone
  const name = table?.restaurant?.name || restaurantName
  const tableNumber = table?.tableNumber

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='sm'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          QR Code
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form" className='center-div'>
              <QRCode id="qrcode" value={encodeURI(`https://wa.me/${phone}?text=Hi, ${name}${isTableNumerRequired ? `, I am at table no.${tableNumber}.` : '.'}`)} size={264} renderAs="canvas" />
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" onClick={downloadQRCode}>
          Download
        </Button>
      </div>
    </Modal>
  )
}
