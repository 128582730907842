import React from 'react'
import { useFormik } from 'formik';
import { Button, Card, CardBody, Form, Modal } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { customerValidationSchema } from '../../../../constants/Schemas';
import { trimObjectValues } from '../../../../services/middleware';

export default function CustomerModal(props) {
  // Props
  const { open, customer, handleCloseModal, handleSuccess } = props

  const formik = useFormik({
    initialValues: {
      name: customer?.name || '',
      about: customer?.about || '',
    },
    validationSchema: customerValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      handleSuccess(values)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='sm'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Customer
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <InputField placeholder="Name" fieldName="name" formik={formik} />
              <InputField placeholder="About" fieldName="about" formik={formik} />
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal>
  )
}
