import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { addOfferValidationSchema } from '../../../../constants/Schemas';
import { Button, Card, CardBody, Col, Form, InputGroup, InputGroupText, Label, Modal, Row } from "reactstrap";
import InputField from '../../../../components/InputField';
import { addProductOffer, getProductsForSelectedCategory, getSelectedProductData, updatePrice } from '../../utils/middleware/offers';
import { generateDropdownOptionFromObject, trimObjectValues } from '../../../../services/middleware';
import { DiscountType, selectDropdownStyle } from '../../../../constants/General';

export default function AddOfferModal(props) {
  // Props
  const { open, handleCloseModal } = props

  // Store
  const { categories } = useSelector(state => state.restaurantAdminProducts)
  const { products, search, activeSort, sortOrder, page } = useSelector(state => state.restaurantAdminOffers)

  // State
  const [alreadyOfferAdded, setAlreadyOfferAdded] = useState(false)
  const formik = useFormik({
    initialValues: {
      retailerId: '',
      categoryName: '',
      description: '',
      saleStart: '',
      saleEnd: '',
      discountType: '',
      discountValue: '',
      price: '',
    },
    validationSchema: addOfferValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      const price = values.retailerId?.value ? getSelectedProductData(products, values.retailerId.value).price : 0
      delete values.price
      addProductOffer({
        ...(values?.description && { description: values.description }),
        discountValue: parseInt(values.discountValue),
        categoryName: values.categoryName?.value || '',
        discountType: values.discountType?.value || '',
        saleStart: moment(values.saleStart).utc().format(),
        saleEnd: moment(values.saleEnd).utc().format(),
        offerOnCategory: !values.retailerId?.value,
        ...(values.retailerId?.value && {
          retailerId: values.retailerId?.value || '',
          title: values.retailerId?.label || '',
          salePrice: formik.values.discountType?.label === DiscountType.percentage ? (price - price * values.discountValue / 100).toString() : price - values.discountValue,
          imageLink: getSelectedProductData(products, values.retailerId.value).imageLink,
          price: parseInt(getSelectedProductData(products, values.retailerId.value).price),
          recommended: getSelectedProductData(products, values.retailerId.value).recommended
        }),
      }, search, activeSort, sortOrder, page, handleCloseModal)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Add Offer
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col md={6}>
                  <Label className='inputfield required-field'>
                    Category
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={categories} value={formik.values.categoryName} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("categoryName", selectedOption);
                        formik.setFieldValue("retailerId", '');
                        getProductsForSelectedCategory(selectedOption.value, formik)
                        setAlreadyOfferAdded(false)
                      }}
                      filterOption={(option) => !option.data.isParentCategory}
                    />
                    {formik.touched?.categoryName && formik.errors.categoryName && <span className="error-message input-group-alternative">{formik.errors.categoryName}</span>}
                  </section>
                </Col>
                <Col md={6}>
                  <Label className='inputfield'>
                    Product
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select
                      className={`custom-select ${!formik.values.categoryName && 'dropdown-disabled'}`}
                      isSearchable={false}
                      isDisabled={!formik.values.categoryName}
                      styles={selectDropdownStyle}
                      isClearable
                      onChange={selectedOption => {
                        formik.setFieldValue("retailerId", selectedOption);
                        formik.setFieldValue("price", selectedOption?.value ? getSelectedProductData(products, selectedOption.value)?.price : '');
                        setAlreadyOfferAdded(!!getSelectedProductData(products, selectedOption?.value || '')?.saleStart || !!getSelectedProductData(products, selectedOption?.value || '')?.saleEnd)
                        if (!selectedOption) { formik.setFieldValue("price", updatePrice(products)) }
                      }}
                      options={products?.map((product) => {
                        return {
                          label: product?.name,
                          value: product?.retailerId
                        }
                      })}
                    />
                    {formik.touched?.retailerId && formik.errors.retailerId && <span className="error-message input-group-alternative">{formik.errors.retailerId}</span>}
                  </section>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Sale Start Date
                  </Label>
                  <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                    <Datetime dateFormat={'DD-MM-YYYY'} onChange={(value) => {
                      if (moment(value).isSame(moment(formik.values.saleEnd)) || moment(value).isAfter(moment(formik.values.saleEnd))) {
                        formik.setFieldValue("saleEnd", moment(value).add(1, 'hour'))
                      }
                      formik.setFieldValue("saleStart", value)
                    }} value={formik.values.saleStart} isValidDate={(currentDate) => currentDate.isAfter(moment().subtract(1, 'day').format())} />
                    {formik.touched?.saleStart && formik.errors.saleStart && <span className="error-message input-group-alternative">{formik.errors.saleStart}</span>}
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Sale End Date
                  </Label>
                  <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                    <Datetime dateFormat={'DD-MM-YYYY'} onChange={(value) => {
                      if (moment(formik.values.saleStart).isSame(moment(value)) || moment(formik.values.saleStart).isAfter(moment(value))) {
                        formik.setFieldValue("saleEnd", moment(formik.values.saleStart).add(1, 'hour'))
                      } else {
                        formik.setFieldValue("saleEnd", value)
                      }
                    }} value={formik.values.saleEnd}
                      isValidDate={(currentDate) => currentDate.isAfter(moment(formik.values.saleStart).subtract(1, 'hour').format())} />
                    {formik.touched?.saleEnd && formik.errors.saleEnd && <span className="error-message input-group-alternative">{formik.errors.saleEnd}</span>}
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                {formik.values.retailerId ? <>
                  <Col md={4}>
                    <Label className='inputfield required-field'>
                      Discount Type
                    </Label>
                    <section className="mb-3 input-group-alternative">
                      <Select className="custom-select" value={formik.values.discountType} isSearchable={false} styles={selectDropdownStyle} onChange={selectedOption => formik.setFieldValue("discountType", selectedOption)} options={generateDropdownOptionFromObject(DiscountType)} />
                    </section>
                  </Col>
                  <Col md={4}>
                    <InputField disabled placeholder="Price" fieldName="price" formik={formik} />
                  </Col>
                  <Col md={4}>
                    <InputField required disabled={!formik.values.discountType || !products?.length} placeholder="Discount" fieldName="discountValue" formik={formik} />
                  </Col></> : <>
                  <Col md={6}>
                    <Label className='inputfield required-field'>
                      Discount Type
                    </Label>
                    <section className="mb-3 input-group-alternative">
                      <Select className="custom-select" value={formik.values.discountType} isSearchable={false} styles={selectDropdownStyle} onChange={selectedOption => formik.setFieldValue("discountType", selectedOption)} options={generateDropdownOptionFromObject(DiscountType)} />
                    </section>
                  </Col>
                  <Col md={6}>
                    <InputField required disabled={!formik.values.discountType || !products?.length} placeholder="Discount" fieldName="discountValue" formik={formik} />
                  </Col></>}
              </Row>
              <Row>
                <Col>
                  <InputField placeholder="Description" fieldName="description" inputType="textarea" rows={3} formik={formik} />
                </Col>
              </Row>
            </Form>
            {alreadyOfferAdded && <span className="error-message input-group-alternative">Sorry, you have already added an offer for this product. You can change the existing offer or remove it before adding a new one.</span>}
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={(!!Object.values(formik.errors)?.length) || alreadyOfferAdded} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}
