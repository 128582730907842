import moment from "moment"
import { COUPONS_API, CUSTOMERS_LIST_API, ORDERS_API, PRODUCTS_API, TABLES_API } from "../../../../constants/Configs"
import { API_REQUEST, DiscountType, OrderStatus } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { couponsListDTO } from "../dtos/coupons"
import { customersListDTO } from "../dtos/customers"
import { ordersListDTO } from "../dtos/orders"
import { productsListDTO } from "../dtos/products"
import { Badge } from "reactstrap"
import { tableListDTO } from "../dtos/tables"

export const getOrders = (search, startDate, endDate, sortBy, orderBy, page = 1) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!startDate ? { startDate } : {}),
    ...(!!endDate ? { endDate } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, ORDERS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminOrders.SetOrders, ordersListDTO(res?.data?.data?.rows))
      dispatch(Actions.RestaurantAdminOrders.SetTotalCount, res?.data?.data?.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addOrder = ({ filterData, search, startDate, endDate, activeSort, sortOrder, page, handleCloseAddModal }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, ORDERS_API, filterData)
    .then((res) => {
      handleSuccess(res)
      handleCloseAddModal()
      getOrders(search, startDate, endDate, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editOrder = ({ id, filterData, search, startDate, endDate, activeSort, sortOrder, page, handleCloseEditModal }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${ORDERS_API}/${id}`, filterData)
    .then(res => {
      handleSuccess(res)
      handleCloseEditModal()
      getOrders(search, startDate, endDate, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const sendPaymentLink = ({ id, filterData, search, startDate, endDate, activeSort, sortOrder, page, handleCloseModal }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${ORDERS_API}/${id}/send-payment-link`, filterData)
    .then(res => {
      handleSuccess(res)
      handleCloseModal()
      getOrders(search, startDate, endDate, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const completeOrder = ({ id, search, startDate, endDate, activeSort, sortOrder, page, handleCloseCompleteOrderModal }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${ORDERS_API}/status/${id}`, { status: OrderStatus.COMPLETE })
    .then(res => {
      handleSuccess(res)
      handleCloseCompleteOrderModal()
      getOrders(search, startDate, endDate, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getProducts = () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  return API(API_REQUEST.get, PRODUCTS_API)
    .then(res => {
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return productsListDTO(res.data.data.rows).reduce((acc, cur) => {
        const { category, ...rest } = cur;
        acc[category] = [...(acc[category] || []), {
          label: rest?.name,
          quantity: 0,
          price: rest?.salePrice ? parseInt(rest?.salePrice) : parseInt(rest?.price),
          value: rest?.retailerId
        }];
        return acc;
      }, {});
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getCustomers = async (searchValue = '') => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    pagination: 0, isSubscribe: 1, search: searchValue
  }
  await API(API_REQUEST.get, CUSTOMERS_LIST_API, { params })
    .then(res => {
      const customers = customersListDTO(res.data.data.rows)?.map((customer) => {
        return {
          label: customer?.customer?.whatsappNumber,
          value: customer?.customer?.whatsappNumber
        }
      })
      dispatch(Actions.RestaurantAdminOrders.SetCustomers, customers)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getCoupons = async () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...({ 'couponOnDate': moment().toISOString() })
  }

  await API(API_REQUEST.get, COUPONS_API, { params })
    .then(res => {
      const coupons = couponsListDTO(res.data.data.rows)?.map((coupon) => {
        return {
          label: coupon?.couponCode,
          type: DiscountType[coupon?.type],
          price: coupon?.value,
          value: coupon?.id
        }
      })
      dispatch(Actions.RestaurantAdminOrders.SetCoupons, coupons)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getTables = async () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)

  API(API_REQUEST.get, TABLES_API)
    .then(res => {
      const tables = tableListDTO(res.data.data.rows)?.map((table) => {
        return {
          label: table?.tableNumber,
          value: table?.tableNumber
        }
      })
      dispatch(Actions.RestaurantAdminOrders.SetTables, tables)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const filterPayload = (data, orderItems) => {
  let payload = { tableNumber: data.tableNumber, couponId: data.couponId.value }
  if (data?.customers?.length) {
    let customers = data.customers.map(customer => ({
      name: customer.label,
      phone: customer.value
    }))
    payload = { ...payload, customers }
  }

  if (data?.customerName && data?.customerNumber) {
    payload = { ...payload, customers: [{ name: data?.customerName, phone: data?.customerNumber }] }
  }

  if (orderItems?.length) {
    let selectedOrderItems = orderItems.filter(item => item?.quantity > 0)
    payload = { ...payload, items: selectedOrderItems }
  }

  return payload
}

export const setCustomers = (data) => {
  return data.map(datum => ({
    label: datum.name,
    value: datum.phone
  }))
}

export const setCoupon = (selected, coupons) => {
  return coupons.find(datum => selected === datum?.value)
}

export const setPrefilledProducts = (order, products) => {
  let mergedProducts = [];
  for (let i = 0; i < products.length; i++) {
    let found = false;
    for (let j = 0; j < order?.orderItems.length; j++) {
      if (products[i].productRetailerId === order?.orderItems[j].productRetailerId) {
        let newProduct = { ...products[i], quantity: order?.orderItems[j].quantity, price: order?.orderItems[j].price };
        mergedProducts.push(newProduct);
        found = true;
        break;
      }
    }
    if (!found) {
      mergedProducts.push(products[i]);
    }
  }
  return mergedProducts
}

export const orderStatus = (order) => {
  if (order?.status === OrderStatus["ADDED TO CART"] || order?.status === OrderStatus["PAYMENT INITIATED"] || order?.status === OrderStatus["ADDED TO CART PARTIALLY"]) {
    return <Badge className="badge-info">
      {Object.keys(OrderStatus).find(key => OrderStatus[key] === order?.status)}
    </Badge>
  } else if (order?.status === OrderStatus["ORDER CONFIRMED"] || order?.status === OrderStatus["COMPLETE"] || order?.status === OrderStatus["PAYMENT DONE"]) {
    return <Badge className="badge-success">
      {Object.keys(OrderStatus).find(key => OrderStatus[key] === order?.status)}
    </Badge>
  } else if (order?.status === OrderStatus["CANCELLED"] || order?.status === OrderStatus["PAYMENT FAILED"]) {
    return <Badge className="badge-danger">
      {Object.keys(OrderStatus).find(key => OrderStatus[key] === order?.status)}
    </Badge>
  } else if (order?.status === OrderStatus["PARTIALLY PAID"]) {
    return <>
      <Badge className="badge-info mr-2">
        {Object.keys(OrderStatus).find(key => OrderStatus[key] === order?.status)}
      </Badge>
      <Badge className="badge-info">
        {`${order?.transactions.filter(transaction => transaction.status === 1).length}/${order?.payingCustomer?.length}`}
      </Badge>
    </>
  }
}

export const prefillData = (selected, allData) => {
  let obj = {}
  selected.forEach((item, index) => {
    Object.keys(allData).forEach(category => {
      let selected = allData[category].find(a => a?.value === item.productRetailerId)
      if (selected) {
        obj = { ...obj, [index]: { ...obj[item], name: selected.label, category: { label: category, value: category }, productRetailerId: selected.value, price: selected.price, quantity: item.quantity } }
      }
    })
  })
  return obj
}