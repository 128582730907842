export function reservationsListDTO(data) {
  let reservations = [];

  if (data?.length) {
    reservations = data.map((reservation) => ({
      id: reservation.id || 0,
      customerNumber: reservation?.customerNumber || null,
      customerName: reservation?.customerName || null,
      startTime: reservation.startTime || null,
      endTime: reservation.endTime || null,
      numberOfGuests: reservation.numberOfGuests || null,
      status: reservation.status,
      tableNumber: reservation.tableNumber || null,
    }))
  }

  return reservations;
}