export default function categories(
  initialState = {
    categories: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_CATEGORIES':
      return {
        ...initialState,
        categories: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CATEGORIES_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CATEGORIES_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CATEGORIES_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CATEGORIES_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_CATEGORIES_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };


    case 'RESTAURANT_ADMIN_CATEGORIES_RESET':
      return {
        ...initialState,
        categories: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
