import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Form, Input, InputGroup, Label, Modal, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { addProductValidationSchema, } from '../../../../constants/Schemas';
import { ImageType, selectDropdownStyle } from '../../../../constants/General';
import { addProduct } from '../../utils/middleware/products';
import { removeEmptyKeysFromObject, trimObjectValues, uploadImage } from '../../../../services/middleware';
import { dispatch } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { handleError } from '../../../../utils/toast';
import { getLocalStorageUserData } from '../../../../utils/session';
import UploadLogo from '../../../../components/UpdateLogo'

export default function AddProductModal(props) {
  // Props
  const { open, handleCloseModal } = props

  // State
  const [subCategories, setSubCategories] = useState([])
  const [isParentCategory, setIsParentCategory] = useState(true)

  // Store
  const { categories, search, activeSort, sortOrder, page } = useSelector(state => state.restaurantAdminProducts)

  const formik = useFormik({
    initialValues: {
      title: '',
      imageLink: '',
      description: '',
      price: 0,
      categoryName: '',
      subCategoryName: '',
      recommended: false,
    },
    validationSchema: addProductValidationSchema,
    onSubmit: async (values) => {
      trimObjectValues(values, 'imageLink')
      dispatch(Actions.RestaurantAdmin.SetLoading, true)
      const imageResponse = values.imageLink ? await uploadImage(values.imageLink, ImageType.PRODUCT_IMAGE).catch(err => {
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
        handleError(err)
      }) : { fileLink: '' }
      values.imageLink = imageResponse?.fileLink;
      values.categoryName = isParentCategory ? (values.categoryName?.value || '') : (values.subCategoryName?.value || '');
      values.price = parseInt(values?.price) || 0;
      values.retailerId = `${values.title}-${values.categoryName}-${getLocalStorageUserData()?.name}`
      delete values.subCategoryName
      if (!values.description) {
        values.description = `Elevate your dining at home experience with our gourmet ${values.title}, a delectable treat that won't strain your budget at ${values.price}.`
      }
      const filterData = removeEmptyKeysFromObject(values)
      if(values.price == 0){
        filterData.price = 0
      }
      addProduct(filterData, search, activeSort, sortOrder, page, handleCloseModal)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Add Product
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col md={12}>
                  <div className='inline-block'>
                    <UploadLogo formik={formik} fieldName="imageLink" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Title" fieldName="title" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField placeholder="Description" fieldName="description" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label for="exampleSelect" className='inputfield required-field'>
                    Category
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={categories} value={formik.values?.categoryName} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("categoryName", selectedOption);
                        setIsParentCategory(!selectedOption.subCategories?.length)
                        setSubCategories(selectedOption.subCategories)
                        formik.setFieldValue("subCategoryName", '');
                      }}
                      filterOption={(option) => option.data.isParentCategory}
                    />
                    {formik.touched?.categoryName && formik.errors.categoryName && <span className="error-message input-group-alternative">{formik.errors.categoryName}</span>}
                  </section>
                </Col>
                <Col md={4}>
                  <Label for="exampleSelect" className='inputfield'>
                    Sub Category
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" isDisabled={isParentCategory} value={formik.values?.subCategoryName} options={subCategories} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("subCategoryName", selectedOption);
                      }}
                    />
                    {formik.touched?.subCategoryName && formik.errors.subCategoryName && <span className="error-message input-group-alternative">{formik.errors.subCategoryName}</span>}
                  </section>
                </Col>
                <Col md={4}>
                  <InputField required placeholder="Price" fieldName="price" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label for="exampleSelect" className='inputfield required-field'>
                    Recommended
                  </Label>
                  <InputGroup className='recommended-field'>
                    <label className="custom-toggle">
                      <Input type="checkbox"
                        className="form-control"
                        name="recommended"
                        value={formik.values?.recommended}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}
