export function productsListDTO(data) {
  let products = [];

  if (data?.length) {
    products = data.map((product) => ({
      id: product?.id || 0,
      name: product?.name || null,
      retailerId: product?.retailerId || null,
      currency: product?.currency || null,
      condition: product?.condition || null,
      category: product?.category || null,
      price: product?.price?.slice(1) || 0,
      description: product?.description || null,
      salePrice: product?.salePrice?.slice(1) || null,
      saleStart: product?.saleStart || null,
      saleEnd: product?.saleEnd || null,
      availability: product?.availability || null,
      brand: product?.brand || null,
      imageLink: product?.imageLink || null,
      discountType: product?.discountType || null,
      discountValue: product?.discountValue || null,
      recommended: product?.recommended,
    }))
  }

  return products;
}