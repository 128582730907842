export default function restaurants(
  initialState = {
    restaurants: [],
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_SUPER_ADMIN_RESTAURANTS':
      return {
        ...initialState,
        restaurants: action.payload,
      };

    case 'SET_SUPER_ADMIN_RESTAURANTS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_SUPER_ADMIN_RESTAURANTS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_SUPER_ADMIN_RESTAURANTS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_SUPER_ADMIN_RESTAURANTS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_SUPER_ADMIN_RESTAURANTS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'SUPER_ADMIN_RESTAURANTS_RESET':
      return {
        ...initialState,
        restaurants: [],
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
