import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media, Navbar, NavItem, NavLink, Nav, } from "reactstrap";
import { getLocalStorageUserData, removeSessionData, } from "../../utils/session";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faKey } from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "../ChangePassword";
import logoRed from "../../assets/images/logo-red.svg";
import logoShort from "../../assets/images/logo-short.svg";

const AdminSidebar = (props) => {
  // Store
  const { restaurant } = useSelector((state) => state.restaurantAdmin);
  const isAdminUser = useSelector((state) => state.user.isAdminUser);

  // State
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState();

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeclassname="active"
          >
            <i className={prop.icon} />
            <span className="menu-name">{prop.name}</span>
          </NavLink>
        </NavItem>
      );
    });
  };

  const { routes, logo } = props;
  function collapse_sidebar() {
    var body_el = document.querySelector("body");
    body_el.classList.toggle("collapse-side-menu");
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left site-sidebar"
      expand="lg"
      id="sidenav-main"
    >
      {/* Toggler */}
      <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
        <span className="navbar-toggler-icon">
          <svg width="28" height="18" viewBox="0 0 28 18">
            <rect width="28" height="2" fill="#5d5c61" />
            <rect y="8" width="16" height="2" fill="#5d5c61" />
            <rect y="16" width="28" height="2" fill="#5d5c61" />
          </svg>
        </span>
      </button>
      {/* Brand */}
      <div className="sidebar-topbar">
        {logo ? (
          <Media className="logo-outer-wrap">
            <span className="logo-wrapper">
              <img alt="logoRed" src={logoRed} className="normal-logo" />
              <img alt="logoShort" src={logoShort} className="mini-logo" />
            </span>
          </Media>
        ) : null}
        <div className="hamburger-menu" onClick={() => collapse_sidebar()}>
          {/* <FontAwesomeIcon icon={faCircleDot} /> */}
          <span className="outer-circle">
            <em></em>
          </span>
        </div>
      </div>
      {/* User */}
      <Nav className="align-items-center d-lg-none">
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="logo-outer-wrap">
              <span className="logo-wrapper">
                {restaurant?.logo ? (
                  <img alt="..." src={restaurant?.logo} />
                ) : (
                  <FontAwesomeIcon icon={faImage} />
                )}
              </span>
              <Media className="ml-2 d-none d-lg-block">
                <span className="mb-0 text-sm font-weight-bold">
                  {restaurant?.name || getLocalStorageUserData()?.name}
                </span>
              </Media>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" end>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">Welcome!</h6>
            </DropdownItem>
            {!isAdminUser && (
              <DropdownItem to="/admin/profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
            )}
            <DropdownItem
              tag={Link}
              onClick={() => setChangePasswordModal(true)}
            >
              <FontAwesomeIcon icon={faKey} />
              <span>Change password</span>
            </DropdownItem>
            <DropdownItem to="/admin/settings" tag={Link}>
              <i className="ni ni-settings-gear-65" />
              <span>Settings</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem href="#" onClick={() => removeSessionData()}>
              <i className="ni ni-button-power" />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/* Collapse */}
      <Collapse navbar isOpen={collapseOpen}>
        {/* Navigation */}
        <Nav navbar>{createLinks(routes)}</Nav>
      </Collapse>

      {changePasswordModal && (
        <ChangePassword
          open={changePasswordModal}
          handleCloseModal={() => setChangePasswordModal(false)}
        />
      )}
    </Navbar>
  );
};

AdminSidebar.defaultProps = {
  routes: [{}],
};

AdminSidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default AdminSidebar;
