import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Form, Row, Nav, TabContent, TabPane, NavItem, NavLink, Label, } from "reactstrap";
import InputField from "../../../../components/InputField";
import { adminSettingsInputFields, superAdminSettingTabs, } from "../../../../constants/General";
import { editAdminSettingsSchema } from "../../../../constants/Schemas";
import { removeEmptyKeysFromObject } from "../../../../services/middleware";
import { editMessages, editSettings, setInitialMessages, setInitialValues, } from "../../utils/middleware/settings";
import classNames from "classnames";

export default function Settings() {
  // Store
  const settings = useSelector((state) => state.superAdmin.settings);
  const messages = useSelector((state) => state.superAdmin.messages);

  // State
  const [editable, setEditable] = useState(false);
  const [chatbotMessages, setChatbotMessages] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: setInitialValues(settings),
    validationSchema: editAdminSettingsSchema,
    onSubmit: (values, { resetForm }) => {
      const filterData = removeEmptyKeysFromObject(values);
      editSettings(filterData, resetForm, setEditable);
    },
  });

  const messageFormik = useFormik({
    enableReinitialize: true,
    initialValues: setInitialMessages(messages),
    validationSchema: Yup.object(
      messages?.length
        ? messages.reduce((schema, item) => {
          switch (true) {
            case item.key.includes("Button"):
              schema[item.key] = Yup.string().max(
                20,
                "Maximum 20 characters allowed"
              );
              break;
            case item.key.includes("Header"):
              schema[item.key] = Yup.string().max(
                60,
                "Maximum 60 characters allowed"
              );
              break;
            case item.key.includes("Title"):
              schema[item.key] = Yup.string().max(
                24,
                "Maximum 24 characters allowed"
              );
              break;
            default:
              schema[item.key] = Yup.string().max(
                1024,
                "Maximum 1024 characters allowed"
              );
          }
          return schema;
        }, {})
        : {}
    ),
    onSubmit: (values, { resetForm }) => {
      const filterData = removeEmptyKeysFromObject(values);
      const payload = Object.keys(filterData).map((value) => ({
        key: value,
        message_en: filterData[value],
      }));
      editMessages({ messages: payload }, resetForm, setEditable);
    },
  });

  useEffect(() => setEditable(false), [activeTab]);

  useEffect(() => {
    if (messages?.length) {
      const categorizeMessages = (msgs) => {
        return msgs.reduce(
          (categories, msg) => {
            const categoryKey = msg.key.includes("Button")
              ? "Button"
              : msg.key.includes("Header")
                ? "Headers"
                : msg.key.includes("Title")
                  ? "Titles"
                  : "Others";

            categories[categoryKey].push(msg);
            return categories;
          },
          { Button: [], Headers: [], Titles: [], Others: [] }
        );
      };

      const chunkArray = (arr) => {
        return arr.reduce((result, item, index) => {
          const chunkIndex = Math.floor(index / 3);
          result[chunkIndex] = result[chunkIndex] || [];
          result[chunkIndex].push(item);
          return result;
        }, []);
      };

      const { Button, Headers, Titles, Others } = categorizeMessages(messages);

      setChatbotMessages({
        Button: chunkArray(Button),
        Headers: chunkArray(Headers),
        Titles: chunkArray(Titles),
        Others: chunkArray(Others),
      });
    }
  }, [messages]);

  return (
    <div>
      <Card className="shadow overflow-hidden">
        <CardBody className="p-0">
          <div className="custom-tabs-wrapper">
            <Nav tabs className="custom-tab-links">
              {superAdminSettingTabs.map((tab, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={classNames({
                        active: activeTab === index,
                      })}
                      onClick={() => {
                        setActiveTab(index);
                      }}
                    >
                      <h3>{tab}</h3>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab} className="custom-tab-content">
              <TabPane tabId={0}>
                <div className="tab-content-inner-wrap">
                  <Form role="form">
                    {adminSettingsInputFields.map((row, index) => {
                      return (
                        <Row key={index}>
                          <Col md={6}>
                            <InputField
                              required
                              disabled={!editable}
                              placeholder={row.placeholderLeft}
                              fieldName={row.fieldNameLeft}
                              formik={formik}
                            />
                          </Col>
                          {row.fieldNameRight && (
                            <Col md={6}>
                              <InputField
                                required
                                disabled={!editable}
                                placeholder={row.placeholderRight}
                                fieldName={row.fieldNameRight}
                                formik={formik}
                              />
                            </Col>
                          )}
                        </Row>
                      );
                    })}
                    <div className="text-right">
                      {editable ? (
                        <>
                          <Button
                            className="mt-4"
                            color="outline-primary"
                            type="button"
                            disabled={!!Object.values(formik.errors)?.length}
                            onClick={formik.handleSubmit}
                          >
                            Update
                          </Button>
                          <Button
                            className="mt-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              formik.setValues(setInitialValues(settings));
                              setEditable(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() => setEditable(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
              </TabPane>
              <TabPane tabId={1}>
                <div className="tab-content-inner-wrap">
                  <Form role="form">
                    {!!chatbotMessages?.Button?.length && (
                      <>
                        <Label className="mb-2 bold">Buttons</Label>
                        {chatbotMessages.Button.map((row, rowIndex) => (
                          <Row key={rowIndex}>
                            {row.map((item, colIndex) => (
                              <Col md={4} key={colIndex}>
                                <InputField
                                  disabled={!editable}
                                  placeholder={item.key}
                                  fieldName={item.key}
                                  value={messageFormik.values[item.key]}
                                  formik={messageFormik}
                                />
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </>
                    )}
                    {!!chatbotMessages?.Headers?.length && (
                      <>
                        <Label className="mb-2 bold">Headers</Label>
                        {chatbotMessages.Headers.map((row, rowIndex) => (
                          <Row key={rowIndex}>
                            {row.map((item, colIndex) => (
                              <Col md={4} key={colIndex}>
                                <InputField
                                  disabled={!editable}
                                  placeholder={item.key}
                                  fieldName={item.key}
                                  value={messageFormik.values[item.key]}
                                  formik={messageFormik}
                                />
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </>
                    )}
                    {!!chatbotMessages?.Titles?.length && (
                      <>
                        <Label className="mb-2 bold">Titles</Label>
                        {chatbotMessages.Titles.map((row, rowIndex) => (
                          <Row key={rowIndex}>
                            {row.map((item, colIndex) => (
                              <Col md={4} key={colIndex}>
                                <InputField
                                  disabled={!editable}
                                  placeholder={item.key}
                                  fieldName={item.key}
                                  value={messageFormik.values[item.key]}
                                  formik={messageFormik}
                                />
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </>
                    )}
                    {!!chatbotMessages?.Others?.length && (
                      <>
                        <Label className="mb-2 bold">Others</Label>
                        {chatbotMessages.Others.map((row, rowIndex) => (
                          <Row key={rowIndex}>
                            {row.map((item, colIndex) => (
                              <Col md={4} key={colIndex}>
                                <InputField
                                  disabled={!editable}
                                  placeholder={item.key}
                                  fieldName={item.key}
                                  value={messageFormik.values[item.key]}
                                  formik={messageFormik}
                                />
                              </Col>
                            ))}
                          </Row>
                        ))}
                      </>
                    )}
                    <div className="text-right">
                      {editable ? (
                        <>
                          <Button
                            className="mt-4"
                            color="outline-primary"
                            type="button"
                            disabled={
                              !!Object.values(messageFormik.errors)?.length
                            }
                            onClick={messageFormik.handleSubmit}
                          >
                            Update
                          </Button>
                          <Button
                            className="mt-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              messageFormik.setValues(
                                setInitialMessages(messages)
                              );
                              setEditable(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() => setEditable(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
