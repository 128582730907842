import React from "react";
import { Card, CardBody, Form } from "reactstrap";
import { BrandName } from "../../constants/General";

export default function TermsAndConditions() {
  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap privacy-policy-div">
        <div className="logo-wrap">
          <span>{BrandName}</span>
        </div>
        <Card className="shadow">
          <CardBody>
            <Form role="form">
              <div className="text-center mb-4">
                <h1>WePOS- Terms & Conditions</h1>
              </div>
              <section>
                <p>Wepos.ai is a product of RAG TECH LTD.</p>
                <p>
                  <b>RAG TECH LTD</b> (“Wepos.ai”, “Wepos”, “RAG TECH”, “we”,
                  “us”, or “our”) provides Software as a Service (SaaS) that
                  allows our customers to store, manipulate, analyze and
                  transfer messages between their business systems and their
                  customers on a variety of RAG TECH provided and third party
                  messaging channels (the “Service”). A “Customer” is an entity
                  with whom RAG TECH has an agreement to provide the Service.
                </p>
                <p>
                  This RAG TECH Terms of Service (the “Agreement”) is an
                  agreement between the Customer and RAG TECH, together the
                  “Parties” and each, a “Party”, and is entered on the date the
                  Customer signs up for a RAG TECH account through the RAG TECH
                  website (the “Effective Date”).
                </p>
                <p>
                  If you register for a RAG TECH or Wepos.ai account, you
                  acknowledge your understanding of these terms and enter the
                  Agreement on behalf of the Customer. Please make sure you have
                  the necessary authority to enter into the Agreement on behalf
                  of the Customer before proceeding.
                </p>
                <p>
                  If a customer violates any of the terms outlined below, we
                  reserve the right to cancel accounts or bar access to accounts
                  without notice. If you do not agree to these terms, please do
                  not use our Services.
                </p>
                <p>
                  RAG TECH may, but have no obligation to, remove Content and Accounts
                  containing Content that we determine in our sole discretion are unlawful, offensive,
                  threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable
                  or violates any party’s intellectual property or these Terms of Use.
                </p>
                <p>
                  RAG TECH may refuse service to anyone for any reason at any time.
                </p>
                <p>
                  BY USING ANY ELEMENT OF THE RAG TECH SOLUTION (INCLUDING THE
                  WEBSITE), CUSTOMER ACKNOWLEDGES THAT CUSTOMER HAS READ,
                  ACCEPTS AND AGREES TO BE BOUND BY AND COMPLY WITH THE TERMS
                  AND CONDITIONS, SET OUT IN THIS AGREEMENT, AS AMENDED FROM
                  TIME TO TIME. IF CUSTOMER DOES NOT ACCEPT AND AGREE TO BE
                  BOUND BY THIS AGREEMENT, CUSTOMER WILL IMMEDIATELY CEASE ANY
                  FURTHER USE OF THE RAG TECH SOLUTION, INCLUDING USE OF ANY
                  PART THEREOF. CUSTOMER REPRESENTS AND WARRANTS TO RAG TECH
                  THAT CUSTOMER HAS THE CAPACITY TO ENTER INTO THIS LEGALLY
                  BINDING AGREEMENT. IF CUSTOMER IS USING THE RAG TECH SOLUTION
                  ON BEHALF OF ANOTHER PERSON, CUSTOMER HEREBY REPRESENTS AND
                  WARRANTS TO RAG TECH THAT CUSTOMER HAS THE AUTHORITY TO BIND
                  SUCH PERSON TO THIS AGREEMENT.
                </p>
                <div>
                  <h3>WePOS Platform</h3>
                  <p>
                    Provisioning of the RAG TECH Platform. Subject to Customer’s
                    compliance with the terms and conditions of this Agreement,
                    RAG TECH will make the RAG TECH Platform available to
                    Customer on the terms and conditions set out in this
                    Agreement, provided that: (i) Customer has read and accepted
                    and is in compliance with its obligations under the
                    applicable terms of use related to all applicable Third
                    Party Messaging Platforms, Pre-Integrated Third-Party
                    Business Applications, and Custom-Integrated Business
                    Applications; and (ii) has taken all steps as necessary to
                    enable interoperability between the RAG TECH Platform and
                    all applicable Third Party Messaging Platforms,
                    Pre-Integrated Third-Party Business Applications, and
                    Custom-Integrated Business Applications, including acquiring
                    the necessary approvals and API keys, whether directly or
                    via Customer’s Clients, to access these messaging channels
                    as a business.
                  </p>
                </div>
                <div>
                  <h3>Modifications to the Service and Fees</h3>
                  <p>
                    RAG TECH reserves the right to modify, suspend, or
                    discontinue the Service at any time for any reason, with or
                    without notice.
                  </p>
                  <p>
                    RAG TECH reserves the right to change our monthly/annual
                    fees upon 30 days’ notice. The fee change will be notified
                    per email to all our subscribers and will be reflected on
                    the pricing page at wepos.ai/pricing.
                  </p>
                  <p>
                    RAG TECH reserves the right to update and change the Terms
                    of Service from time to time without notice. Any new
                    features that augment or enhance the current Service,
                    including the release of new tools and resources, shall be
                    subject to the Terms of Service. Should you continue to use
                    the Service after any such modifications have been made,
                    this shall constitute your agreement to such modifications.
                  </p>
                </div>
                <div>
                  <h3>Trademark License</h3>
                  <p>
                    During the Term, Customer hereby grants to RAG TECH a
                    worldwide, non-exclusive, non-transferable and
                    non-sub-licensable (other than to affiliates) royalty-free
                    licence to use Customer’s trademarks and logos made
                    available to RAG TECH by Customer as part of this Agreement,
                    solely in connection with the marketing, advertising, and
                    promotion of the RAG TECH Solution, including listing the
                    Customer and the Customer Application on the RAG TECH
                    Website; and only in accordance with Customer’s reasonable
                    trademark usage guidelines, as updated by Customer from time
                    to time. Customer may require RAG TECH to cease using
                    Customer’s trademarks and logos if, in the reasonable
                    opinion of Customer, the continued display of Customer’s
                    trademarks and logos would cause a materially adverse effect
                    on Customer’s image and the goodwill associated therewith.
                  </p>
                </div>
                <div>
                  <h3>Copyrights & Ownership</h3>
                  <p>
                    RAG TECH expressly reserves all rights, title, and interest
                    in, and Customer will not acquire any right, title or
                    interest in (i) the RAG TECH Solution (or any part thereof)
                    and any other materials or content provided by RAG TECH
                    under this Agreement, including any and all Modifications to
                    any of the foregoing; and (ii) all intellectual property
                    rights in any of the foregoing (clauses (i) and (ii) are
                    collective, the “RAG TECH Property”), in each case. All
                    right, titles and interests in the RAG TECH Property will
                    remain with RAG TECH (or RAG TECH’s third-party suppliers,
                    as applicable). Customer may not copy, modify, adapt,
                    reproduce, distribute, reverse engineer, decompile, or
                    disassemble any aspect of the Service which RAG TECH or its
                    suppliers own. The customer agrees not to resell, duplicate,
                    reproduce or exploit any part of the Service without the
                    express written permission of RAG TECH. For greater
                    certainty, the RAG TECH Property is licensed and not “sold”
                    to the Customer.
                  </p>
                  <p>
                    RAG TECH claims no intellectual property rights over the
                    Content you upload or provide to the Service.
                  </p>
                  <p>
                    RAG TECH’s Right to Use Customer Data Customer acknowledges
                    and agrees that RAG TECH may store, use, reproduce, Modify,
                    and transfer to its subcontractors, Customer Data, including
                    Personal Data, solely in connection with delivering the
                    Services under this Agreement. Customer further acknowledges
                    and agrees that RAG TECH may store, use, reproduce, Modify,
                    and transfer data that is not related to an identified or
                    identifiable natural person, including aggregated or
                    de-identified data, without limitation, for its internal
                    business purposes, including but not limited to such
                    purposes as analytics, quality assurance, product and
                    service improvement, and new product and service
                    development. The customer agrees to cause any Administrative
                    User, Customer’s Client and Chat Participant to agree to the
                    terms.
                  </p>
                </div>
                <div>
                  <h3>Customer Responsibilities</h3>
                  <p>
                    <b>Customer Accounts.</b> Upon Customer’s request, RAG TECH
                    will issue one or more administrator accounts (the
                    “Administrator Accounts”) to Customer that provides Customer
                    with the capability to create accounts for use by
                    individuals who are an employee or contractor of Customer
                    and that Customer wishes to have access to and use of the
                    RAG TECH Platform (each, an “Administrative User”). Customer
                    will ensure that Administrative Users only use the RAG TECH
                    Platform through their Customer Account. Customer will not
                    share their Administrator Accounts with any other person and
                    will not allow Administrative Users to share their Customer
                    Account with any other person. The customer will promptly
                    notify RAG TECH of any actual or suspected unauthorized use
                    of the RAG TECH Platform. RAG TECH reserves the right to
                    suspend, deactivate, or replace any Customer Account or
                    Administrator Accounts if it determines that the Customer
                    Account or Administrator Account, as applicable, may have
                    been used for an unauthorized purpose.
                  </p>
                  <p>
                    Customers are responsible for maintaining the privacy and
                    security of their accounts. RAG TECH will not be held liable
                    for any damage or loss that may result from your failure to
                    protect your login information, including your password.
                  </p>
                  <p>
                    <b>Use Restrictions.</b> Customer acknowledges and agrees that it is responsible for
                    the activities and communications of all Administrative
                    Users and Chat Participants on the RAG TECH Platform, and
                    the compliance by all Administrative Users, Customer’s
                    Clients and Chat Participants with this Agreement, and any
                    guidelines and policies published by RAG TECH from time to
                    time.
                  </p>
                  <p>
                    The Customer is fully responsible for all activities that
                    occur under the account and any other actions taken in
                    connection with the account. You are responsible for all
                    Content sent and activity that occurs under your account
                    (even when Content is sent by others to your account).
                  </p>
                  <p>
                    RAG TECH may, but have no obligation to, remove Content and
                    Accounts containing Content that we determine in our sole
                    discretion are unlawful, offensive, threatening, libellous,
                    defamatory, pornographic, obscene or otherwise objectionable
                    or violates any party’s intellectual property or these Terms
                    of Use.
                  </p>
                  <p>
                    Without limiting the generality of any of the foregoing,
                    Customer will not, and will not permit any other person
                    (including any Administrative Users, Customer’s Clients or
                    Chat Participants) to:
                  </p>
                  <p>
                    – use the RAG TECH Platform to send, upload, collect,
                    transmit, store, use, disclose or process, or ask RAG TECH
                    to obtain from third parties or perform any of the above
                    with respect to, any Customer Data: that contains any
                    computer viruses, worms, malicious code, or any software
                    intended to damage or alter a computer system or data;
                  </p>
                  <ul>
                    <li>
                      that Customer or the applicable Administrative User,
                      Customer’s Client or Chat Participant does not have the
                      lawful right to send, upload, collect, transmit, store,
                      use, disclose, process, copy, transmit, distribute and
                      display;
                    </li>
                    <li>
                      that is false, intentionally misleading, or impersonates
                      any other person;
                    </li>
                    <li>
                      that is bullying, harassing, abusive, threatening, vulgar,
                      obscene, or offensive, or that contains pornography,
                      nudity, or graphic or gratuitous violence, or that
                      promotes violence, racism, discrimination, bigotry,
                      hatred, or physical harm of any kind against any group or
                      individual;
                    </li>
                    <li>
                      that is harmful to minors in any way or targeted at
                      persons under the age of 16;
                    </li>
                    <li>
                      that violates any applicable laws, or infringes, violates
                      or otherwise misappropriates the intellectual property or
                      other rights of any third party (including any moral
                      right, privacy right or right of publicity); or
                    </li>
                    <li>
                      that encourages any conduct that may violate, any
                      applicable laws or would give rise to civil or criminal
                      liability;
                    </li>
                    <li>
                      disable, overly burden, impair, or otherwise interfere
                      with servers or networks connected to the RAG TECH Platform
                      (e.g., a denial of service attack);
                    </li>
                    <li>
                      attempt to gain unauthorized access to the RAG TECH
                      Platform;
                    </li>
                    <li>
                      use any data mining, robots, or similar data gathering or
                      extraction methods, or copy, Modify, reverse engineer,
                      reverse assemble, disassemble, or decompile the RAG TECH
                      Solution or any part thereof or otherwise attempt to
                      discover any source code, except as expressly provided for
                      in this Agreement;
                    </li>
                    <li>
                      use the RAG TECH Solution for the purpose of building a
                      similar or competitive product or service; or
                    </li>
                    <li>
                      use the RAG TECH Solution other than as permitted by this
                      Agreement;
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>WhatsApp & Usage Policy</h3>
                  <p>
                    <div>
                      – The Customer shall adhere to all the terms & conditions
                      set by WhatsApp at all times. WhatsApp may update WhatsApp
                      Business Policy without notice; by continuing to use the
                      WhatsApp Business Products after such change, the Customer
                      consents to such changes.
                      <a href="https://www.whatsapp.com/legal/business-policy/" target="_blank" rel="noreferrer">https://www.whatsapp.com/legal/business-policy/</a>
                    </div>
                    <div>
                      – The Customer is not in violation of the WhatsApp Commerce
                      Policy and is not in any of the restricted industries.
                      <a href="https://www.whatsapp.com/legal/commerce-policy/" target="_blank" rel="noreferrer">https://www.whatsapp.com/legal/commerce-policy/</a>
                    </div>
                    <div>
                      – WhatsApp may add limits to businesses on the number of
                      messages to send per day. All Customers must adhere to this
                      Messaging Limit
                      (<a href="https://developers.facebook.com/docs/whatsapp/api/rate-limits" target="_blank" rel="noreferrer">https://developers.facebook.com/docs/whatsapp/api/rate-limits</a>)
                    </div>
                    <div>
                      – WhatsApp has the absolute discretion to review, approve or
                      reject any Message Templates (as defined in WhatsApp
                      documentations) at any time.
                    </div>
                    <div>
                      – Customer agrees to ensure full compliance with WhatsApp
                      Policies regarding sending of messages
                    </div>
                    <div>
                      – WhatsApp does not offer a way to be notified when a user
                      has blocked your sender, or to retrieve a list of users who
                      have blocked you.
                    </div>
                    <div>
                      – Any violation of these WhatsApp policies may lead to
                      suspension of the number by WhatsApp. WhatsApp has absolute
                      discretion to limit or remove Customer’s access to or use of
                      the WhatsApp Business Products if Customer receives
                      excessive negative feedback, causes harm to WhatsApp or
                      WhatsApp’s users, or violates or encourages others to
                      violate our terms or policies, as determined by WhatsApp in
                      our sole discretion. If WhatsApp terminates your account for
                      violations of relevant WhatsApp Business terms or policies,
                      WhatsApp may prohibit Customer and Customer organization
                      from all future use of WhatsApp products.
                    </div>
                    <div>
                      – RAG TECH shall take no responsibility in case of any such
                      violations. Any additional charges arising due to this shall
                      be borne by the Customer.
                    </div>
                    <div>
                      – Once registered on Wepos.ai, Customers cannot reuse the
                      WhatsApp number on WhatsApp Business App or WhatsApp mobile
                      app. The Customer owns the phone number.
                    </div>
                  </p>
                </div>
                <div>
                  <h3>WhatsApp Onboarding & Verification Terms</h3>
                  <p>
                    <div>
                      – WhatsApp imposes reviews, such as Facebook business
                      verification
                      (<a href="https://www.facebook.com/business/help/2058515294227817?id=180505742745347" target="_blank" rel="noreferrer">https://www.facebook.com/business/help/2058515294227817?id=180505742745347</a>) to ensure the quality of businesses using WhatsApp
                      products. These requirements are prerequisites to using
                      WhatsApp Business API and Wepos.ai. WhatsApp has absolute
                      discretion to approve the Customer’s usage of WhatsApp
                      products. RAG TECH will assist with the application and
                      submission process
                    </div>
                    <div>
                      – By using Wepos, clients agree to the setup for WABA and
                      WhatsApp business API and will provide all the prerequisite
                      documents as required by Whatsapp & Facebook.
                    </div>
                  </p>
                </div>
                <div>
                  <h3>WhatsApp Message Policy</h3>
                  <p>
                    <div>
                      – WhatsApp may change acceptable message types and related
                      policies at their absolute discretion at all times.
                    </div>
                    <div>
                      – The Customer may only initiate chats if you are sending a
                      transactional notification, and only via approved Message
                      Templates (as defined in WhatsApp documentation), subject to
                      applicable pricing. Any Message Templates must comply with
                      WhatsApp’s terms, and only be used for their designated
                      purpose. WhatsApp has the right to review, approve and
                      reject any Message Template at any time. — Customer
                      acknowledges that they are responsible for the variable
                      Message Template costs, billed by RAG TECH.
                    </div>
                    <div>
                      – If a person initiates a chat with a Customer, you may
                      continue that conversation via WhatsApp for up to 24 hours
                      after the last message sent to you by that person without
                      charge (“Customer Service Window”). Outside of the Customer
                      Service Window, Customer may only send messages via approved
                      Message Templates, for which RAG TECH will bill and charge
                      Customer the applicable rate.
                    </div>
                    <div>
                      – Customer may use automation when responding during the
                      Customer Service Window, but must also have available
                      prompt, clear, and direct escalation paths to human agents.
                    </div>
                  </p>
                </div>
                <div>
                  <h3>Email and Web Support</h3>
                  <p>
                    Customer will generally have access to RAG TECH’s technical
                    support from 10:00 am to 7:00 pm UTC (GMT+01:00) on weekdays
                    (other than holidays observed by RAG TECH) through email
                    support, or through the web site using our chat widget, or
                    by mailing us on <a href="mailto:support@wepos.ai">support@wepos.ai</a>
                  </p>
                </div>
                <div>
                  <h3>Fees and Payment</h3>
                  <p>
                    The customer will pay RAG TECH the applicable fees described
                    on the Wepos.ai Website (the “Fees”) during the Term in
                    accordance with the payment terms set out herein.
                  </p>
                </div>
                <div>
                  <h3>Third-Party Messaging Platform Fees.</h3>
                  <p>
                    For greater clarity, Wepos’s Fees do not include any charges
                    that may be assessed by Third Party Messaging Platforms for
                    access to or use of a channel. Such charges shall be the
                    responsibility of Customer, whether paid directly to the
                    Third Party Messaging Platforms or whether such access is
                    resold through RAG TECH, in which case RAG TECH shall advise
                    Customer in writing as to the applicable charges and
                    Customer shall have the right to accept such charges or
                    decline them and not use the associated channel. In the
                    event a Third Party Messaging Platforms imposes special
                    requirements on RAG TECH beyond API integrations, including
                    but not limited to hosting endpoints unique to that channel,
                    then RAG TECH shall have the right to charge Customer for
                    this additional service, and Customer shall have the right
                    to accept such charges or decline them and not use the
                    associated channel.
                  </p>
                </div>
                <div>
                  <h3>Payment Terms:</h3>
                  <p>
                    <div>– All Fees shall be in GBP British Pound;</div>
                    <div>
                      – The Fees start immediately after you make the payment. The
                      onboarding verification time is counted towards the
                      subscription.
                    </div>
                    <div>
                      – One-time onboarding fee for WhatsApp Green Checkmark
                      Verification is non-refundable. WhatsApp holds absolute
                      discretion whether to grant Green Checkmark, that they
                      verify Customer as an authentic, notable brand that owns the
                      WhatsApp account.
                      <a href="https://developers.facebook.com/docs/whatsapp/guides/display-name/" target="_blank" rel="noreferrer">https://developers.facebook.com/docs/whatsapp/guides/display-name/</a>
                    </div>
                    <div>
                      – RAG TECH shall charge and invoice the Customer applicable
                      Fees in accordance with our pricing schedule, in advance, by
                      credit card, on the Effective Date and on every anniversary
                      thereafter. Subscription Fees are charged on a pre-pay
                      basis. and usage Fees are charged on a post-pay basis at the
                      end of each month and will cover the use of the variable
                      messages fees for the previous month. All monthly & usage
                      payments are non-refundable.
                    </div>
                    <div>
                      – The customer is responsible for all charges incurred (for
                      example – international remittance, bank transfer, and
                      handling fees), and Wepos shall receive the total amount
                      stated in the invoice.
                    </div>
                    <div>
                      – There will be no refunds or credits for partial months of
                      service, upgrade/downgrade refunds, or refunds for months
                      unused with an open account. In order to treat everyone
                      equally, no exceptions will be made.
                    </div>
                    <div>
                      – Payment obligations hereunder are not subject to any
                      set-off or withholding rights whatsoever, any and all of
                      which are hereby expressly waived by Customer.
                    </div>
                    <div>
                      – <b>Disputed Invoices or Charges.</b> If Customer, in good
                      faith, disputes any portion of a RAG TECH invoice or charge,
                      Customer may provide a dispute notice to RAG TECH with
                      written documentation identifying and substantiating the
                      disputed amount within fifteen (15) days from receipt of the
                      applicable invoice or charge, and if applicable, at the time
                      it pays the undisputed portion of such invoice, withhold
                      payment of such disputed portion. If Customer does not
                      report or does not provide such substantiating documentation
                      within that period, Customer shall be deemed to have waived
                      its right to dispute any and all portions of that invoice.
                    </div>
                    <div>
                      – <b> Late Payment.</b> Except for bona fide disputed
                      amounts, any failure to make a payment of any Fees or taxes
                      in strict conformity with the requirements entitles RAG TECH
                      to suspend, in whole or in part, access to Services, until
                      such payment is received. Additionally, RAG TECH shall
                      assess, and Customer shall pay a charge, compounded monthly,
                      of the lesser of (a) 1.5% per month (19.56% per year) or (b)
                      the highest amount allowed by law on all past due amounts
                      (except amounts disputed). Furthermore, upon any such
                      failure, all outstanding amounts shall become due and
                      payable without further delay.
                    </div>
                    <div>
                      - Any invoice overdue for more than 30 days will result in
                      account suspension. The customer’s account will only be
                      activated after all overdue invoices are fully settled.
                    </div>
                    <div>
                      - Any cancellation of your Account will result in the
                      deactivation or deletion of your Account or your access to
                      your Account, and the forfeiture and relinquishment of all
                      Content in your Account. This information cannot be
                      recovered from RAG TECH once your account is cancelled.
                    </div>
                    <div>
                      – <b>Taxes</b>. The Fees set out in this Agreement do not
                      include applicable taxes, duties, withholdings, tariffs,
                      levies, customs, capital or income taxes or other
                      governmental charges or expenses, including but not limited
                      to value-added tax, sales tax, consumption tax and similar
                      taxes or duties as well as any current or future municipal,
                      state, federal or provincial taxes, and Customer will pay,
                      indemnify and hold harmless RAG TECH from same, other than
                      taxes based on the net income or profits of RAG TECH.
                    </div>
                  </p>
                </div>
                <div>
                  <h3> Limitation of Liabilities</h3>
                  <p>
                    The Parties acknowledge that the following provisions have
                    been negotiated by them and reflect a fair allocation of
                    risk and form an essential basis of the bargain and will
                    survive and continue in full force and effect despite any
                    failure of consideration or of an exclusive remedy:
                  </p>
                  <p>
                    AMOUNT. IN NO EVENT WILL THE TOTAL AGGREGATE LIABILITY OF
                    RAG TECH IN CONNECTION WITH OR UNDER THIS AGREEMENT EXCEED
                    THE AMOUNT OF FEES PAID BY CUSTOMER IN THE PRIOR 12 MONTH
                    PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE
                    CLAIM OR £500GBP, WHICHEVER IS THE LESSER. FOR GREATER
                    CERTAINTY, THE EXISTENCE OF ONE OR MORE CLAIMS UNDER THIS
                    AGREEMENT WILL NOT INCREASE THIS MAXIMUM LIABILITY AMOUNT.
                    IN NO EVENT WILL RAG TECH’S THIRD PARTY SUPPLIERS HAVE ANY
                    LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS
                    AGREEMENT. TYPE. TO THE MAXIMUM EXTENT PERMITTED UNDER
                    APPLICABLE LAW, IN NO EVENT WILL RAG TECH BE LIABLE TO
                    CUSTOMER OR ANY USER FOR ANY: (I) SPECIAL, EXEMPLARY,
                    PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                    (II) LOST SAVINGS, PROFIT, DATA, USE, OR GOODWILL; (III)
                    BUSINESS INTERRUPTION; (IV) ANY COSTS FOR THE PROCUREMENT OF
                    SUBSTITUTE PRODUCTS OR SERVICES; (V) PERSONAL INJURY OR
                    DEATH; OR (VI) PERSONAL OR PROPERTY DAMAGE ARISING OUT OF OR
                    IN ANY WAY CONNECTED TO THIS AGREEMENT, REGARDLESS OF CAUSE
                    OF ACTION OR THE THEORY OF LIABILITY, WHETHER IN CONTRACT,
                    TORT (INCLUDING NEGLIGENCE, GROSS NEGLIGENCE, FUNDAMENTAL
                    BREACH, BREACH OF A FUNDAMENTAL TERM) OR OTHERWISE AND EVEN
                    IF NOTIFIED IN ADVANCE OF THE POSSIBILITIES OF SUCH DAMAGES.
                  </p>
                </div>
                <div>
                  <h3>Term and Termination</h3>
                  <p>
                    RAG TECH makes no warranties regarding (i) your ability to
                    use the Service, (ii) your satisfaction with the Service,
                    (iii) that the Service will be available at all times,
                    uninterrupted, and error-free (iv), the accuracy of
                    mathematical calculations performed by the Service, and (v)
                    that bugs or errors in the Service will be corrected. RAG
                    TECH and its affiliates are neither responsible nor liable
                    for any direct, indirect, incidental, consequential,
                    special, exemplary, punitive or other damages arising out of
                    or relating in any way to your use of the Service. Your sole
                    remedy for dissatisfaction with the Service is to stop using
                    the Service.
                  </p>
                  <p>
                    Term. This Agreement will commence on the Effective Date and
                    continue to be in effect until your subscription has been
                    terminated in accordance with the terms Customer agreed to
                    on the RAG TECH website at the time of signup (the “Term”).
                    For greater certainty, if the Customer has subscribed to an
                    ongoing subscription, then the such subscription will
                    automatically renew at its expiry for the same period of
                    time, at the then-current subscription rate described on the
                    RAG TECH Website.
                  </p>
                </div>
                <div>
                  <h3>Termination For Convenience.</h3>
                  <p>
                    Either Party may elect to terminate this Agreement and your
                    subscription to RAG TECH services as of the end of your
                    then-current Term by providing notice, on or prior to the
                    date thirty (30) days preceding the end of such Term. For
                    clarity, unless this Agreement and your subscription are so
                    terminated, your subscription will renew for a Term
                    equivalent in length to the then-expiring Term.
                  </p>
                  <p>
                    The customer is responsible for properly cancelling your
                    Account. An email request to cancel your Account shall
                    result in cancellation. Any cancellation of your Account
                    will result in the deactivation or deletion of your Account
                    or your access to your Account, and the forfeiture and
                    relinquishment of all Content in your Account. This
                    information cannot be recovered from RAG TECH once your
                    account is cancelled. After terminating a customer account,
                    there will be a 90-day grace period where the customer can
                    reactivate the account. After 90 days, the account deletion
                    process will begin, and the customer will have to purchase a
                    new account and cannot retrieve any of their existing Wepos
                    account information. Please be aware of that. You can cancel
                    at any time, but you will remain liable for all charges
                    accrued up to that time, including full monthly charges for
                    the month in which you discontinued service. You will not be
                    charged again.
                  </p>
                  <p>
                    RAG TECH will use all reasonable efforts to contact you
                    directly via email to warn you prior to the suspension or
                    termination of your account. Any suspected fraudulent,
                    abusive, or illegal activity that may be grounds for
                    termination of your use of Service may be referred to
                    appropriate law enforcement authorities. RAG TECH shall not
                    be liable to You or any third party for any modification,
                    suspension or discontinuation of the Service.
                  </p>
                </div>
                <div>
                  <h3>Termination For Non-payment.</h3>
                  <p>
                    RAG TECH reserves the right to suspend Services if the
                    Customer fails to timely pay any undisputed amounts due to
                    RAG TECH under this Agreement, but only after RAG TECH
                    notifies Customer of such failure and such failure continues
                    for thirty (30) calendar days after such notification.
                    Suspension of Services shall not release Customer of its
                    payment obligations under this Agreement. Customer agrees
                    that RAG TECH shall not be liable to Customer or to any
                    third party for any liabilities, claims or expenses arising
                    from or relating to suspension of the Services resulting
                    from Customer’s non-payment unless RAG TECH suspends the
                    Services without giving Customer 30 calendar days written
                    notice to rectify its failure to pay. Termination will
                    result in the deactivation or deletion of your Account or
                    your access to your Account, and the forfeiture and
                    relinquishment of all Content in your Account. This
                    information cannot be recovered from RAG TECH once your
                    account is terminated. Please be aware of that.
                  </p>
                  <p>
                    <b>Refunds and Termination Charges.</b> No refunds or
                    credits for Fees will be provided if you elect to terminate
                    this Agreement prior to the end of your Term. If you
                    terminate this Agreement prior to the end of your Term, or
                    RAG TECH effects such termination, in addition to other
                    amounts you may owe RAG TECH, you must immediately pay any
                    then unpaid Fees associated with the remainder of your Term.
                    This amount will not be payable by you in the event you
                    terminate as a result of a material breach of this Agreement
                    by RAG TECH, provided that you provide advance notice of a
                    such breach to RAG TECH and afford RAG TECH not less than
                    thirty (30) days to reasonably cure such breach.
                  </p>
                  <p>
                    <b>Termination for Cause.</b> A Party may terminate this
                    Agreement for cause (a) upon thirty (30) days written notice
                    to the other Party of a material breach if such breach
                    remains uncured at the expiration of such period; or (b) if
                    the other Party becomes the subject of a petition in
                    bankruptcy or any other proceeding relating to insolvency,
                    receivership, liquidation or assignment for the benefit of
                    creditors. If this Agreement is terminated by you in
                    accordance with this section, RAG TECH will, to the extent
                    permitted by applicable law, refund you any prepaid fees
                    covering the remainder of the Term after the effective date
                    of termination. If this Agreement is terminated by RAG TECH
                    in accordance with this section, you will pay any unpaid
                    fees covering the remainder of the Term. In no event will
                    termination relieve you of your obligation to pay any fees
                    payable to RAG TECH for the period prior to the effective
                    date of termination.
                  </p>
                </div>
              </section>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
