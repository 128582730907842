export function tagsListDTO(data) {
  let tags = [];

  if (data?.length) {
    tags = data.map((tag) => ({
      id: tag?.id || 0,
      tagName: tag?.tagName || null,
      customers: tag?.customers?.length ? tag?.customers?.map(customer => ({
        id: customer?.id || 0,
        name: customer?.name || '',
        whatsappNumber: customer?.whatsappNumber || '',
      })) : [],
    }))
  }

  return tags;
}