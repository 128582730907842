import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useSelector } from 'react-redux'
import { removeEmptyKeysFromObject, setSelectedDropdownForSameValue, trimObjectValues } from '../../../../services/middleware';
import { orderValidationSchema } from '../../../../constants/Schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { CountryCode, selectDropdownStyle } from '../../../../constants/General';
import { editOrder, prefillData, } from '../../utils/middleware/orders';
import CreatableSelect from 'react-select/creatable';

export default function EditOrderModal(props) {
  // Props
  const { productsByCategory, order, handleCloseEditModal } = props

  // Store
  const { customers, tables, search, startDate, endDate, activeSort, sortOrder, page } = useSelector(state => state.restaurantAdminOrders)
  const { restaurant } = useSelector(state => state.restaurantAdmin)

  // State
  const [subTotal, setSubTotal] = useState(0)
  const [items, setItems] = useState(prefillData(order?.items, productsByCategory))

  const formik = useFormik({
    initialValues: {
      tableNumber: setSelectedDropdownForSameValue(order?.tableNumber),
      customers: order?.customers?.length ? order?.customers.map(customer => ({ label: customer?.phone, value: customer?.phone })) : [],
      items: prefillData(order?.items, productsByCategory) || []
    },
    validationSchema: orderValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      values['customers'] = values.customers.map(customer => (customer.value.length === 12 ? customer.value : `${CountryCode[restaurant.country]}${customer.value}`))
      values['tableNumber'] = values?.tableNumber?.value || ''
      const filterData = removeEmptyKeysFromObject(values)
      editOrder({ id: order?.orderId, filterData, search, startDate, endDate, activeSort, sortOrder, page, handleCloseEditModal })
    }
  });

  useEffect(() => {
    let totalCost = 0;
    let selectedProducts = []
    Object.keys(items)?.length !== 0 && Object.keys(items).forEach(item => {
      if (items[item].productRetailerId && items[item].quantity) {
        const { category, ...newObj } = items[item];
        totalCost += (parseInt(items[item].quantity) * items[item].price);
        selectedProducts.push(newObj)
      }
    })
    setSubTotal(totalCost)
    formik.setFieldValue('items', selectedProducts)
  }, [items]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateQuantity = (item, value) => {
    setItems(obj => ({ ...obj, [item]: { ...obj[item], quantity: value } }))
  }

  return (
    <Card className="section-primary shadow">
      <CardBody>
        <CardTitle tag="h3">
          Edit Order
        </CardTitle>
        <Form role="form">
          <Row>
            <Col md={6}>
              <Label className='inputfield required-field'>
                Table Number
              </Label>
              <section className="mb-3 input-group-alternative">
                <CreatableSelect value={formik.values.tableNumber} styles={selectDropdownStyle} onChange={selectedOption => formik.setFieldValue("tableNumber", selectedOption)} options={tables} className='custom-select' />
                {formik.touched?.tableNumber && formik.errors.tableNumber && <span className="error-message input-group-alternative">{formik.errors.tableNumber}</span>}
              </section>
            </Col>
            <Col md={6}>
              <Label className='inputfield'>
                Customers
              </Label>
              <section className="mb-3 input-group-alternative">
                <CreatableSelect isMulti value={formik.values.customers} styles={selectDropdownStyle} onChange={selectedOption => formik.setFieldValue("customers", selectedOption)} options={customers} className='custom-select' />
                {!!formik.errors?.customers?.length && Array.isArray(formik.errors?.customers) ? <span className="error-message input-group-alternative">{formik.errors.customers.map((customer, index) => (`customer ${index + 1} ${customer?.value}`)).join(",")}</span>
                  : formik.errors.customers && <span className="error-message input-group-alternative">{formik.errors.customers}</span>}
              </section>
            </Col>
          </Row>
          {Object.keys(items)?.length !== 0 && Object.keys(items).map((item, index) => {
            return <Row key={index}>
              <Col md={4}>
                <Label className='inputfield'>
                  Sub Category
                </Label>
                <section className="mb-3 input-group-alternative">
                  <Select className="custom-select" value={items[item].category} styles={selectDropdownStyle}
                    onChange={selectedOption => setItems(obj => ({ ...obj, [item]: { ...obj[item], category: selectedOption, name: '', productRetailerId: '' } }))}
                    options={Object.keys(productsByCategory).map(category => ({ label: category, value: category }))} />
                </section>
              </Col>
              <Col md={4}>
                <Label className='inputfield'>
                  Product
                </Label>
                <section className="mb-3 input-group-alternative">
                  <Select className={`custom-select ${!items[index]?.category && 'dropdown-disabled'}`} isDisabled={!items[index]?.category} value={{ label: items[item].name, value: items[item].productRetailerId }} styles={selectDropdownStyle}
                    onChange={selectedOption => {
                      setItems(obj => ({ ...obj, [item]: { ...obj[item], name: selectedOption.label, productRetailerId: selectedOption.value, price: selectedOption.price } }))
                    }}
                    options={productsByCategory[items[item].category.value]} />
                  {formik.errors.items && typeof formik.errors.items === 'string' && <span className="error-message input-group-alternative">{formik.errors.items}</span>}
                </section>
              </Col>
              <Col md={4}>
                <div className='flex align-items-end mb-3'>
                  <div className='mr-3'>
                    <Label className='inputfield required-field'>
                      Quantity
                    </Label>
                    <div className='center-div qty-wrap'>
                      <Button className='minus-btn' disabled={!items[index]?.quantity || !items[index]?.productRetailerId} onClick={() => handleUpdateQuantity(item, Number(items[index]?.quantity) - 1)}>
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                      <Input
                        className="form-control text-center"
                        type="number"
                        value={items[index]?.quantity}
                        disabled={!items[index]?.productRetailerId}
                        onChange={(e) => setItems(obj => ({ ...obj, [item]: { ...obj[item], quantity: e.target.value } }))}
                        invalid={Array.isArray(formik?.errors?.items) && !!formik?.errors?.items?.length}
                      />
                      <Button className="plus-btn" disabled={!items[index]?.productRetailerId} onClick={() => handleUpdateQuantity(item, Number(items[index]?.quantity) + 1)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                    <FormFeedback>
                      {formik?.errors?.items?.[index]?.quantity || ''}
                    </FormFeedback>
                  </div>
                  {index === Object.keys(items)?.length - 1 ? <Button color='primary' disabled={formik.errors.items} type="button" onClick={() =>
                    setItems(obj => ({
                      ...obj, [index + 1]: {
                        name: '',
                        category: '',
                        quantity: 0,
                        price: 0,
                        productRetailerId: ''
                      }
                    }))
                  }>
                    <FontAwesomeIcon icon={faPlus} />
                  </Button> : <Button color='primary' title='Remove' disabled={formik.errors.items} type="button" onClick={() => {
                    let newMyObj = {}
                    delete items[index]
                    let key = 0
                    for (const oldKey in items) {
                      newMyObj[key] = items[oldKey];
                      key++;
                    }
                    setItems(newMyObj)
                  }
                  }>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  }
                </div>
              </Col>
            </Row>
          })}
          <Row>
            <Col md={4}>
              <InputField disabled placeholder="Sub Total" value={subTotal} />
            </Col>
            <Col md={4}>
              <InputField disabled placeholder="VAT" value={subTotal && restaurant?.vat && (subTotal * restaurant?.vat * 0.01).toFixed(2)} />
            </Col>
            <Col md={4}>
              <InputField disabled placeholder="Total" value={subTotal && restaurant?.vat && (subTotal + (subTotal * restaurant?.vat * 0.01))?.toFixed(2)} />
            </Col>
          </Row>
          <div className="text-center">
            <Button color='outline-primary' className="mt-4 mr-2" type="button" onClick={() => handleCloseEditModal()}>
              Back to Orders
            </Button>
            <Button color='primary' className="mt-4" type="button" onClick={formik.handleSubmit}>
              Update
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}
