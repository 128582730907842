export function couponsListDTO(data) {
  let coupons = [];

  if (data?.length) {
    coupons = data.map((coupon) => ({
      id: coupon?.id || 0,
      restaurantId: coupon?.restaurantId || null,
      description: coupon?.description || null,
      couponCode: coupon?.couponCode || null,
      startDate: coupon?.startDate || null,
      endDate: coupon?.endDate || null,
      type: coupon?.type || null,
      value: coupon?.value || null,
    }))
  }

  return coupons;
}