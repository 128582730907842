import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { faCalendar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Col, Form, FormFeedback, InputGroup, InputGroupText, Label, Modal, Row } from "reactstrap";
import InputField from '../../../../components/InputField';
import { editOfferValidationSchema } from '../../../../constants/Schemas';
import { addProductOffer, selectedProductPayload } from '../../utils/middleware/offers';
import { DiscountType, selectDropdownStyle } from '../../../../constants/General';
import { generateDropdownOptionFromObject, setSelectedDropdownValue, trimObjectValues } from '../../../../services/middleware';

export default function EditOfferModal(props) {
  // Props
  const { open, product, handleCloseModal } = props

  // Store
  const { search, activeSort, sortOrder, page } = useSelector(state => state.restaurantAdminOffers)

  const formik = useFormik({
    initialValues: {
      description: product?.description,
      discountType: product?.discountType ? setSelectedDropdownValue(product?.discountType, DiscountType) : '',
      discountValue: product?.discountValue || '',
      price: product?.price,
      saleStart: product?.saleStart ? new Date(product?.saleStart) : '',
      saleEnd: product?.saleEnd ? new Date(product?.saleEnd) : '',
    },
    validationSchema: editOfferValidationSchema,
    onSubmit: (values) => {
      trimObjectValues(values)
      addProductOffer({
        ...selectedProductPayload(product),
        offerOnCategory: false,
        ...(values?.description && { description: values.description }),
        discountValue: parseInt(values.discountValue),
        discountType: values.discountType?.value,
        salePrice: formik.values.discountType?.label === DiscountType.percentage ? (product?.price - product?.price * values.discountValue / 100).toString() : product?.price - values.discountValue,
        saleStart: moment(values.saleStart).utc().format(),
        saleEnd: moment(values.saleEnd).utc().format(),
      }, search, activeSort, sortOrder, page, handleCloseModal)
    }
  });

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Edit Offer
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
            <Form role="form">
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Sale Start Date
                  </Label>
                  <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                    <Datetime dateFormat={'DD-MM-YYYY'} onChange={(value) => {
                      if (moment(value).isSame(moment(formik.values.saleEnd)) || moment(value).isAfter(moment(formik.values.saleEnd))) {
                        formik.setFieldValue("saleEnd", moment(value).add(1, 'hour'))
                      }
                      formik.setFieldValue("saleStart", value)
                    }} value={formik.values.saleStart} isValidDate={(currentDate) => currentDate.isAfter(moment().subtract(1, 'day').format())} />
                    <FormFeedback>
                      {formik.errors?.saleStart}
                    </FormFeedback>
                  </InputGroup>
                </Col>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Sale End Date
                  </Label>
                  <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faCalendar} />
                    </InputGroupText>
                    <Datetime dateFormat={'DD-MM-YYYY'} onChange={(value) => {
                      if (moment(formik.values.saleStart).isSame(moment(value)) || moment(formik.values.saleStart).isAfter(moment(value))) {
                        formik.setFieldValue("saleEnd", moment(formik.values.saleStart).add(1, 'hour'))
                      } else {
                        formik.setFieldValue("saleEnd", value)
                      }
                    }} value={formik.values.saleEnd} isValidDate={(currentDate) => currentDate.isAfter(moment(formik.values.saleStart).subtract(1, 'day').format())} />
                    <FormFeedback>
                      {formik.errors?.saleEnd}
                    </FormFeedback>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Discount Type
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" value={formik.values.discountType} isSearchable={false} styles={selectDropdownStyle} onChange={selectedOption => formik.setFieldValue("discountType", selectedOption)} options={generateDropdownOptionFromObject(DiscountType)} />
                  </section>
                </Col>
                <Col md={4}>
                  <InputField disabled placeholder="Price" fieldName="price" formik={formik} />
                </Col>
                <Col md={4}>
                  <InputField required disabled={!formik.values.discountType} placeholder="Discount" fieldName="discountValue" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField placeholder="Description" fieldName="description" inputType="textarea" rows={3} formik={formik} />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}
