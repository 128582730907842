import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment';
import { Card, CardHeader, Media, Table, Row, Button, } from "reactstrap";
import { addCoupon, deleteCoupon, editCoupons, getCoupons } from '../../utils/middleware/coupons'
import { dispatch, dispatchNoPayload } from '../../../../utils/store'
import { Actions } from '../../../../redux/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { handleSortColumn, sortIcon } from '../../../../services/middleware';
import NoDataLabel from '../../../../components/NoDataLabel';
import CustomPagination from '../../../../components/Pagination';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import CouponModal from '../../components/CouponModal';
import SearchInput from '../../../../components/SearchInput';
import { RoutesActions } from '../../../../constants/General';

export default function Coupons() {
  // Store
  const { coupons, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminCoupons)
  const { pageSize } = useSelector(state => state.user)
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedCoupon, setSselectedCoupon] = useState(null)

  // Mount
  useEffect(() => {
    getCoupons(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminCoupons.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize])

  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSselectedCoupon(null)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSselectedCoupon(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSselectedCoupon(null)
  }

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminCoupons.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Coupons List</h3>
                <div className='right-div-wrap'>
                  <SearchInput action={RoutesActions.coupons} />
                  <Button color='primary' onClick={() => setShowAddModal(true)}>
                    Add
                  </Button>
                </div>

              </CardHeader>
              {coupons?.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'coupons')}>No.</th> */}
                    <th scope='col' className={`${activeSort === 'couponCode' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('couponCode', activeSort, sortOrder, 'coupons')}>Coupon Code <FontAwesomeIcon icon={sortIcon(activeSort, 'couponCode', sortOrder)} /></th>
                    <th scope='col' >Discount</th>
                    <th scope='col' className={`${activeSort === 'startDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('startDate', activeSort, sortOrder, 'coupons')}>Start Date <FontAwesomeIcon icon={sortIcon(activeSort, 'startDate', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'endDate' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('endDate', activeSort, sortOrder, 'coupons')}>End Date <FontAwesomeIcon icon={sortIcon(activeSort, 'endDate', sortOrder)} /></th>
                    <th scope='col' className={`${activeSort === 'description' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('description', activeSort, sortOrder, 'coupons')}>Description <FontAwesomeIcon icon={sortIcon(activeSort, 'description', sortOrder)} /></th>
                    <th scope='col' className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {coupons.map((coupon, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {coupon.couponCode}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{coupon?.value ? coupon?.type === 'percentage' ? `${coupon?.value}%` : `${settings?.superAdminSetting?.currencySign}${coupon?.value}` : ''}</td>
                      <td>{coupon.startDate ? moment(coupon.startDate).format('DD-MM-YYYY hh:mm A') : ''}</td>
                      <td>{coupon.endDate ? moment(coupon.endDate).format('DD-MM-YYYY hh:mm A') : ''}</td>
                      <td className='ellipsis-column' title={coupon.description}>{coupon.description}</td>
                      <td className="text-right">
                        <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                          setSselectedCoupon(coupon)
                          setShowEditModal(true)
                        }}>
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                          setSselectedCoupon(coupon)
                          setShowDeleteModal(true)
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminCoupons.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteCoupon(selectedCoupon?.id, search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showAddModal && <CouponModal open={showAddModal} handleCloseModal={handleCloseAddModal} handleSuccess={(data) => addCoupon(data, search, activeSort, sortOrder, page, handleCloseAddModal)} />}
      {showEditModal && <CouponModal open={showEditModal} coupon={selectedCoupon} handleCloseModal={handleCloseEditModal} handleSuccess={(data) => editCoupons(selectedCoupon?.id, data, search, activeSort, sortOrder, page, handleCloseEditModal)} />}
    </div >
  )
}
