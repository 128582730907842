import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { CHATAUTOMATION_NODE_API, CHATAUTOMATION_EDGE_API, CHATAUTOMATION_GET_NODE_API, CHATAUTOMATION_GET_EDGE_API, UPLOAD_IMAGE_API } from "../../../../constants/Configs/index";
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"

export const getChatAutomation = (id) => {
  return new Promise((resolve, reject) => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    const promiseArray = [];
    promiseArray.push(API(API_REQUEST.get, `${CHATAUTOMATION_GET_NODE_API}/${id}`));
    promiseArray.push(API(API_REQUEST.get, `${CHATAUTOMATION_GET_EDGE_API}/${id}`));
    Promise.all(promiseArray)
      .then((res) => {
        console.log(res);
        resolve(res)
        handleSuccess(res)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch((error) => {
        handleError(error);
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  })
}

export const addNodeChatAutomation = (data) => {
  // dispatch(Actions.RestaurantAdmin.SetLoading, true)
  if(data.type === "textnode"){    
    data.data.nodePDF = data.data.pdf;
    data.data.nodeImages = data.data.file;
    data.data.nodeVideo = data.data.video;
  }
  const promiseArray = [];
  data.nodeId = data.id;
  delete data.id;
  delete data.positionAbsolute;  
  console.log(data)
  promiseArray.push(API(API_REQUEST.post, CHATAUTOMATION_NODE_API, data));
  // data.forEach(element => {
  // });
  Promise.all(promiseArray)
    .then((res) => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addEdgeChatAutomation = (data) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  console.log('addEdgeChatAutomation', data)
  const promiseArray = [];
  // data.forEach(element => {
  // });
  data.edgeId = data.id;
  delete data.id;
  promiseArray.push(API(API_REQUEST.post, CHATAUTOMATION_EDGE_API, data));
  Promise.all(promiseArray)
    .then((res) => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateNodeChatAutomation = (data) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const promiseArray = [];
  // data.forEach(element => {
    // });
  if(data.type === "textnode"){    
    data.data.nodePDF = data.data.pdf;
    data.data.nodeImages = data.data.file;
    data.data.nodeVideo = data.data.video;
  }
  let nodeId = data.nodeId
  data.nodeId = data.id;
  delete data.id;
  delete data.positionAbsolute;
  delete data.createdAt;
  delete data.deletedAt;
  delete data.dragging;
  delete data.updatedAt;   
  promiseArray.push(API(API_REQUEST.put, `${CHATAUTOMATION_NODE_API}/${nodeId}`, data));
  Promise.all(promiseArray)
    .then((res) => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateEdgeChatAutomation = (data) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const promiseArray = [];
  // data.forEach(element => {
  // });  

  var edgeId = data.edgeId
  data.edgeId = data.id;
  delete data.id;
  delete data.createdAt;
  delete data.deletedAt;
  delete data.type;
  delete data.updatedAt;
  promiseArray.push(API(API_REQUEST.put, `${CHATAUTOMATION_EDGE_API}/${edgeId}`, data));
  Promise.all(promiseArray)
    .then((res) => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editChatAutomation = (id, data, search, activeSort, sortOrder, page, handleCloseEditModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, data)
    .then(res => {
      handleSuccess(res)
      handleCloseEditModal()

    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteChatAutomation = (id, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete,)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal(false)

    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteNodeChatAutomation = (id) => {
  return new Promise((resolve, reject) => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    const promiseArray = [];
    promiseArray.push(API(API_REQUEST.delete, `${CHATAUTOMATION_NODE_API}/${id}`));
    Promise.all(promiseArray)
      .then((res) => {
        resolve(res)
        handleSuccess(res)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch((error) => {
        handleError(error);
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  })
}

export const deleteEdgeChatAutomation = (data) => {
  return new Promise((resolve, reject) => {
    dispatch(Actions.RestaurantAdmin.SetLoading, true)
    const promiseArray = [];
    promiseArray.push(API(API_REQUEST.delete, `${CHATAUTOMATION_EDGE_API}/${data}`));
    Promise.all(promiseArray)
      .then((res) => {
        resolve(res)
        handleSuccess(res)
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
      .catch((error) => {
        handleError(error);
        dispatch(Actions.RestaurantAdmin.SetLoading, false)
      })
  })
}

export const uploadNodeImage = async (image, imageType, isObject = false) => {
  try {

    var payload = new FormData();
  
    console.log(image)
    if (isObject) {
      if (Array.isArray(image) && image?.length) {
        image.forEach((img, index) => {
          // payload.append(`file`, img.fileUrl || img.name);
          payload.append(`fileName`, img);          
        })
      } else {
        // payload.append(`file1`, image.fileUrl, image.name);
        payload.append(`fileName`, image);
      }
      payload.append('allowMultiple', true);
    } else {
      payload.append('file', image)
    }
    if (imageType) payload.append('fileCategory', imageType);
    
    return API(API_REQUEST.post, UPLOAD_IMAGE_API, payload) .then((res) => { 
      handleSuccess(res)     
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return res || []
    })
    .catch(err => {      
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return []
    })
  } catch(err){
    console.log(err)
  }
}
