import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Form, Modal, Row, Col, Label } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { editCMS } from '../../utils/middleware/cms';
import { addRestaurantInputFields, Country, CountryCode, ImageType, multiSelectDropdownStyle, selectDropdownStyle, serviceThrough, UK_States } from '../../../../constants/General';
import InputField from '../../../../components/InputField';
import { addRestaurantValidationSchema, } from '../../../../constants/Schemas';
import { dispatch } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import { handleError } from '../../../../utils/toast';
import { generateDropdownOptionForStates, generateDropdownOptionFromObject, removeEmptyKeysFromObject, trimObjectValues, uploadImage } from '../../../../services/middleware';
import UploadLogo from '../../../../components/UpdateLogo';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';

export default function Editcms(props) {
  const { search, activeSort, sortOrder, page, cmsCategoreis } = useSelector(state => state.superAdminCMS)
  // Props
  const {cms, open, handleCloseModal } = props
  const [editor, setEditor] = useState(null);
  const [editorFill, setEditorFill] = useState(true);
  const [showMetaForm, setShowMetaForm] = useState(false);
  const [cmsCate, setCmsCate] = useState({})

  // Meta tag states
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [metaRobots, setMetaRobots] = useState('index, follow');

  useEffect(() => {
    let newEditor;
    setPageTitle(cms.title);
    const cateObj = {value: cms.categoryid, label: cms.category}
    setCmsCate(cateObj);
    // Clear previous editor instance (if any) and create a new one
    const initEditor = () => {
      newEditor = grapesjs.init({
        container: '#gjs',
        fromElement: true,
        height: '100%',
        width: 'auto',
        storageManager: false,
        blockManager: {
          appendTo: '#blocks',
          blocks: [
            {
              id: '1-column',
              label: '<i class="fa fa-square fa-3x"></i>',  // Increased icon size
              content: '<div class="row"><div class="col-12" style="min-height: 100px; border: 1px solid #000;"></div></div>',
              category: 'Basic',
            },
            {
              id: '2-columns',
              label: '<i class="fa fa-columns fa-3x"></i>',  // Increased icon size
              content: '<div class="row"><div class="col-6" style="min-height: 100px; border: 1px solid #000;"></div><div class="col-6" style="min-height: 100px; border: 1px solid #000;"></div></div>',
              category: 'Basic',
            },
            {
              id: '3-columns',
              label: '<i class="fa fa-th-large fa-3x"></i>',  // Increased icon size
              content: '<div class="row"><div class="col-4" style="min-height: 100px; border: 1px solid #000;"></div><div class="col-4" style="min-height: 100px; border: 1px solid #000;"></div><div class="col-4" style="min-height: 100px; border: 1px solid #000;"></div></div>',
              category: 'Basic',
            },
            {
              id: 'text',
              label: '<i class="fa fa-font fa-3x"></i>',  // Increased icon size
              content: '<div class="custom-text">Insert your text here</div>',
              category: 'Basic',
            },
            {
              id: 'link',
              label: '<i class="fa fa-link fa-3x"></i>',  // Increased icon size
              content: '<a href="#">This is a link</a>',
              category: 'Basic',
            },
            {
              id: 'image',
              label: '<i class="fa fa-image fa-3x"></i>',  // Increased icon size
              content: '<img src="https://via.placeholder.com/350x150" alt="Image" />',
              category: 'Media',
            },
            {
              id: 'video',
              label: '<i class="fa fa-video-camera fa-3x"></i>',  // Increased icon size
              content: '<video controls><source src="movie.mp4" type="video/mp4">Your browser does not support the video tag.</video>',
              category: 'Media',
            },
            {
              id: 'map',
              label: '<i class="fa fa-map fa-3x"></i>',  // Increased icon size
              content: '<div class="map">Map placeholder</div>',
              category: 'Basic',
            },
            {
              id: 'text-section',
              label: '<i class="fa fa-align-left fa-3x"></i>',  // Icon for text section
              content: `
                <section style="padding: 20px; background-color: #f4f4f4; border: 1px solid #ddd;">
                  <h2 style="text-align: center;">This is a text section</h2>
                  <p style="text-align: justify;">
                    This is an editable text section. You can click and edit the text, change the style, or modify the layout. Use this section to add detailed information or descriptions to your page.
                  </p>
                </section>
              `,
              category: 'Basic',
            },
          ],
        },
      });
      setEditor(newEditor);
      // Set HTML content
      newEditor.setComponents(cms.content);
      // Set CSS content
      newEditor.setStyle(cms.contentstyle);
    };

    setEditor(null);  // Reset editor before re-initializing
    const timer = setTimeout(initEditor, 1000);

    // Cleanup the editor on component unmount
    return () => {
      if (newEditor) {
        newEditor.destroy();
        newEditor = null;
      }
      clearTimeout(timer);  // Clean up the timer if the component unmounts before the editor is initialized
    };
  }, []);

  // Meta tag form UI
  const renderMetaTagForm = () => (
    <div style={{ padding: '20px' }}>
      {/* <h1>Meta Tag Information</h1> */}
      <div className="form-group">
        <label>Description:</label>
        <input
          type="text"
          className="form-control"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
          placeholder="Enter page description"
        />
      </div>
      <div className="form-group">
        <label>Keywords:</label>
        <input
          type="text"
          className="form-control"
          value={metaKeywords}
          onChange={(e) => setMetaKeywords(e.target.value)}
          placeholder="Enter page keywords"
        />
      </div>
      <div className="form-group">
        <label>Robots:</label>
        <select
          className="form-control"
          value={metaRobots}
          onChange={(e) => setMetaRobots(e.target.value)}
        >
          <option value="index, follow">Index, Follow</option>
          <option value="noindex, nofollow">Noindex, Nofollow</option>
        </select>
      </div>
    </div>
  );

  const handleSave = () => {
    if (editor) {
      const html = editor.getHtml();
      const css = editor.getCss();

       // Constructing meta tags dynamically based on user input
       const metaTags = `
       <meta name="description" content="${metaDescription}">
       <meta name="keywords" content="${metaKeywords}">
       <meta name="robots" content="${metaRobots}">
       <meta name="viewport" content="width=device-width, initial-scale=1.0">
       <meta property="og:description" content="${metaDescription}" />
       <meta property="og:type" content="website" />
     `;

      const payload = {
        "id":cms.id,
        "title":pageTitle,
        "content": html,
        "contentstyle": css,
        "contentmeta": metaTags,
        "title":pageTitle,
        "categoryId": cmsCate.value
      }

      editCMS(cms.id, payload, search, activeSort, sortOrder, page);
      handleCloseModal();
      // Uncomment below to send data to your backend
      // saveToDatabase({ html, css });
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={handleCloseModal}
      style={{maxWidth:"95vw"}}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Edit CMS
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div>
        <div style={{ marginTop: "20px",marginLeft:"24px", display:'flex', alignItems:'center' }}>
          <Button
            style={{ cursor: "pointer", width:'335px'}}
            color="outline-primary"
            type="button"
            onClick={() => setShowMetaForm(!showMetaForm)}>
            Add Meta Tag Information here
          </Button>
          <input
            type="text"
            className="form-control"
            value={pageTitle}
            style={{  width:'335px'}}
            onChange={(e) => setPageTitle(e.target.value)}
            placeholder="Enter page title"
          />
          <section className="ml-2 input-group-alternative">
            <Select className="custom-select" options={cmsCategoreis} value={cmsCate} isSearchable={false} styles={selectDropdownStyle}
              onChange={selectedOption => {
                setCmsCate(selectedOption)
              }}
            />            
          </section>
        </div>
        {showMetaForm && renderMetaTagForm()}
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
          <div style={{ display: 'flex', height: '100vh', position: 'relative' }}>
        {/* Block Manager Panel */}
        <div
          id="blocks"
          style={{
            width: '200px',
            padding: '10px',
            borderRight: '1px solid #ccc',
            backgroundColor: '#343a40',
            color: '#fff',
          }}
        ></div>

        {/* GrapesJS Editor Container */}
        <div id="gjs" style={{ flex: 1, padding: '10px' }}></div>

        {/* Save Button */}
        {/* <button
          onClick={handleSave}
          style={{
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            padding: '10px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Save Page
        </button> */}
      </div>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" onClick={handleSave}>
          Submit
        </Button>
      </div>
    </Modal >
  )
}
