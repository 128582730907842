import moment from "moment"
import { REPORTS_API } from "../../../../constants/Configs"
import { API_REQUEST, ReportType } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize, sortData } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError } from "../../../../utils/toast"
import { reportsListDTO } from "../dtos/reports"

export const getReports = (search, reportType, startDate, endDate, sortBy, orderBy, page = 1) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!reportType ? { reportType } : {}),
    ...(!!startDate ? { startDate } : {}),
    ...(!!endDate ? { endDate } : {}),
  }

  API(API_REQUEST.get, REPORTS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminReports.SetReports, reportsListDTO(res?.data?.data?.rows))
      filteredData(reportsListDTO(res?.data?.data?.rows), search, sortBy, orderBy, page)
      dispatch(Actions.RestaurantAdminReports.SetTotalCount, res?.data?.data?.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const filteredData = (reports, search, activeSort, sortOrder, page) => {
  let data = []
  const query = search?.toLowerCase()
  if (reports?.length) {
    data = sortData(reports, activeSort, sortOrder).filter((item) =>
      item?.productName?.toLowerCase().includes(query) ||
      item?.name?.toLowerCase().includes(query) ||
      item?.orderCount?.toString().toLowerCase().includes(query) ||
      item?.revenue?.toString().toLowerCase().includes(query)
    );
  }
  dispatch(Actions.RestaurantAdminReports.SetTotalCount, data?.length)
  getReportsByPagination(data, page)
}

export const getReportsByPagination = (reports, page) => {
  let data = []
  if (reports?.length) {
    reports.forEach((report, index) => {
      if (index >= ((page - 1) * getPageSize()) && (index < (getPageSize() * page))) {
        data.push(report)
      }
    })
  }
  dispatch(Actions.RestaurantAdminReports.SetReportsWithPagination, data)
}

export const handleSort = (param, activeSort, sortOrder) => {
  let paramSortOrder = ''
  if (param) {
    if (activeSort !== param) {
      paramSortOrder = 'ASC';
    } else {
      paramSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
    }
  }
  dispatch(Actions.RestaurantAdminReports.SetActiveSort, param);
  dispatch(Actions.RestaurantAdminReports.SetSortOder, paramSortOrder);
}

export const filterDataForCSV = (reports, type, currency) => {
  return reports.map(report => {
    if (type === ReportType.HOURLY || type === ReportType.WEEKLY) {
      return {
        'Start Time': report.startDate ? moment(report.startDate).format(type === ReportType.HOURLY ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY') : '',
        'End Time': report.endDate ? moment(report.endDate).format(type === ReportType.HOURLY ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY') : '',
        'Orders': report.orderCount || 0,
        [`Tax (${currency})`]: report.revenue && report.vat && `${((report.revenue * report.vat) / 100).toFixed(2)}`,
        [`Revenue (${currency})`]: report.revenue && `${(report.revenue).toFixed(2)}`
      }
    } else if (type === ReportType.PRODUCT) {
      return {
        'Product Name': report.productName || '',
        'Sale Quantity': report.productCount || '',
        'Orders': report.orderCount || 0,
        [`Tax (${currency})`]: report.revenue && report.vat && `${((report.revenue * report.vat) / 100).toFixed(2)}`,
        [`Revenue (${currency})`]: report.revenue && `${(report.revenue).toFixed(2)}`
      }
    } else if (type === ReportType.CATEGORY) {
      return {
        'Category Name': report.name || '',
        'Products': report.productRetailerIds || 0,
        'Sale Quantity': report.categoryCount || 0,
        'Orders': report.orderCount || 0,
        [`Tax (${currency})`]: report.revenue && report.vat && `${((report.revenue * report.vat) / 100).toFixed(2)}`,
        [`Revenue (${currency})`]: report.revenue && `${(report.revenue).toFixed(2)}`
      }
    } else {
      return {
        [type === ReportType.MONTHLY ? 'Month' : type === ReportType.YEARLY ? 'Year' : type === ReportType.DAILY ? 'Date' : '']: report.endDate ? type === ReportType.MONTHLY ? moment(report.endDate).format('MMM YYYY') : type === ReportType.YEARLY ? moment(report.endDate).format('YYYY') : type === ReportType.DAILY ? moment(report.endDate).format('DD-MM-YYYY') : '' : '',
        'Orders': report.orderCount || 0,
        [`Tax (${currency})`]: report.revenue && report.vat && `${((report.revenue * report.vat) / 100).toFixed(2)}`,
        [`Revenue (${currency})`]: report.revenue && `${(report.revenue).toFixed(2)}`
      }
    }
  })
}