export function paymentsListDTO(data) {
  let payments = [];

  if (data?.length) {
    payments = data.map((payment) => ({
      id: payment?.id || null,
      createdAt: payment?.createdAt || null,
      amount: payment?.amount || 0,
      status: payment?.status,
      restaurantSubscription: payment?.restaurantSubscription ? {
        startDate: payment?.restaurantSubscription?.startDate || null,
        endDate: payment?.restaurantSubscription?.endDate || null,
        status: payment?.restaurantSubscription?.status,
      } : null,
      restaurant: payment?.restaurant ? {
        id: payment?.restaurant?.id || null,
        name: payment?.restaurant?.name || null,
        phone: payment?.restaurant?.phone || null,
      } : null,
      subscription: payment?.subscription ? {
        id: payment?.subscription?.id || null,
        name: payment?.subscription?.name || null,
        amount: payment?.subscription?.amount || null,
        type: payment?.subscription?.type || null
      } : null,
    }))
  }

  return payments;
}