import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Select from "react-select";
import Datetime from "react-datetime";
import { Button, Card, CardBody, Col, Collapse, Form, Input, Label, Row, Nav, TabContent, TabPane, NavItem, NavLink, InputGroup, InputGroupText, FormFeedback, UncontrolledAlert, } from "reactstrap";
import InputField from "../../../../components/InputField";
import { CombinePDFSizeValidationMessage, featurePermissions, ImageLinks, ImageType, loyaltyProgramInputFields, reservationTimeInterval, selectDropdownStyle, settingsInputFields, settingTabs, } from "../../../../constants/General";
import { editSettingsSchema } from "../../../../constants/Schemas";
import { createStripeAccount, editSettings, initializeServiceHours, linkBankAccounts, setInitialValues, stripeAccountLogin, } from "../../utils/middleware/settings";
import { useEffect } from "react";
import { isAllowFeature, removeEmptyKeysFromObject, uploadImage, } from "../../../../services/middleware";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Actions } from "../../../../redux/actions";
import { handleError } from "../../../../utils/toast";
import { dispatch } from "../../../../utils/store";

export default function Settings() {
  // Store
  const settings = useSelector((state) => state.restaurantAdmin.settings);

  // State
  const [editable, setEditable] = useState(false);
  const [allowLoyalty, setAllowLoyalty] = useState(false);
  const [isCallbackurlCopy, setIsCallBackurlCopy] = useState(false);
  const [isVerifyTokenCopy, setIsVerifyTokenCopy] = useState(false);
  const [serviceHours, setServiceHours] = useState(
    initializeServiceHours(settings?.serviceHours)
  );
  const [activeTab, setActiveTab] = useState(0);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: setInitialValues(settings),
    validationSchema: editSettingsSchema,
    onSubmit: async (values, { resetForm }) => {
      values.reservationTimeInterval =
        values.reservationTimeInterval?.value || "";
      const filterData = removeEmptyKeysFromObject(values);
      if (
        filterData.welcomePDF &&
        Array.isArray(filterData.welcomePDF) &&
        filterData.welcomePDF?.length
      ) {
        dispatch(Actions.RestaurantAdmin.SetLoading, true);
        let welcomePDFData = [];
        let fileData = filterData.welcomePDF.filter(
          (file) => typeof file.fileUrl === "object"
        );
        let uploadedData = filterData.welcomePDF.filter(
          (file) => typeof file.fileUrl === "string"
        );
        if (fileData?.length) {
          const logoResponse = await uploadImage(
            fileData?.length > 1 ? fileData : fileData[0],
            ImageType.RESTAURANT_MENU_PDF,
            true
          ).catch((err) => {
            dispatch(Actions.RestaurantAdmin.SetLoading, false);
            handleError(err);
          });
          const extractFileName = (filePath) =>
            filePath.split("/").pop().replace(".pdf", "");
          logoResponse.forEach((file) =>
            welcomePDFData.push({
              fileName: extractFileName(file?.fileName),
              fileUrl: file?.fileLink,
              isFileNameUpdate:
                extractFileName(file?.fileName) !==
                extractFileName(file.fileLink),
            })
          );
        }
        uploadedData.forEach((file) => {
          if (file?.fileName && file.fileUrl) {
            welcomePDFData.push({
              fileName: file?.fileName,
              fileUrl: file?.fileUrl,
              isFileNameUpdate:
                file.fileName !==
                file.fileUrl.split("/").pop().replace(".pdf", ""),
            });
          }
        });
        filterData.welcomePDF = welcomePDFData;
      }
      editSettings(
        { ...filterData, allowLoyalty: true },
        resetForm,
        setEditable
      );
    },
  });

  useEffect(() => {
    setServiceHours(initializeServiceHours(settings?.serviceHours));
    setAllowLoyalty(settings?.allowLoyalty);
  }, [settings]);

  const updateSetting = () => {
    return (
      <div className="text-right">
        {editable ? (
          <>
            <Button
              color="primary"
              className="mt-4"
              type="button"
              disabled={!!Object.values(formik.errors)?.length}
              onClick={formik.handleSubmit}
            >
              Update
            </Button>
            <Button
              color="outline-primary"
              className="mt-4"
              type="button"
              onClick={() => {
                formik.setValues(setInitialValues(settings));
                setAllowLoyalty(settings?.allowLoyalty);
                setServiceHours(initializeServiceHours(settings?.serviceHours));
                setEditable(false);
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            className="mt-4"
            type="button"
            onClick={() => setEditable(true)}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    let data = [];
    if (serviceHours && Object.keys(serviceHours).length) {
      data = Object.keys(serviceHours).map((service) => {
        let timeSlots = [];
        if (serviceHours?.[service]?.from1 && serviceHours?.[service]?.to1) {
          timeSlots.push({
            from: moment(serviceHours?.[service]?.from1).format("HH:mm"),
            to: moment(serviceHours?.[service]?.to1).format("HH:mm"),
          });
        }
        if (serviceHours?.[service]?.from2 && serviceHours?.[service]?.to2) {
          timeSlots.push({
            from: moment(serviceHours?.[service]?.from2).format("HH:mm"),
            to: moment(serviceHours?.[service]?.to2).format("HH:mm"),
          });
        }
        return {
          day: parseInt(service),
          timeSlots: timeSlots,
        };
      });
    }
    formik.setFieldValue("serviceHours", data);
  }, [serviceHours]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopyText = (text, copyTextVisible) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand("copy");

    // Clean up the temporary element
    document.body.removeChild(textarea);
    copyTextVisible(true);

    setTimeout(() => {
      copyTextVisible(false);
    }, 5000); // Set timeout for 5 seconds
  };

  const handleRemove = (index) => {
    let updatedPdfs = [...formik.values.welcomePDF];
    if (updatedPdfs.length === 1) {
      updatedPdfs = [{ fileName: "", fileUrl: "" }];
    } else {
      updatedPdfs.splice(index, 1);
    }
    formik?.setFieldValue("welcomePDF", updatedPdfs);
  };

  const handleAdd = () => {
    const updatedPdfs = [
      ...formik.values.welcomePDF,
      { fileName: "", fileUrl: "" },
    ];
    formik?.setFieldValue("welcomePDF", updatedPdfs);
  };

  return (
    <div>
      {activeTab === 3 && (
        <UncontrolledAlert color="light">
          {CombinePDFSizeValidationMessage}
        </UncontrolledAlert>
      )}
      <Card className="shadow overflow-hidden">
        <CardBody className="p-0">
          <div className="custom-tabs-wrapper">
            <Nav tabs className="custom-tab-links">
              {settingTabs.map((tab, index) => {
                return (
                  (index !== 2 ||
                    (tab === featurePermissions["loyalty"] &&
                      isAllowFeature("loyalty"))) && (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === index,
                        })}
                        onClick={() => {
                          setActiveTab(index);
                        }}
                      >
                        <h3>{tab}</h3>
                      </NavLink>
                    </NavItem>
                  )
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab} className="custom-tab-content">
              <TabPane tabId={0}>
                <div className="tab-content-inner-wrap">
                  <Form>
                    {settingsInputFields.map((row, index) => {
                      return (
                        <Row key={index}>
                          <Col md={6}>
                            <div className="flex align-items-end">
                              <div className="full-width">
                                {row.fieldNameLeft && (
                                  <InputField
                                    required={row.requiredLeft}
                                    disabled={
                                      index ===
                                      settingsInputFields.length - 3 ||
                                      !editable
                                    }
                                    placeholder={row.placeholderLeft}
                                    fieldName={row.fieldNameLeft}
                                    formik={formik}
                                  />
                                )}
                              </div>
                              {index === settingsInputFields.length - 3 && (
                                <Button
                                  color="primary"
                                  className="ml-3 mb-3"
                                  disabled={!formik.values[row.fieldNameLeft]}
                                  onClick={() =>
                                    handleCopyText(
                                      formik.values[row.fieldNameLeft],
                                      setIsCallBackurlCopy
                                    )
                                  }
                                >
                                  {isCallbackurlCopy ? "Copied" : "Copy"}
                                </Button>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            {row.fieldNameRight ===
                              "reservationTimeInterval" ? (
                              <>
                                <Label
                                  for="exampleSelect"
                                  className="inputfield"
                                >
                                  {row.placeholderRight}
                                </Label>
                                <section className="mb-3 input-group-alternative">
                                  <Select
                                    options={reservationTimeInterval}
                                    isDisabled={!editable}
                                    value={
                                      formik.values?.reservationTimeInterval
                                    }
                                    className="custom-select"
                                    isSearchable={false}
                                    styles={selectDropdownStyle}
                                    onChange={(selectedOption) => {
                                      formik.setFieldValue(
                                        "reservationTimeInterval",
                                        selectedOption
                                      );
                                    }}
                                  />
                                  {formik.touched?.reservationTimeInterval &&
                                    formik.errors.reservationTimeInterval && (
                                      <span className="error-message input-group-alternative">
                                        {formik.errors.reservationTimeInterval}
                                      </span>
                                    )}
                                </section>
                              </>
                            ) : (
                              <div className="flex align-items-end">
                                <div className="full-width">
                                  {row.fieldNameRight && (
                                    <InputField
                                      required={row.requiredRight}
                                      disabled={
                                        index ===
                                        settingsInputFields.length - 3 ||
                                        !editable
                                      }
                                      placeholder={row.placeholderRight}
                                      fieldName={row.fieldNameRight}
                                      formik={formik}
                                    />
                                  )}
                                </div>
                                {index === settingsInputFields.length - 3 && (
                                  <Button
                                    color="primary"
                                    className="ml-3 mb-3"
                                    disabled={
                                      !formik.values[row.fieldNameRight]
                                    }
                                    onClick={() =>
                                      handleCopyText(
                                        formik.values[row.fieldNameRight],
                                        setIsVerifyTokenCopy
                                      )
                                    }
                                  >
                                    {isVerifyTokenCopy ? "Copied" : "Copy"}
                                  </Button>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                    <Label className="mb-2">Service Hours</Label>
                    {days.map((day, index) => {
                      return (
                        <Row key={index}>
                          <Col md={5} xl={4}>
                            <Row className="title-div-rdt-picker">
                              <Col className="space-between-div">
                                <Label className="inputfield">{day}</Label>
                              </Col>
                              <Col className="center-div">
                                <Label className="inputfield">Opened</Label>
                                <label className="ml-3 custom-toggle">
                                  <Input
                                    type="checkbox"
                                    className="form-control"
                                    name="recommended"
                                    checked={serviceHours?.[index]}
                                    disabled={!editable}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setServiceHours({
                                          ...serviceHours,
                                          [index]: {},
                                        });
                                      } else
                                        setServiceHours((current) => {
                                          const state = { ...current };
                                          delete state[index];
                                          return state;
                                        });
                                    }}
                                  />
                                  <span className="custom-toggle-slider rounded-circle" />
                                </label>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={7} xl={8}>
                            <Row>
                              <Col sm={6} xl={3}>
                                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                                  <InputGroupText>
                                    <FontAwesomeIcon icon={faClock} />
                                  </InputGroupText>
                                  <Datetime
                                    dateFormat={false}
                                    value={serviceHours?.[index]?.from1}
                                    renderInput={(props) => (
                                      <input
                                        {...props}
                                        value={
                                          serviceHours?.[index]?.from1
                                            ? props.value
                                            : ""
                                        }
                                      />
                                    )}
                                    onChange={(value) => {
                                      setServiceHours({
                                        ...serviceHours,
                                        [index]: {
                                          ...serviceHours[index],
                                          from1: value,
                                        },
                                      });
                                    }}
                                    inputProps={{
                                      disabled:
                                        !editable || !serviceHours?.[index],
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                              <Col sm={6} xl={3}>
                                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                                  <InputGroupText>
                                    <FontAwesomeIcon icon={faClock} />
                                  </InputGroupText>
                                  <Datetime
                                    dateFormat={false}
                                    value={serviceHours?.[index]?.to1}
                                    renderInput={(props) => (
                                      <input
                                        {...props}
                                        value={
                                          serviceHours?.[index]?.to1
                                            ? props.value
                                            : ""
                                        }
                                      />
                                    )}
                                    onChange={(value) => {
                                      if (
                                        moment(
                                          serviceHours[index].from1
                                        ).isSame(moment(value))
                                      ) {
                                        setServiceHours({
                                          ...serviceHours,
                                          [index]: {
                                            ...serviceHours[index],
                                            to1: moment(
                                              serviceHours[index].from1
                                            ).add(1, "hour"),
                                          },
                                        });
                                      } else {
                                        setServiceHours({
                                          ...serviceHours,
                                          [index]: {
                                            ...serviceHours[index],
                                            to1: value,
                                          },
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      disabled:
                                        !editable || !serviceHours?.[index],
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                              <Col sm={6} xl={3}>
                                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                                  <InputGroupText>
                                    <FontAwesomeIcon icon={faClock} />
                                  </InputGroupText>
                                  <Datetime
                                    dateFormat={false}
                                    value={serviceHours?.[index]?.from2}
                                    renderInput={(props) => (
                                      <input
                                        {...props}
                                        value={
                                          serviceHours?.[index]?.from2
                                            ? props.value
                                            : ""
                                        }
                                      />
                                    )}
                                    onChange={(value) => {
                                      setServiceHours({
                                        ...serviceHours,
                                        [index]: {
                                          ...serviceHours[index],
                                          from2: value,
                                        },
                                      });
                                    }}
                                    inputProps={{
                                      disabled:
                                        !editable || !serviceHours?.[index],
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                              <Col sm={6} xl={3}>
                                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                                  <InputGroupText>
                                    <FontAwesomeIcon icon={faClock} />
                                  </InputGroupText>
                                  <Datetime
                                    dateFormat={false}
                                    value={serviceHours?.[index]?.to2}
                                    renderInput={(props) => (
                                      <input
                                        {...props}
                                        value={
                                          serviceHours?.[index]?.to2
                                            ? props.value
                                            : ""
                                        }
                                      />
                                    )}
                                    onChange={(value) => {
                                      if (
                                        moment(
                                          serviceHours[index].from2
                                        ).isSame(moment(value))
                                      ) {
                                        setServiceHours({
                                          ...serviceHours,
                                          [index]: {
                                            ...serviceHours[index],
                                            to2: moment(
                                              serviceHours[index].from2
                                            ).add(1, "hour"),
                                          },
                                        });
                                      } else {
                                        setServiceHours({
                                          ...serviceHours,
                                          [index]: {
                                            ...serviceHours[index],
                                            to2: value,
                                          },
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      disabled:
                                        !editable || !serviceHours?.[index],
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                    {editable && formik.errors.serviceHours && (
                      <span className="error-message input-group-alternative">
                        {formik.errors.serviceHours}
                      </span>
                    )}
                    {updateSetting()}
                  </Form>
                </div>
              </TabPane>
              <TabPane tabId={1}>
                <div className="tab-content-inner-wrap">
                  <Form>
                    <Row>
                      <Col md={6} className="space-between-div mb-3">
                        <Label for="exampleSelect" className="inputfield">
                          Create Stripe Account
                        </Label>
                        <Button
                          color="outline-primary"
                          disabled={settings?.isStripeAccount}
                          type="button"
                          size="sm"
                          title={
                            settings?.isStripeAccount
                              ? "Already Account Created"
                              : "Create Account"
                          }
                          onClick={() => createStripeAccount()}
                        >
                          Create
                        </Button>
                      </Col>
                      <Col md={6} className="space-between-div mb-3">
                        <Label for="exampleSelect" className="inputfield">
                          To Add Bank Details
                        </Label>
                        <Button
                          color="outline-primary"
                          type="button"
                          size="sm"
                          onClick={() => linkBankAccounts()}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="space-between-div mb-3">
                        <Label for="exampleSelect" className="inputfield">
                          View Your Accout Details
                        </Label>
                        <Button
                          color="outline-primary"
                          type="button"
                          size="sm"
                          onClick={() => stripeAccountLogin()}
                        >
                          View
                        </Button>
                      </Col>
                    </Row>
                    {updateSetting()}
                  </Form>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <div className="tab-content-inner-wrap">
                  <Form>
                    <Row>
                      <Col md={6} className="space-between-div mb-3">
                        <Label for="exampleSelect" className="inputfield">
                          Toggle Loyalty Program
                        </Label>
                        <label className="custom-toggle">
                          <Input
                            type="checkbox"
                            className="form-control"
                            name="recommended"
                            checked={allowLoyalty}
                            disabled={!editable}
                            onChange={() => {
                              setAllowLoyalty(!allowLoyalty);
                              if (allowLoyalty) {
                                formik.setFieldValue("rewardCoinsPrice", "");
                                formik.setFieldValue("rewardCoinsPerOrder", "");
                                formik.setFieldValue("minimumOrderPrice", "");
                                formik.setFieldValue(
                                  "minimumRewardedCoins",
                                  ""
                                );
                                formik.setFieldValue("maxRedeemableCoins", "");
                              }
                            }}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                    <Collapse isOpen={allowLoyalty}>
                      {allowLoyalty &&
                        loyaltyProgramInputFields.map((row, index) => {
                          return (
                            <Row key={index}>
                              <Col md={6}>
                                <InputField
                                  disabled={!editable}
                                  placeholder={row.placeholderLeft}
                                  fieldName={row.fieldNameLeft}
                                  formik={formik}
                                />
                              </Col>
                              {row?.fieldNameRight && (
                                <Col md={6}>
                                  <InputField
                                    disabled={!editable}
                                    placeholder={row.placeholderRight}
                                    fieldName={row.fieldNameRight}
                                    formik={formik}
                                  />
                                </Col>
                              )}
                            </Row>
                          );
                        })}
                    </Collapse>
                    {updateSetting()}
                  </Form>
                </div>
              </TabPane>
              <TabPane tabId={3}>
                <div className="tab-content-inner-wrap">
                  <Form>
                    {Array.isArray(formik.values.welcomePDF) &&
                      formik.values.welcomePDF.map((file, index) => (
                        <Row key={index}>
                          <Col md={6}>
                            <Label for="exampleSelect" className={`inputfield`}>
                              Welcome PDF {index + 1}
                            </Label>
                            <div className="space-between-div welcome-pdf-input cursor-pointer">
                              <InputGroup className="input-group-alternative mb-3">
                                <Input
                                  key={`welcomePDF-${index}`}
                                  className="custom-form-control mr-3"
                                  id="welcomePDF"
                                  type="file"
                                  disabled={!editable}
                                  accept="application/pdf"
                                  invalid={
                                    !!formik?.errors?.welcomePDF &&
                                    !!formik?.errors?.welcomePDF[index]?.fileUrl
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.currentTarget.files &&
                                      e.currentTarget.files[0]
                                    ) {
                                      const updatedPdfs = [
                                        ...formik.values.welcomePDF,
                                      ];

                                      updatedPdfs[index] = {
                                        fileName:
                                          e.currentTarget.files[0].name.replace(
                                            ".pdf",
                                            ""
                                          ),
                                        fileUrl: e.currentTarget.files[0],
                                      };
                                      formik?.setFieldValue(
                                        "welcomePDF",
                                        updatedPdfs
                                      );
                                    }
                                  }}
                                />
                                <FormFeedback>
                                  {formik?.errors?.welcomePDF &&
                                    formik?.errors?.welcomePDF[index] &&
                                    formik?.errors?.welcomePDF[index]?.fileUrl}
                                </FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                          <Col md={6}>
                            <Label for="exampleSelect" className={`inputfield`}>
                              Welcome PDF {index + 1} Name
                            </Label>
                            <div className="space-between-div cursor-pointer align-start">
                              <InputGroup className="mb-3">
                                <Input
                                  key={`${file.fileName}-${index}`}
                                  className="custom-form-control mr-3"
                                  value={file.fileName}
                                  disabled={!editable}
                                  autoFocus
                                  invalid={
                                    !!formik?.errors?.welcomePDF &&
                                    !!formik?.errors?.welcomePDF[index]
                                      ?.fileName
                                  }
                                  onChange={(e) => {
                                    const updatedPdfs = [
                                      ...formik.values.welcomePDF,
                                    ];

                                    updatedPdfs[index] = {
                                      ...updatedPdfs[index],
                                      fileName: e.target.value,
                                    };
                                    formik?.setFieldValue(
                                      "welcomePDF",
                                      updatedPdfs
                                    );
                                  }}
                                />
                                <FormFeedback>
                                  {formik?.errors?.welcomePDF &&
                                    formik?.errors?.welcomePDF[index] &&
                                    formik?.errors?.welcomePDF[index]?.fileName}
                                </FormFeedback>
                              </InputGroup>
                              {Array.isArray(formik.values.welcomePDF) &&
                                formik.values.welcomePDF.length - 1 ===
                                index && (
                                  <Button
                                    disabled={!editable}
                                    color="primary"
                                    type="button"
                                    onClick={() => handleAdd()}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </Button>
                                )}
                              <Button
                                color="primary"
                                disabled={!editable}
                                type="button"
                                onClick={() => handleRemove(index)}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    <ul>
                      {Array.isArray(formik.values.welcomePDF) &&
                        formik.values.welcomePDF.map((file, index) => {
                          return (
                            typeof file?.fileUrl === "string" &&
                            ImageLinks.includes(
                              file?.fileUrl.split(":")[0]
                            ) && (
                              <li
                                key={index}
                                className={`${!editable && "disabled"} ${typeof file?.fileUrl === "string" &&
                                  "cursor-pointer"
                                  }`}
                                onClick={() =>
                                  typeof file.fileUrl === "string" &&
                                  window.open(file?.fileUrl, "_blank")
                                }
                              >
                                {file.fileName}
                              </li>
                            )
                          );
                        })}
                    </ul>
                    {formik.errors.welcomePDF &&
                      typeof formik.errors.welcomePDF === "string" && (
                        <span className="error-message input-group-alternative">
                          {formik.errors.welcomePDF}
                        </span>
                      )}
                    {updateSetting()}
                  </Form>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
