import React, { useState } from 'react';
import { 
  Form, FormGroup, Label, Input, Button, 
  Row, Col, Card, CardBody, CardTitle, 
  CardText, Modal,  ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ScriptModal from '../../components/ChatWidgetModal/index'
import './chatWidget.css'

import image from '../../../../assets/images/whatsapp-profile.jpg'

const ChatWidgetConstructor = () => {
  const [businessName, setBusinessName] = useState('');
  const [availabilityTag, setAvailabilityTag] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [welcomeText, setWelcomeText] = useState('');
  const [question, setQuestion] = useState('');
  const [position, setPosition] = useState('left');
  const [triggerMessage, setTriggerMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [generatedScript, setGeneratedScript] = useState('');  

const toggleModal = () => setModal(!modal);

  const generateScript = (e) => {
    e.preventDefault();
    const restaurant = JSON.parse(localStorage.getItem('userData'));
    const tokenString = JSON.stringify({id:restaurant.id,restaurantId:restaurant.restaurantId,phone:restaurant.phone});
    const base64Token = btoa(tokenString);
    const script = `
      <script>
          (function (w, d, s, u) {
              w.wpwawc = {
                  url: u,
                  options: {
                      siteName: "${businessName}",
                      siteTag: "${availabilityTag}",
                      siteLogo: "${logoUrl}",
                      widgetPosition: "${position}",
                      triggerMessage: "${triggerMessage}",
                      welcomeMessage: "${welcomeText}",
                      brandColor: "#25D366",
                      messageText: "${question}",
                      token: "${base64Token}",
                      socketURL:"wss://ymheyjjw2e.execute-api.eu-west-2.amazonaws.com/production/"
                  },
              };
              var h = d.getElementsByTagName(s)[0],
              j = d.createElement(s);
              j.async = true;
              j.src = u + "/chatwidget.min.js?_=" + Math.random();
              h.parentNode.insertBefore(j, h);
          })(window, document, "script", "https://s3.eu-west-2.amazonaws.com/wepos.ai");
      </script>
    `;
    setGeneratedScript(script);
    toggleModal();
  };

  return (
    <Row className="justify-content-center">
      <Col lg="6">
        <Card>
          <CardBody>
            <CardTitle tag="h3">Chat Widget Constructor</CardTitle>
            <Form onSubmit={generateScript}>
              <FormGroup>
                <Label for="businessName">Name of your business/brand *</Label>
                <Input
                  type="text"
                  id="businessName"
                  placeholder="Name to be displayed in widget"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="availabilityTag">Availability tag</Label>
                <Input
                  type="text"
                  id="availabilityTag"
                  placeholder="Usually replies in a day"
                  value={availabilityTag}
                  onChange={(e) => setAvailabilityTag(e.target.value)}
                />
              </FormGroup>

              <CardText tag="h5">Customize Your Chat Widget</CardText>
              
              <FormGroup>
                <Label for="logoUrl">Your Logo</Label>
                <Input
                  type="url"
                  id="logoUrl"
                  placeholder="Logo URL"
                  value={logoUrl}
                  onChange={(e) => setLogoUrl(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="welcomeText">Welcome Text *</Label>
                <Input
                  type="textarea"
                  id="welcomeText"
                  placeholder="Welcome message"
                  value={welcomeText}
                  onChange={(e) => setWelcomeText(e.target.value)}
                  required
                />
              </FormGroup>

              {/* <FormGroup>
                <Label for="question">Question</Label>
                <Input
                  type="textarea"
                  id="question"
                  placeholder="Question text"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </FormGroup> */}

              {/* <FormGroup>
                <Label>Position of WhatsApp Button</Label>    
                <div>
                  <FormGroup  check inline >
                      <Input type="radio" id="positionLeft" checked={position === 'left'} onChange={() => setPosition('left')}/>
                      <Label check>
                        Left
                      </Label>
                  </FormGroup>
                  <FormGroup check inline >
                    <Input type="radio" id="positionRight" checked={position === 'right'} onChange={() => setPosition('right')}/>
                    <Label check>
                      Right
                    </Label>
                  </FormGroup>
                </div>            

              </FormGroup> */}

              {/* <FormGroup>
                <Label for="triggerMessage">Trigger Message</Label>
                <Input
                  type="text"
                  id="triggerMessage"
                  placeholder="Message us"
                  value={triggerMessage}
                  onChange={(e) => setTriggerMessage(e.target.value)}
                />
              </FormGroup> */}

              <Button color="primary" block>
                Generate Chat Widget Code
              </Button>
            </Form>
          </CardBody>
        </Card>
        {/* Modal to display the generated script */}
        <ScriptModal modal={modal} toggleModal={toggleModal} generatedScript={generatedScript}/>
      </Col>
      <Col lg="6">
        <div className='flex justify-content-center align-items-center'>
          <div className='whatsapp-content'>
              <div className='whatsapp-header'>
                <img src={logoUrl ? logoUrl : image} alt="Logo"/>
                <div className='flex-col'>
                  <h2>{businessName ? businessName : "Business Name"}</h2>
                  <small>{availabilityTag ? availabilityTag : "Tag Line"}</small>
                </div>
              </div>  
              <div className='whatsapp-msg'>
                <div class="chat-bubble">
                  <p class="message-text">{welcomeText ? welcomeText : "Hello, Welcome to our Restaurant !!!"}</p>                
                </div>                    
              </div>  
              <div className='whatsapp-footer'></div>          
          </div>
        </div>
        <div className='flex justify-content-center align-items-center'>
            <div className='whatsapp-icon'>
              <FontAwesomeIcon icon={faWhatsapp} />
            </div>
        </div>
      </Col>
    </Row>
  );
};

export default ChatWidgetConstructor;
