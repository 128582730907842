export default function products(
  initialState = {
    products: [],
    productsWithPagination: [],
    categories: [],
    selectedCategory: '',
    isRecommended: false,
    search: '',
    activeSort: '',
    sortOrder: '',
    totalCount: 0,
    page: 1
  },
  action
) {
  switch (action.type) {
    case 'SET_RESTAURANT_ADMIN_PRODUCTS':
      return {
        ...initialState,
        products: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_WITH_PAGINATION':
      return {
        ...initialState,
        productsWithPagination: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_CATEGORIES':
      return {
        ...initialState,
        categories: action.payload,
      };

    case 'SET_SELECTED_CATEGORY_RESTAURANT_ADMIN_PRODUCTS':
      return {
        ...initialState,
        selectedCategory: action.payload,
      };

    case 'SET_IS_RECOMMENDED_RESTAURANT_ADMIN_PRODUCTS':
      return {
        ...initialState,
        isRecommended: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_SEARCH':
      return {
        ...initialState,
        search: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_ACTIVE_SORT':
      return {
        ...initialState,
        activeSort: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_SORT_ORDER':
      return {
        ...initialState,
        sortOrder: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_TOTAL_COUNT':
      return {
        ...initialState,
        totalCount: action.payload,
      };

    case 'SET_RESTAURANT_ADMIN_PRODUCTS_PAGE':
      return {
        ...initialState,
        page: action.payload,
      };

    case 'RESTAURANT_ADMIN_PRODUCTS_RESET':
      return {
        ...initialState,
        products: [],
        productsWithPagination: [],
        categories: [],
        selectedCategory: '',
        isRecommended: false,
        search: '',
        activeSort: '',
        sortOrder: '',
        totalCount: 0,
        page: 1
      };

    default:
      return initialState;
  }
}
