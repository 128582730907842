import React, { useEffect, useRef, useState } from "react";

const otpCss = {
  width: "40px",
  height: "40px",
  margin: "5px",
  textAlign: "center",
  fontSize: "1.2em",
};

const resendOtpCss = {
  width: "100%",
  textAlign: "end",
  cursor: "pointer"
};

const Otp = ({onSubmit, onResend }) => {
  const length = 6
  const inputRef = useRef([]);
  const [value, setValue] = useState(new Array(length).fill(""));
  const [timer, setTimer] = useState(30 * 60);
  const [canResend, setCanResend] = useState(true);

  const onChangeHandler = ({ target: { value: inputValue } }, index) => {
    if (isNaN(inputValue)) return;

    const newValue = [...value];
    newValue[index] = inputValue.slice(-1);
    setValue(newValue);

    if (inputValue && index < length - 1) inputRef.current[index + 1].focus();

    const finalValue = newValue.join("");
    finalValue.length === length && onSubmit(finalValue);
  };

  const onClickHandler = (index) => inputRef.current[index].setSelectionRange(1, 1);

  const onKeyDownHandler = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !value[index]) {
      inputRef.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResend(true); // Enable resend once the timer reaches 0
    }
  }, [timer]);

  const handleResend = () => {
    if (canResend) {
      onResend(); // Call the resend OTP function
      setTimer(30 * 60); // Reset the timer for another 30 seconds
      setCanResend(false); // Disable the resend link until timer is over
    }
  };

  useEffect(() => {
    if (inputRef.current[0]) inputRef.current[0].focus();
  }, []);

  const resendLinkStyle = {
    ...resendOtpCss,
    cursor: canResend ? "pointer" : "not-allowed",
    opacity: canResend ? 1 : 0.5,
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`; // Adds a leading zero to seconds if needed
  };

  return (
    <div>
      <h3>Enter Your OTP</h3>
      <form>
        {value.map((item, index) => (
          <input
            key={index}
            ref={(input) => (inputRef.current[index] = input)}
            value={item}
            style={otpCss}
            placeholder="0"
            onChange={(e) => onChangeHandler(e, index)}
            onClick={() => onClickHandler(index)}
            onKeyDown={(e) => onKeyDownHandler(e, index)}
          />
        ))}
      </form>
      <p className="secondary-link" onClick={handleResend} style={resendLinkStyle}>
        {canResend ? "Resend OTP?" : `Resend OTP in ${formatTime(timer)}`}
      </p>      
    </div>
  );
};
export default Otp;