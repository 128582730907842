import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { campaignValidationSchema } from '../../../../constants/Schemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';
import { API_REQUEST, multiSelectDropdownStyle } from '../../../../constants/General';
import { TEMPLATES_API } from '../../../../constants/Configs';
import { templatesListDTO } from '../../utils/dtos/templates';
import { removeEmptyKeysFromObject, trimObjectValues, uploadImage } from '../../../../services/middleware';
import { handleError } from '../../../../utils/toast';
import { CAMPAIGN_FILE_TYPE, ImageLinks, ImageType, selectDropdownStyle } from '../../../../constants/General';
import { useSelector } from 'react-redux';
import { getCustomers } from '../../utils/middleware/marketings';
import Customers from "../../pages/Customers"; // Import Customers component
import "./viewModal.css"

import moment from 'moment-timezone';

import API from '../../../../services/api';
export default function CustomerViewModal(props) {
  // Props

  const { open,startDate, handleCloseModal,endDate } = props

  return (
    <Modal
      className="modal-dialog-centered customer-view-modal"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Customer List
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0" >
        <Card className="border-0">
          <CardBody>
                <Customers />
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">

      </div>
    </Modal>
  )
}
