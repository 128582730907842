import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Table, CardFooter } from "reactstrap";
import NoDataLabel from '../../../../components/NoDataLabel';
import moment from 'moment';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { selectDropdownStyle } from '../../../../constants/General';

// Define page size options
const PageSizes = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
];

export default function CustomNotification() {
  const notifications = useSelector(state => state.restaurantAdminNotifications.notification.data);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Default page size

  // Calculate the current notifications to display
  const indexOfLastNotification = currentPage * itemsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - itemsPerPage;
  
  const currentNotifications = notifications?.slice(indexOfFirstNotification, indexOfLastNotification);

  const formatDate = (dateString) => {
    return moment(dateString).format('DD-MM h:mm A');
  };

  // Handle page size change
  const handlePageSizeChange = (selectedOption) => {
    setItemsPerPage(selectedOption.value);
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <div>
      <div className="custom-container">
        <Card className="shadow">
          <CardHeader className="border-0 space-between-div table-header-div">
            <h3 className="mb-0">Notification List</h3>
          </CardHeader>
          {notifications && notifications.length !== 0 ? (
            <>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th scope='col'>Name</th>
                    <th scope='col'>Message</th>
                    <th scope='col'>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentNotifications.map((notification, index) => (
                    <tr key={index}>
                      <td>{notification.customer.name}</td>
                      <td>{notification.message}</td>
                      <td>{formatDate(notification.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="space-between-div">
                {notifications.length > 0 && (
                  <Select
                    styles={selectDropdownStyle}
                    menuPlacement="auto"
                    className='page-size-select'
                    options={PageSizes}
                    isSearchable={false}
                    onChange={handlePageSizeChange}
                  />
                )}
                <nav aria-label="...">
                  <ReactPaginate
                    className='pagination justify-content-end mb-0'
                    breakLabel="..."
                    nextLabel=">"
                    previousLabel="<"
                    onPageChange={({ selected }) => setCurrentPage(selected + 1)} // Update current page
                    pageRangeDisplayed={1}
                    forcePage={currentPage - 1} // Adjust for zero-based index
                    pageCount={Math.ceil(notifications.length / itemsPerPage)}
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextClassName="page-item next-arrow"
                    nextLinkClassName="page-link"
                    previousClassName="page-item prev-arrow"
                    previousLinkClassName="page-link"
                  />
                </nav>
              </CardFooter>
            </>
          ) : (
            <NoDataLabel />
          )}
        </Card>
      </div>
    </div>
  );
}
