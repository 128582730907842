import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, Row, } from "reactstrap";
import InputField from "../../../../components/InputField";
import { setSelectedDropdownForSameValue, trimObjectValues, } from "../../../../services/middleware";
import { templateValidationSchema } from "../../../../constants/Schemas";
import './mobileView.css';
import moment from 'moment';

export default function MobileViewModal(props) {
  // Props
  const { template, formik } = props;
  const image = "https://images.unsplash.com/photo-1724757090342-59922ed19e39?q=80&w=2100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  const video = "https://cdn.pixabay.com/video/2024/03/06/203260-920602949_large.mp4"

  const videoRef = useRef(null);

  const handlePlayClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };


  const formatDate = (dateString) => {
    return moment(dateString).format('DD-MM h:mm A');
  };


  return (
    <>
      <div className="modal-body p-2 d-flex justify-content-center">
        <div class="phone">
          <div class="message-container">
            <div class="cardMobile message-received">
              {template.headerType === "DOCUMENT" &&<div class="pdf-header">
                <div class="pdf-icon">
                  <span>PDF</span>
                </div>
                <div class="download-btn">
                  <FontAwesomeIcon icon={faArrowDown} />
                </div>
              </div>}
              {template.headerType === "VIDEO" &&<div class="pdf-header">
                <video
                    src={video}
                    className="img-temp"
                    style={{height: '125px'}}
                    controls                    
                />

              </div>}
              {template.headerType === "IMAGE" &&<div class="img-header">
                <img
                    src={image} // Replace with actual image source
                    alt="temp"
                    className="img-temp" // Add custom CSS for profile pic
                  />
                  {/* <p className="download-img">
                    Image <FontAwesomeIcon icon={faArrowDown} />
                  </p> */}
              </div>}
              <div class="content mb-3">
                <h2>{template.name}</h2>
                <p className="content-body">{template.body}</p>
                <p className="footer-temp mb-0">{template.footer}</p>
                {template.buttons.map((msg) => (
                  <Button color="link" className="d-block mobileBtn mb-1" key={msg.id}>
                    {msg}
                  </Button>
                ))}
              </div>
              <p class="timeMobile">{formatDate(template.createdAt)}</p>
            </div>
          </div>
        </div>
      </div>
      </>
  );
}
